
import tw, { css } from "twin.macro";
import { YearComparisonChart } from "./Charts/YearComparisonChart";
import { ChartHeading } from "./Charts/chartHelpers";
import { NetSalesReportTable } from "./NetSalesReportTable";

export const AdminProfitReportContents = function(props: {
  postProcessedRowsForPod: any;
  columnDefinitionsForPod: any;
  startYear: number;
  endYear: number;
  chartContainerWidth: number;
}) {
  return (
    <div css={[tw`flex flex-col items-start`, css`max-width: 100vw;`]}>
      <h2
        css={[
          tw`w-full font-bold text-xl text-center my-4 mt-6 py-2`,
          css`
            background: linear-gradient(45deg, #dbeafe00, #dbeafe9e, #dbeafe00);
          `,
        ]}
      >
        POs opened by Pod
        <div css={tw`text-base font-normal`}>
          <p>(cumulative)</p>
          <p>(Value of POs opened minus project flow-through costs)</p>
        </div>
      </h2>

      <div css={tw`mb-8 mt-3 flex flex-row`}>
        <div css={[tw`flex-shrink-0`]}>
          <div style={{ width: props.chartContainerWidth }}>
            <YearComparisonChart
              year1Data={props.postProcessedRowsForPod.map((row: any) => ({
                year: props.startYear,
                monthName: row.monthName,
                x: row.monthName,
                y: row.startYearCumulativeGrossProfit,
              }))}
              year2Data={props.postProcessedRowsForPod.map((row: any) => ({
                year: props.endYear,
                monthName: row.monthName,
                x: row.monthName,
                y: row.endYearCumulativeGrossProfit,
              }))}
            />
          </div>
          <ChartHeading>
            <h2 css={tw`text-center`}>
              Net Sales: Current Year vs Previous Year
            </h2>
          </ChartHeading>
        </div>
        <NetSalesReportTable
          columnDefinitions={props.columnDefinitionsForPod}
          postProcessedRows={props.postProcessedRowsForPod}
          isCumulative={true}
        />
      </div>
    </div>
  );
}
