import { useMemo, useState } from "react";
import tw, { styled } from 'twin.macro';
import { PeopleDataQuery } from "../../codegen/graphql-types";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
} from '@tanstack/react-table';


const Table = styled.table`
  ${tw`min-w-full border-collapse`}
`;

const Thead = styled.thead`
  ${tw`bg-green-700`}
`;

const Th = styled.th`
  ${tw`px-6 py-3 border-b border-gray-200 text-left text-xs bg-gray-400 font-medium uppercase tracking-wider`}
`;

const Tr = styled.tr`
  ${tw`bg-white`}
  &:nth-of-type(even) {
    ${tw`bg-gray-50`}
  }
  &:hover {
    ${tw`bg-gray-100`}
  }
`;

const Td = styled.td`
  ${tw`px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900`}
`;

const Input = styled.input`
  ${tw`mt-2 p-2 border border-gray-300 rounded-md w-full`}
`;

export default function AllUsers({
  people
}: {
  people: PeopleDataQuery['people']
}) {
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'first_name',
        header: 'First Name',
      },
      {
        accessorKey: 'last_name',
        header: 'Last Name',
      },
      {
        accessorKey: 'email_address',
        header: 'Email',
      },
      {
        accessorKey: 'title',
        header: 'Job Title',
      },
      {
        accessorKey: 'role.roleTitle',
        header: 'Role',
      },
      {
        accessorKey: 'team.teamName',
        header: 'Team',
      },
      {
        accessorKey: 'rate',
        header: 'Rate',
        // divide by 100 and format as money
        cell: ({ row }: any) => row.original.rate > 0 ? `$${(row.original.rate / 100).toFixed(2)}` : <></>,
      }
    ],
    []
  );

  const table = useReactTable({
    data: people,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    // @ts-ignore
    onSortingChange: setSorting,
    // @ts-ignore
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });


  return (
    <div tw="overflow-x-auto">
      <Table>
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Th key={header.id}>
                  <div
                    {...{
                      onClick: header.column.getToggleSortingHandler(),
                      style: { cursor: header.column.getCanSort() ? 'pointer' : 'default' },
                    }}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                      // @ts-ignore
                    }[header.column.getIsSorted()] ?? null}
                  </div>
                  {header.column.getCanFilter() ? (
                    <div>
                      <Input
                        type="text"
                        value={(header.column.getFilterValue() ?? '') as string}
                        onChange={e => header.column.setFilterValue(e.target.value)}
                        placeholder={`Search ${header.column.columnDef.header}`}
                      />
                    </div>
                  ) : null}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
  
}
