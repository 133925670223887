
import tw, { css } from "twin.macro";
import { useState } from "react";
import { DateTime } from "luxon";
import { TimeSummaryForUser } from "./TimeSummaryForUser";
import { useRecentTimeContext } from "../../helpers/useRecentTimeContext";
import { FullSpinner } from "../../FullSpinner";

export const TimeForUser = () => {
  const { data, loading, error } = useRecentTimeContext();
  const thisYear = DateTime.now().year;
  const thisWeek = DateTime.now().weekNumber;
  const [yearInFocus, setYearInFocus] = useState(() => DateTime.now().year);
  // if the year in focus is the current year, set the week in focus to the current week
  const [weekInFocus, setWeekInFocus] = useState(() => yearInFocus === thisYear ? thisWeek : 1);

  if (loading) return (
    <div css={tw`w-full justify-center items-center h-screen`}>
      <FullSpinner />
    </div>
  );

  if (error) 
    return (
      <div css={tw`w-full justify-center items-center`}>
        {error && <p><b>Error in query for recent time logs</b>: {error.message}</p>}
      </div>
    );

  const handleYearChange = (selectedYear: number) => {
    setYearInFocus(selectedYear);
  }
  const handleWeekChange = (selectedWeek: number) => {
    setWeekInFocus(selectedWeek);
  }

  return (
    <section css={tw`flex flex-col gap-y-3 items-center p-4`}>
      {data && data.me && (
        <TimeSummaryForUser
          userTimeSummary={data}
          yearInFocus={yearInFocus}
          setYearInFocus={handleYearChange}
          weekInFocus={weekInFocus}
          setWeekInFocus={handleWeekChange}
        />
      )}
    </section>
  )
}