import { TimeEntry } from "../codegen/graphql-types";
import { BrandPalette } from "../Financials/Performance/Charts/constants";

import { differenceInCalendarDays, subDays } from 'date-fns';

export type MinutesByCompany = {
  companyId: string;
  clientName: string;
  totalMinutes: number;
  clientColour: string;
  minutesByDateRange: Record<'last90Days' | 'last60Days' | 'last30Days', number>;
  isOther?: boolean;
};

export const combineMinutesByCompany = (
  entries: TimeEntry[],
  brandPalette: BrandPalette[],
  podTotalMinutes: number,
  threshold: number
): MinutesByCompany[] => {
  const minutesMap: Record<string, MinutesByCompany> = {};

  const yesterday = subDays(new Date(), 1);

  entries.forEach((entry) => {
    const { company_id, minutes, date } = entry;
    const clientId = company_id;
    const brand = brandPalette.find((brand) => brand.id === clientId);
    const clientName = brand?.name ?? "Unknown";
    if (clientName === "Unknown") {
      console.log("Unknown client id", clientId);
      console.log("Go to https://mavenmm.teamwork.com/app/clients/{unknownClientId}/overview to see the client name, then fill that name and choose a colour in the brandPalette.ts file");
    }
    const clientColour = brand?.primary ?? "gray";

    const daysDifference = differenceInCalendarDays(yesterday, new Date(date));
    let dateRangeKey: 'last90Days' | 'last60Days' | 'last30Days' | null = null;
    if (daysDifference <= 30) {
      dateRangeKey = 'last30Days';
    } else if (daysDifference <= 60) {
      dateRangeKey = 'last60Days';
    } else if (daysDifference <= 90) {
      dateRangeKey = 'last90Days';
    }
    

    if (dateRangeKey) {
      if (!minutesMap[clientId]) {
        minutesMap[clientId] = {
          companyId: clientId,
          clientName,
          totalMinutes: 0,
          clientColour,
          minutesByDateRange: {
            last90Days: 0,
            last60Days: 0,
            last30Days: 0,
          },
        };
      }

      minutesMap[clientId].totalMinutes += minutes;
      minutesMap[clientId].minutesByDateRange[dateRangeKey] += minutes;
    }
  });

  return Object.values(minutesMap).map(data => ({
    ...data,
    isOther: data.totalMinutes / podTotalMinutes < threshold,
  }));


};
