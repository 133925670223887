import { RuntimeConfig } from "../../RuntimeConfig";
import { gql, useMutation, useQuery } from "@apollo/client";
import { subMonths } from "date-fns/esm";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";
import { getYear } from "date-fns";
import _ from "lodash";
import React, { useMemo, useRef, useState, useCallback } from "react";
import Select from "react-select";
import {
  CellProps,
  Column,
  FilterProps,
  Renderer,
  useExpanded,
  useFilters,
  UseFiltersColumnOptions,
  UseFiltersColumnProps,
  UseGroupByColumnOptions,
  useSortBy,
  UseSortByColumnOptions,
  useTable,
} from "react-table";
import tw, { css } from "twin.macro";
import type * as GraphQLTypes from "../../codegen/graphql-types";
import { filterTypes } from "../../helpers/filterTypes";
import { usePeopleContext } from "../../helpers/usePeopleContext";
import { ClientInvoicesListForEstimate } from "./ClientInvoicesListForEstimate";
import { CreateNewEstimate } from "./CreateNewEstimate";
import { PopoverInput } from "../../helpers/PopoverInput";
import { PopoverSelect } from "../../helpers/PopoverSelect";
import { PartEstimate, EstimatesProvider } from "../../helpers/useEstimates";
import { MultiSelectColumnFilter } from "../../Projects/Dashboard/MultiSelectColumnFilter";
import { PopulatedOrNotSelectColumnFilter } from "../../helpers/PopulatedOrNotSelectColumnFilter";
import { transformEstimateStatus } from "../../helpers/transformEstimateStatus";
import { TextTruncateRevealOnHover } from "../../Projects/Dashboard/TextTruncateRevealOnHover";
import { EditIcon } from "@chakra-ui/icons";
import { Money } from "../../components/Money";
import { useAuthContext } from "../../useAuth";
import { useLocalStorage } from "../../helpers/useLocalStorage";
import { FullSpinner } from "../../FullSpinner";
import { EstimateBudgetUnaccountedForTooltipContent } from "./EstimateBudgetUnaccountedForTooltipContent";
import SideDrawer from "./SideDrawer";
import PdfViewer from "./PdfViewer";
import UpdateEstimatePdf from "./UpdateEstimatePdf";
import MinMaxDateFilters from "./MinMaxDateFilters";

export const Estimates = () => {
  const { loggedInUserData } = useAuthContext();
  const { data: peopleData, loading: loadingPeople } = usePeopleContext();
  if (loadingPeople) {
    return <FullSpinner />;
  }
  const currentLoggedInUserId = peopleData?.people.find(
    (person) => person.email_address === loggedInUserData?.email
  )?.id;

  if (!currentLoggedInUserId) {
    return (
      <>
        {loggedInUserData?.email} doesn't have access to this page. If this
        message was shown in error, please contact your administrator.
      </>
    );
  }

  return (
    <Flex css={tw`pt-8`}>
      <Stack
        w="full"
        css={css`
          max-width: 100vw;
          overflow-x: auto;
        `}
      >
        <EstimatesProvider>
          <CreateNewEstimate currentLoggedInUserId={currentLoggedInUserId} />
          <ListEstimates />
        </EstimatesProvider>
      </Stack>
    </Flex>
  );
};

const currencyFormatter = new Intl.NumberFormat("en-US", {});

const defaultColumn = {
  // Let's set up our default Filter UI
  Filter: () => null,
  sortType: "alphanumeric",
};

const EMPTY_ARRAY: ReadonlyArray<any> = Object.freeze([]);

const defaultSortBy = [
  {
    id: "projectName",
    desc: true,
  },
];

const ListEstimates = () => {
  const [pdfFileKey, setPdfFileKey] = useState<string | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const { isAdmin } = useAuthContext();
  const { data: peopleData, loading: loadingPeople } = usePeopleContext();

  // minimum start date for estimates
  const [minStartDate, setMinStartDate] = useLocalStorage<Date | null>(
    "estimates.minStartDate",
    subMonths(new Date(), 6)
  );
  // maximum end date for estimates
  const [maxEndDate, setMaxEndDate] = useLocalStorage<Date | null>(
    "estimates.maxEndDate",
    new Date() // by default, today is the max end date
  );

  const actualMinStartDate =
    typeof minStartDate === "string" ? new Date(minStartDate) : minStartDate;

  const actualMaxEndDate = 
    typeof maxEndDate === "string" ? new Date(maxEndDate) : maxEndDate;

  const { data: estimatesData } = useQuery<
    GraphQLTypes.EstimateForEstimatesPageQuery,
    GraphQLTypes.EstimateForEstimatesPageQueryVariables
  >(
    gql`
      query EstimateForEstimatesPage($estimatesWhere: estimateWhereInput) {
        estimates(where: $estimatesWhere) {
          ...EstimateFragmentForEstimatesPage
        }
      }
      ${EstimateFragmentForEstimatesPage}
    `,
    {
      skip: loadingPeople || !peopleData?.me?.id,
      variables: {
        estimatesWhere: {
          date: {
            gte: actualMinStartDate,
            lte: actualMaxEndDate,
          },
        },
      },
    }
  );

  const { data: companiesData } = useQuery<
    GraphQLTypes.CompaniesForEstimatesTableQuery,
    GraphQLTypes.CompaniesForEstimatesTableQueryVariables
  >(
    gql`
      query CompaniesForEstimatesTable {
        companies {
          id
          company_name
          brands {
            id
            brand_name
          }
        }
      }
    `
  );

  const postProcessedRows = estimatesData?.estimates;

  const [updateEstimate] = useMutation<
    GraphQLTypes.UpdateEstimateMutation,
    GraphQLTypes.UpdateEstimateMutationVariables
  >(gql`
    mutation UpdateEstimate(
      $updateOneEstimateData: estimateUpdateInput!
      $updateOneEstimateWhere: estimateWhereUniqueInput!
    ) {
      updateOneEstimate(
        data: $updateOneEstimateData
        where: $updateOneEstimateWhere
      ) {
        ...PartEstimate
      }
    }
    ${PartEstimate}
  `);

  const [deleteOneEstimate] = useMutation<
    GraphQLTypes.DeleteOneEstimateMutation,
    GraphQLTypes.DeleteOneEstimateMutationVariables
  >(gql`
    mutation DeleteOneEstimate(
      $deleteOneEstimateWhere: estimateWhereUniqueInput!
    ) {
      deleteOneEstimate(where: $deleteOneEstimateWhere) {
        id
      }
    }
  `);

  const columnDefinitions = useMemo(() => {
    if (!postProcessedRows) return [];
    type Row = typeof postProcessedRows[number];
    type DataColumn = UseSortByColumnOptions<Row> &
      UseFiltersColumnOptions<Row> &
      UseGroupByColumnOptions<Row> &
      Column<Row> & {
        Header2?: Renderer<UseFiltersColumnProps<Row>>;
      };
    const NONE = `(None)`;

    const years = _.uniq(
      postProcessedRows.flatMap((row) => {
        if (!row.date) return [];
        return getYear(new Date(row.date));
      })
    );

    const columns: Array<DataColumn | { columns: DataColumn[] }> = [
      {
        id: "date",
        Header: "Date",
        accessor: (x) => x.date,
        Cell: ({ cell, row }: CellProps<Row>) => {
          return (
            <PopoverInput
              initialValue={new Date(cell.value).toLocaleDateString("en-CA", {
                timeZone: "UTC",
              })}
              type="date"
              onSubmit={async (e, newValue) => {
                await updateEstimate({
                  variables: {
                    updateOneEstimateData: {
                      date: {
                        set: new Date(newValue),
                      },
                    },
                    updateOneEstimateWhere: {
                      id: row.original.id,
                    },
                  },
                });
              }}
            />
          );
        },
        filter: "dateIsSameYear",
        Filter: ({
          column,
        }: FilterProps<Row> & {
          column: UseFiltersColumnProps<Row>;
        }) => (
          <div
            css={css`
              min-width: 6rem;
            `}
          >
            <Select
              isMulti={false}
              closeMenuOnSelect={true}
              defaultValue={column.filterValue}
              onChange={(selectedOption) => {
                column.setFilter(selectedOption.value ?? undefined);
              }}
              options={[
                {
                  value: undefined,
                  label: "All",
                },
                ...years.map((year) => ({
                  value: year.toString(),
                  label: year.toString(),
                })),
              ]}
            />
          </div>
        ),
      },
      {
        id: "owner",
        Header: "Owner",
        accessor: (x) => x.owner?.id ?? NONE,
        Cell: ({ row }: CellProps<Row>) => {
          const owner = peopleData?.people.find(
            (person) => person.id === row.original.owner.id
          );
          return (
            <>
              {owner ? (
                <>
                  {owner?.first_name} {owner?.last_name}
                </>
              ) : (
                NONE
              )}
            </>
          );
          // return (
          //   <PopoverSelect
          //     displayText={`${owner?.first_name} ${owner?.last_name}`}
          //     options={
          //       peopleData?.people
          //         .filter((person) => /maven/i.test(person.title))
          //         .map((person) => ({
          //           value: person.id,
          //           label: `${person?.first_name} ${person?.last_name}`,
          //         })) ?? []
          //     }
          //     onSubmit={(e, selectedOption) => {
          //       updateEstimate({
          //         variables: {
          //           updateOneEstimateData: {
          //             company: {
          //               connect: {
          //                 id: selectedOption?.value,
          //               },
          //             },
          //           },
          //           updateOneEstimateWhere: {
          //             id: row.original.id,
          //           },
          //         },
          //       });
          //     }}
          //   />
          // );
        },
        filter: "multiple",
        Filter: ({
          column,
        }: FilterProps<Row> & {
          column: UseFiltersColumnProps<Row>;
        }) => (
          <div
            css={css`
              min-width: 8rem;
            `}
          >
            <Select<
              {
                value: string | undefined;
                label: string;
              },
              true
            >
              isMulti={true}
              closeMenuOnSelect={false}
              defaultValue={column.filterValue}
              styles={{
                multiValue: (base) => {
                  return { ...base, minWidth: "8rem" };
                },
              }}
              onChange={(selectedOptions) => {
                const allValues = Array.from(selectedOptions ?? [])
                  .map((o) => o.value)
                  .filter(Boolean);
                column.setFilter(
                  allValues && allValues.length ? allValues : undefined
                );
              }}
              options={[
                {
                  value: undefined as string | undefined,
                  label: "All",
                },
              ].concat(
                _.uniqBy(
                  column.preFilteredRows,
                  (row) => row.values[column.id]
                ).map((row) => {
                  const owner = row.original.owner;
                  return {
                    value: owner?.id ?? NONE,
                    label: owner
                      ? `${
                          peopleData?.people
                            .filter((person) => person.id === owner.id)
                            .map((x) => `${x.first_name} ${x.last_name}`)[0]
                        } (${
                          column.preFilteredRows.filter(
                            (preFilteredRow) =>
                              preFilteredRow.values[column.id] === owner.id
                          ).length
                        })`
                      : NONE,
                  };
                })
              )}
            />
          </div>
        ),
      },

      {
        id: "projectName",
        Header: "Estimate Name",
        accessor: (x) => x.name,
        Cell: ({ row, cell }: CellProps<Row>) => {
          return (
            <PopoverInput
              initialValue={cell.value}
              displayText={
                <TextTruncateRevealOnHover
                  css={css`
                    max-width: 14rem;
                    .group-hover-show {
                      text-decoration: underline;
                    }
                  `}
                  text={
                    <>
                      {cell.value ?? ""}

                      <EditIcon
                        className="cell-hover-visible"
                        style={{ marginLeft: "0.5em" }}
                      />
                    </>
                  }
                />
              }
              onSubmit={(e, newValue) => {
                return updateEstimate({
                  variables: {
                    updateOneEstimateData: {
                      name: {
                        set: newValue,
                      },
                    },
                    updateOneEstimateWhere: {
                      id: row.original.id,
                    },
                  },
                });
              }}
            />
          );
        },
      },
      {
        // show the PDF file of the estimate
        id: "pdf",
        Header: <>PDF&nbsp;</>,
        accessor: (x) => x.pdf_file_key,
        Cell: ({ row }: CellProps<Row>) => row.original.pdf_file_key ? (
          // This component handles both viewing and updating the PDF
          <UpdateEstimatePdf 
            estimateName={row.original.name}
            fileKey={row.original.pdf_file_key}
            estimateId={row.original.id}
            updateEstimate={updateEstimate}
            setIsDrawerOpen={setIsDrawerOpen}
            setPdfFileKey={setPdfFileKey}
          />
        ) : null,
      },
      {
        id: "po_number",
        Header: (
          <Box textAlign="left" minWidth="100px">
            PO #
          </Box>
        ),
        accessor: (x) => x.po_number,
        Cell: ({ row, cell }: CellProps<Row>) => {
          return (
            <PopoverInput
              initialValue={cell.value}
              onSubmit={(e, newValue) => {
                return updateEstimate({
                  variables: {
                    updateOneEstimateData: {
                      po_number: {
                        set: newValue,
                      },
                    },
                    updateOneEstimateWhere: {
                      id: row.original.id,
                    },
                  },
                });
              }}
            />
          );
        },
        filter: "populatedOrNot",
        Filter: PopulatedOrNotSelectColumnFilter,
      },

      {
        id: "company",
        Header: (
          <Box textAlign="left" minWidth="100px">
            Company
          </Box>
        ),
        accessor: (x) => x.company.company_name,
        filter: "multiple",
        Filter: MultiSelectColumnFilter,
        Cell: ({ row }: CellProps<Row>) => {
          return (
            <PopoverSelect
              displayText={row.original.company.company_name}
              options={
                companiesData?.companies.map((company) => ({
                  value: company.id,
                  label: company.company_name,
                })) ?? []
              }
              onSubmit={async (e, selectedOption) => {
                await updateEstimate({
                  variables: {
                    updateOneEstimateData: {
                      company: {
                        connect: {
                          id: selectedOption?.value,
                        },
                      },
                    },
                    updateOneEstimateWhere: {
                      id: row.original.id,
                    },
                  },
                });
              }}
            />
          );
        },
      },
      {
        id: "brand",
        Header: (
          <Box textAlign="left" minWidth="100px">
            Brand
          </Box>
        ),
        accessor: (x) => x.brand.brand_name,
        filter: "multiple",
        Filter: MultiSelectColumnFilter,
        Cell: ({ row }: CellProps<Row>) => {
          return (
            <PopoverSelect
              displayText={row.original.brand.brand_name}
              options={
                companiesData?.companies
                  .find((company) => company.id === row.original.company.id)
                  ?.brands.map((brand) => ({
                    value: brand.id,
                    label: brand.brand_name,
                  })) ?? []
              }
              onSubmit={(e, selectedOption) => {
                return updateEstimate({
                  variables: {
                    updateOneEstimateData: {
                      brand: {
                        connect: {
                          id: selectedOption?.value,
                        },
                      },
                    },
                    updateOneEstimateWhere: {
                      id: row.original.id,
                    },
                  },
                });
              }}
            />
          );
        },
      },
      {
        id: "Gross Profit",
        Header: (
          <Box w="full" textAlign="right">
            Net Sales
          </Box>
        ),
        Header2: ({ filteredRows }) => (
          <div
            css={css`
              width: max-content;
            `}
          >
            Σ = $
            {currencyFormatter.format(
              _.sumBy(filteredRows, (row) => {
                return (
                  Number(row.original.po_subtotal ?? 0) -
                  Number(row.original.cogs ?? 0)
                );
              })
            )}
          </div>
        ),
        accessor: (x) => Number(x.po_subtotal) - Number(x.cogs),
        // derive this from subtotal - cogs
        Cell: ({ cell }: CellProps<Row>) => (
          <>{"$" + currencyFormatter.format(Math.round(cell.value))}</>
        ),
      },
      {
        id: "COGS",
        Header: (
          <Box w="full" textAlign="right">
            COGS
          </Box>
        ),
        Header2: ({ filteredRows }) => (
          <div
            css={css`
              width: max-content;
            `}
          >
            Σ = $
            {currencyFormatter.format(
              Math.round(
                _.sumBy(filteredRows, (row) => Number(row.original.cogs ?? 0))
              )
            )}
          </div>
        ),
        accessor: (x) => x.cogs,
        // derive this by aggregating cogs from project expenses (invoices in code)
        Cell: ({ cell }: CellProps<Row>) => (
          <>{"$" + currencyFormatter.format(Math.round(cell.value))}</>
        ),
      },
      {
        Header: "PO",
        columns: [
          {
            id: "PO Subtotal",
            Header: (
              <Box w="full" textAlign="right">
                PO Subtotal
                <br />
                <small>(Gross Sales)</small>
              </Box>
            ),
            Header2: ({ filteredRows }) => (
              <div
                css={css`
                  width: max-content;
                `}
              >
                Σ = $
                {currencyFormatter.format(
                  Math.round(
                    _.sumBy(filteredRows, (row) =>
                      Number(row.original.po_subtotal ?? 0)
                    )
                  )
                )}
              </div>
            ),
            accessor: (x) => Number(x.po_subtotal),
            Cell: ({ row, cell }: CellProps<Row>) => (
              <PopoverInput
                displayText={
                  "$" + currencyFormatter.format(Math.round(cell.value))
                }
                initialValue={cell.value}
                type="number"
                onSubmit={(e, newValue) => {
                  return updateEstimate({
                    variables: {
                      updateOneEstimateData: {
                        po_subtotal: {
                          set: Number(newValue),
                        },
                      },
                      updateOneEstimateWhere: {
                        id: row.original.id,
                      },
                    },
                  });
                }}
              />
            ),
          },
          {
            id: "Budget Unaccounted For",
            Header: (
              <Box w="full" textAlign="right">
                Budget Unaccounted For
              </Box>
            ),
            Header2: ({ filteredRows }) => (
              <div
                css={css`
                  width: max-content;
                `}
              >
                Σ = $
                {currencyFormatter.format(
                  Math.round(
                    _.sum(
                      filteredRows.flatMap((x) =>
                        Number(x.original.po_subtotal)
                      )
                    ) -
                      _.sum(
                        filteredRows.flatMap((x) =>
                          x.original.projects.map((x) => x.budget)
                        )
                      )
                  )
                )}
              </div>
            ),
            accessor: (row) =>
              row.po_subtotal - _.sumBy(row.projects.flatMap((x) => x.budget)),
            Cell: ({ row }: CellProps<Row>) => (
              <Tooltip
                maxWidth="40vw"
                label={
                  <EstimateBudgetUnaccountedForTooltipContent
                    estimateId={row.original.id}
                  />
                }
              >
                <span>
                  <Money
                    value={
                      Number(row.original.po_subtotal) -
                      _.sumBy(row.original.projects.flatMap((x) => x.budget))
                    }
                  />
                </span>
              </Tooltip>
            ),
          },
          {
            id: "po_hst",
            Header: "HST",
            Header2: ({ filteredRows }) => (
              <div
                css={css`
                  width: max-content;
                `}
              >
                Σ = $
                {currencyFormatter.format(
                  _.sumBy(filteredRows, (row) =>
                    Math.round(Number(row.original.po_subtotal) * 0.13)
                  )
                )}
              </div>
            ),
            accessor: (x) => Number(x.po_subtotal) * 0.13,
            Cell: ({ cell }: CellProps<Row>) => (
              <>{"$" + currencyFormatter.format(Math.round(cell.value))}</>
            ),
          },
          {
            id: "po_total",
            Header: "Total",
            Header2: ({ filteredRows }) => (
              <div
                css={css`
                  width: max-content;
                `}
              >
                Σ = $
                {currencyFormatter.format(
                  _.sumBy(filteredRows, (row) =>
                    Math.round(
                      Number(row.original.po_subtotal) * 0.13 +
                        Number(row.original.po_subtotal)
                    )
                  )
                )}
              </div>
            ),
            accessor: (x) =>
              Math.round(Number(x.po_subtotal) * 0.13 + Number(x.po_subtotal)),
            Cell: ({ cell }: CellProps<Row>) => (
              <>{"$" + currencyFormatter.format(Math.round(cell.value))}</>
            ),
          },
        ],
      },

      {
        id: "status",
        Header: "Status",
        accessor: (x) =>
          x.estimate_status && transformEstimateStatus(x.estimate_status),
        Cell: ({ cell }: CellProps<Row>) => cell.value,
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
      },

      {
        id: "subtotalRemainingOnPO",
        Header: (
          <Box w="full" textAlign="right">
            Subtotal remaining on PO
          </Box>
        ),
        Header2: ({ filteredRows }) => (
          <div
            css={css`
              width: max-content;
            `}
          >
            Σ = $
            {currencyFormatter.format(
              _.sumBy(filteredRows, (row) =>
                Number(row.original.po_subtotal_remaining ?? 0)
              )
            )}
          </div>
        ),
        accessor: (x) => Number(x.po_subtotal_remaining),
        Cell: ({ cell }: CellProps<Row>) => (
          <>{"$" + currencyFormatter.format(Math.round(cell.value))}</>
        ),
      },

      {
        id: "createInvoice",
        Header: "Actions",
        Cell: ({ row }: CellProps<Row>) => (
          <Stack spacing="2" direction="row">
            <CreateNewClientInvoiceCell
              estimateId={row.original.id}
              estimateName={row.original.name}
              poSubtotalRemaining={row.original.po_subtotal_remaining}
            />
            <Button
              {
                // @ts-expect-error
                ...row.getToggleRowExpandedProps()
              }
              size="sm"
              ml="auto"
              width="10em"
              variant="outline"
            >
              {
                // @ts-expect-error
                row.isExpanded ? "Hide" : "Show"
              }{" "}
              {row.original.client_invoice_count} Invoices
            </Button>
            <Popover>
              {({ onClose }) => (
                <>
                  <PopoverTrigger>
                    <Button size="sm">Delete</Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                      <FormControl>
                        <FormLabel>
                          Confirm deletion of estimate{" "}
                          <em>{row.original.po_number || `(No PO #)`}</em> -{" "}
                          <em>{row.original.name}</em>
                        </FormLabel>
                      </FormControl>
                      <Button
                        type="submit"
                        mt="2"
                        size="sm"
                        colorScheme="red"
                        onClick={async () => {
                          await deleteOneEstimate({
                            variables: {
                              deleteOneEstimateWhere: {
                                id: row.original.id,
                              },
                            },
                            update(cache) {
                              cache.modify({
                                fields: {
                                  estimates(
                                    existingEstimateRefs,
                                    { readField }
                                  ) {
                                    return existingEstimateRefs.filter(
                                      (ref: any) =>
                                        row.original.id !== readField("id", ref)
                                    );
                                  },
                                },
                              });
                            },
                          });
                          onClose();
                        }}
                      >
                        DELETE
                      </Button>
                    </PopoverBody>
                  </PopoverContent>
                </>
              )}
            </Popover>
            ;
          </Stack>
        ),
      },

      {
        id: "comments",
        Header: "EST comments",
        accessor: (x) => x.comments,
        Cell: ({ row }: CellProps<Row>) => {
          return (
            <PopoverInput
              initialValue={row.original.comments ?? ""}
              supressEditIcon={true}
              displayText={
                <TextTruncateRevealOnHover
                  wrap
                  css={css`
                    max-width: 30em;
                    .group-hover-show {
                      text-decoration: underline;
                    }
                  `}
                  text={
                    <>
                      {row.original.comments}
                      <EditIcon
                        className="cell-hover-visible"
                        style={{ marginLeft: "0.5em" }}
                      />
                    </>
                  }
                />
              }
              onSubmit={async (e, newValue) => {
                await updateEstimate({
                  variables: {
                    updateOneEstimateData: {
                      comments: {
                        set: newValue,
                      },
                    },
                    updateOneEstimateWhere: {
                      id: row.original.id,
                    },
                  },
                });
              }}
            />
          );
        },
      },
    ];
    return columns.map((columnDefinition) => ({
      ...columnDefinition,
      ...("sortDescFirst" in columnDefinition ? null : { sortDescFirst: true }),
    }));
  }, [
    companiesData?.companies,
    peopleData?.people,
    postProcessedRows,
    updateEstimate,
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    // state: { expanded },
  } = useTable(
    {
      // @ts-expect-error
      columns: columnDefinitions,
      // @ts-expect-error
      data: postProcessedRows ?? EMPTY_ARRAY,
      // @ts-expect-error
      defaultColumn,
      filterTypes,
      autoResetFilters: false,
      autoResetSortBy: false,
      // autoResetGroupBy: false,
      autoResetExpanded: false,
      autoResetSelectedRows: false,
      initialState: {
        // hiddenColumns: initiallyHiddenColumns,
        // @ts-expect-error
        sortBy: defaultSortBy,
      },
    },
    useFilters,
    // useGroupBy,
    useSortBy,
    useExpanded
  );

  return (
    <>
      <Box css={tw`px-6 py-0`}>
        <Heading size="md" mb="2">
          Estimate Tracker
        </Heading>
        {isDrawerOpen && pdfFileKey && (
          <SideDrawer
            isOpen={isDrawerOpen}
            onClose={() => {
              setPdfFileKey(null);
              setIsDrawerOpen(false);
            }}
          >
            <PdfViewer s3Key={pdfFileKey} />
          </SideDrawer>
        )}
        <MinMaxDateFilters
          startDate={actualMinStartDate ?? subMonths(new Date(), 6)}
          endDate={actualMaxEndDate ?? new Date()}
          setStartDate={setMinStartDate}
          setEndDate={setMaxEndDate}
        />
      </Box>
      <Box
        css={[
          tw`px-6`,
          css`
            max-height: 100vh;
            overflow-y: auto;
          `,
        ]}
      >
        <table
          {...getTableProps()}
          css={[
            tw`text-sm w-full`,
            css`
              .row-hover-visible {
                opacity: 0;
              }
              & tr:hover .row-hover-visible {
                opacity: 1;
              }

              .cell-hover-visible {
                opacity: 0;
              }
              & td:hover .cell-hover-visible {
                opacity: 1;
              }
            `,
          ]}
        >
          <thead
            css={css`
              position: sticky;
              z-index: 10;
              top: 0;
              &,
              & > tr > th {
                background: aliceblue;
                border-right: solid 1px #ddd;
                border-left: solid 1px #ddd;
              }
              border-top: solid 1px #ddd;
            `}
          >
            {headerGroups.map((headerGroup, headerGroupIndex) => {
              const { key, ...rest } = headerGroup.getHeaderGroupProps();
              return (
                <tr key={key} {...rest}>
                  {headerGroup.headers.map((column) => {
                    const { key, ...rest } = column.getHeaderProps(
                      // @ts-ignore
                      column.getSortByToggleProps()
                    );
                    return (
                      <th
                        key={key}
                        {...rest}
                        css={css`
                        padding: 0.5rem;
                        top: 0;
                        /* backdrop-filter: blur(6px); */
                        ${
                          !column.placeholderOf &&
                          css`
                            border-bottom: solid 1px #ddd;
                          `
                        }
                        /* ${
                          headerGroups.length === 1 ||
                          headerGroups.length - 2 < headerGroupIndex
                            ? css`
                                position: sticky;
                                top: 0;
                                z-index: 10;
                              `
                            : ""
                        } */
                        border-left: solid 1px #ddd;
                        border-right: solid 1px #ddd;
                        box-sizing: border-box;

                        color: black;
                        font-weight: 500;
                      `}
                      >
                        <div style={{ display: "flex" }}>
                          <>
                            {column.render("Header")}

                            {headerGroups.length === 1 ||
                              (headerGroups.length - 2 < headerGroupIndex && (
                                <span>
                                  {
                                    // @ts-expect-error
                                    column.isSorted
                                      ? // @ts-expect-error
                                        column.isSortedDesc
                                        ? " 🔽"
                                        : " 🔼"
                                      : " ↕️"
                                  }
                                </span>
                              ))}
                          </>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
            <tr key="aggregateHeaderRow">
              {headerGroups.slice(-1)[0]?.headers.map((column) => {
                return (
                  <th
                    key={column.id}
                    css={css`
                      background: aliceblue;
                      padding: 0.5rem;
                      border-left: solid 1px #ddd;
                      border-right: solid 1px #ddd;
                      border-bottom: solid 1px #ddd;
                      box-sizing: border-box;

                      color: black;
                      font-weight: 500;
                    `}
                  >
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {
                        // @ts-ignore
                        column.Header2 && column.render("Header2")
                      }
                    </div>
                    {
                      // @ts-expect-error
                      column.canFilter && column.render("Filter")
                    }
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody
            {...getTableBodyProps()}
            css={css`
              & > tr {
                content-visibility: auto;
                contain-intrinsic-size: 1000px;
                & > td {
                  border: solid 1px #ddd;
                  padding: 0.5rem;
                }
              }
            `}
          >
            {rows.map((row) => {
              prepareRow(row);
              const { key, ...rest } = row.getRowProps();
              return (
                <React.Fragment 
                  // @ts-ignore
                  key={row.id}>
                  <tr {...rest} key={key} data-estimate-id={row.original.id}>
                    {row.cells.map((cell) => {
                      const { key, ...rest } = cell.getCellProps();
                      return (
                        <td
                          key={key}
                          {...rest}
                          style={{
                            textAlign:
                              typeof cell.value === "number" ||
                              (typeof cell.value === "string" &&
                                cell.value.startsWith("$"))
                                ? "right"
                                : "left",
                          }}
                        >
                          <>{cell.render("Cell")}</>
                        </td>
                      );
                    })}
                  </tr>
                  {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                  {
                    // @ts-expect-error
                    row.isExpanded ? (
                      <tr>
                        <td colSpan={visibleColumns.length}>
                          <ClientInvoicesListForEstimate
                            estimateId={row.original.id}
                          />
                        </td>
                      </tr>
                    ) : null
                  }
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </Box>
    </>
  );
};

const CreateNewClientInvoiceCell = ({
  estimateId,
  estimateName,
  poSubtotalRemaining,
}: {
  estimateId: string;
  estimateName: string;
  poSubtotalRemaining: number;
}) => {
  const toast = useToast();
  const [createOneClientInvoice, { loading }] = useMutation<
    GraphQLTypes.CreateOneClientInvoiceMutation,
    GraphQLTypes.CreateOneClientInvoiceMutationVariables
  >(gql`
    mutation CreateOneClientInvoice(
      $createOneClientInvoiceData: client_invoiceCreateInput!
    ) {
      createOneClientInvoice(data: $createOneClientInvoiceData) {
        id
        estimate {
          id
          po_subtotal
          po_subtotal_remaining
        }
      }
    }
  `);

  const [amount, setAmount] = useState(poSubtotalRemaining);

  const initialFocusRef = useRef<HTMLInputElement>(null);

  const handleSlackNotification = useCallback((message: string) => {
    fetch(`${RuntimeConfig.backendOrigin}/slack/approval_to_invoice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message }),
    })
      .then(() => {
        toast({
          title: "Slack notification sent",
          description: "Bookkeeper has been notified",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "Error sending Slack notification",
          description: "Please notify the bookkeeper in the #dashboard_approval_to_invoice Slack channel. Please contact the Dashboard admin to investigate this error.",
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      });
  }, []);

  const grantApprovalToInvoice = async (e: React.FormEvent) => {
    e.preventDefault();
    await createOneClientInvoice({
      variables: {
        createOneClientInvoiceData: {
          estimate: {
            connect: {
              id: estimateId,
            },
          },
          amount_in_cents: Math.round(amount * 100),
        },
      },
      update(cache) {
        cache.evict({
          id: cache.identify({
            __typename: "Estimate",
            id: estimateId,
          }),
          fieldName: "po_subtotal_remaining",
        });
        cache.evict({
          id: cache.identify({
            __typename: "Estimate",
            id: estimateId,
          }),
          fieldName: "client_invoices",
        });
      },
    });
    setAmount(0);
    handleSlackNotification(
      `Approval to invoice for $${amount} has been granted for estimate ${estimateName}`
    );
  };

  return (
    <Popover initialFocusRef={initialFocusRef} onClose={() => setAmount(0)}>
      <PopoverTrigger>
        <Button size="sm">Grant approval to invoice</Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />

        <PopoverBody>
          <form onSubmit={grantApprovalToInvoice}>
            <FormControl>
              <FormLabel>Amount to Invoice</FormLabel>
              <Input
                isDisabled={loading}
                ref={initialFocusRef}
                type="number"
                autoFocus
                value={amount ?? 0}
                size="sm"
                onChange={(e) => {
                  setAmount(Number(e.target.value));
                }}
              />
            </FormControl>
            <Slider
              isDisabled={loading}
              aria-label="slider-ex-4"
              value={amount}
              max={poSubtotalRemaining}
              focusThumbOnChange={false}
              onChange={(val) => setAmount(val)}
              step={500}
            >
              <SliderTrack bg="red.100">
                <SliderFilledTrack bg="tomato" />
              </SliderTrack>
              <SliderThumb boxSize={5} color="tomato" />
            </Slider>
            <Button type="submit" mt="2" size="sm" isDisabled={loading}>
              Submit
            </Button>
          </form>
        </PopoverBody>
        <PopoverCloseButton />
      </PopoverContent>
    </Popover>
  );
};

export const EstimateFragmentForEstimatesPage = gql`
  fragment EstimateFragmentForEstimatesPage on Estimate {
    id
    date
    owner {
      id
    }
    name
    po_number
    po_subtotal
    pdf_file_key
    company {
      id
      company_name
    }
    brand {
      id
      brand_name
    }
    projects {
      id
      budget
    }
    cogs
    po_subtotal_remaining
    client_invoice_count
    estimate_status
    comments
  }
`;
