
import tw, { css } from "twin.macro";
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { useState } from "react";
import { useGrossProfitSummaryQuery } from "../../codegen/graphql-types";
import { GrossProfitReportContents } from "./GrossProfitReportContents";
import { RevenueForecasts } from "./RevenueForecasts/RevenueForecasts";
import { useAuthContext } from "../../useAuth";
import { FullSpinner } from "../../FullSpinner";

export const GrossProfitSummary = () => {
  const { isAdmin } = useAuthContext();
  
  const [yearInFocus, setYearInFocus] = useState(() => DateTime.now().year);

  const startDate = DateTime.fromISO(yearInFocus.toString())
    .minus({
      years: 1,
    })
    .startOf("year");
  const endDate = DateTime.fromISO(yearInFocus.toString()).endOf("year");

  const { data, loading } = useGrossProfitSummaryQuery({
    variables: {
      start_date: startDate.toISODate(),
      end_date: endDate.toISODate(),
    },
  });

  if (loading) {
    return <FullSpinner />;
  }
  return (
    <div css={[tw`px-6 pt-6 flex flex-col items-start w-full`, css`max-width: 100vw; overflow: scroll;`]}>
      <FormControl
        css={css`
          max-width: 10em;
        `}
      >
        <FormLabel>Graph year</FormLabel>
        <NumberInput
          defaultValue={yearInFocus}
          onChange={(newYear) => setYearInFocus(Number(newYear))}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>

      <RevenueForecasts yearInFocus={yearInFocus} isAdmin={isAdmin} />

      {data && (
        <GrossProfitReportContents
          data={data}
          startYear={startDate.year}
          endYear={endDate.year}
        />
      )}
    </div>
  );
};
