import tw from "twin.macro";
import { gql, useLazyQuery } from "@apollo/client";
import AsyncSelect from "react-select/async";
import { useNavigate } from "react-router-dom";

//  https://mavenmm.teamwork.com/projects/api/v3/projects/496857/tasklists
const ProjectSelector = () => {
  const navigate = useNavigate();

  const handleSelectProject = async (option: { 
    value?: string, 
    label: string,
    last_synced_details_at: Date
  }) => {
    // Navigate to the selected project page /pricing/:projectId
    navigate(`/pricing/${option?.value}`);
  };

  // Fetch Projects from DB
  const [searchProjects] = useLazyQuery<any>(
    gql`
      query SearchProjectsForEstimator($keyword: String) {
        searchProjects: projects(
          where: {
            AND: [
              {
                OR: [
                  { name: { contains: $keyword } }
                  { description: { contains: $keyword } }
                  { component_code: { contains: $keyword } }
                ]
              }
            ]
          }
          first: 20
        ) {
          id
          name
        }
      }
    `
  );

  // Fetch options for dropdown select
  const promiseOptions = async (inputValue: string) => {
    try {
      return new Promise<any[]>(async (resolve) => {
        // If keyword is empty, don't search
        if (inputValue === "" || inputValue === undefined) {
          resolve([]);
        }

        const res = await searchProjects({
          variables: { keyword: inputValue },
        });

        if (res.data && res.data.searchProjects) {
          const formattedOptions = res?.data?.searchProjects.map(
            (project: any) => ({
              label: project.name, // Assuming you want to show the project name
              value: project.id, // Assuming the value should be the project id
            })
          );

          resolve(formattedOptions);
        }

        resolve([]);
      });
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  return (
    <div>
      <AsyncSelect
        cacheOptions
        loadOptions={promiseOptions}
        defaultOptions
        onChange={handleSelectProject}
        css={tw`min-w-64`}
      />
    </div>
  );
};

export default ProjectSelector;
