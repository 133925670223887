import React, { useCallback, useState, createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './helpers/useLocalStorage'; // Adjust the import path as needed
import { RuntimeConfig } from './RuntimeConfig'; // Adjust the import path as needed

const AuthContext = createContext<ReturnType<typeof useAuth> | null>(null);

export const AuthContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const userData = useAuth();
  return (
    <AuthContext.Provider value={userData}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within a AuthContextProvider");
  }
  return context;
};

const useAuth = () => {
  const [loggedInUserData, setLoggedInUserData] = useLocalStorage<
    | {
        accessToken: string;
        email: string;
        profileImage: string;
      }
    | null
  >("loggedInUserData", null);
  const navigate = useNavigate();

  const [adminUserOverride, setAdminUserOverride] = useState<string | null>(
    loggedInUserData?.email ?? null
  );

  const handleLoginCode = useCallback((code: string) => {
    const redirectUri = `${window.location.origin}/handle_login`;
    fetch(`${RuntimeConfig.backendOrigin}/teamwork/token-exchange`, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code,
        redirect_uri: redirectUri,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoggedInUserData({
          accessToken: data.access_token,
          email: data.user.email,
          profileImage: data.user.avatar,
        });
        navigate("/");
      });
  }, []);

  const signOut = useCallback(() => {
    setLoggedInUserData(null);
    setAdminUserOverride(null);
    navigate("/");
  }, []);

  // This is the actual logged in user's email - this will stay the same even if the adminUserOverride is set
  const email = loggedInUserData?.email.toLowerCase();
  
  const adminEmails = [
    "kim@mavenmm.com",
    "adam@mavenmm.com",
    "chang@mavenmm.com",
    "dave@mavenmm.com",
    "kris@mavenmm.com",
    "chris@mavenmm.com",
    "drjrmakerewich@gmail.com", // admin assistant
    "rmbookkeeping@mavenmm.com", // bookkeeper
    "utsav.nakra@theceosrighthand.co", // Utsav
    "utsav@mavenmm.com", // Utsav TW account
  ];

  const managerEmails = [
    // "adam@mavenmm.com",
    "danika@mavenmm.com",
    "maryssa@mavenmm.com",
    "marty@mavenmm.com",
    "sydney@mavenmm.com"
  ];

  const quickBooksEmails = [
    "adam@mavenmm.com",
    "dave@mavenmm.com",
    "kris@mavenmm.com",
    "rmbookkeeping@mavenmm.com", // bookkeeper
    "utsav.nakra@theceosrighthand.co", // Utsav
    "utsav@mavenmm.com"
  ];

  const betaTesterEmails = [
    "adam@mavenmm.com",
    "rondie@mavenmm.com",
    "dave@mavenmm.com"
  ];

  const canBeAdmin = email && adminEmails.includes(email);

  const isAdmin =
    canBeAdmin &&
    (!adminUserOverride || adminEmails.includes(adminUserOverride));

  const isManager = email && managerEmails.includes(email);
  
  const canBeAccounting = email && quickBooksEmails.includes(email);

  const isAccounting = canBeAccounting && (adminUserOverride && quickBooksEmails.includes(adminUserOverride));

  const isBetaTester = email && betaTesterEmails.includes(email);

  return {
    isAdmin,
    isManager,
    isAccounting,
    isBetaTester,
    canBeAdmin,
    email,
    handleLoginCode,
    signOut,
    accessToken: loggedInUserData?.accessToken,
    loggedInUserData,
    setLoggedInUserData,
    adminUserOverride,
    setAdminUserOverride,
  };
};

export default useAuth;