
import { startOfYear, subMonths } from "date-fns/esm";
import {
  Stack,
  Button,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  background,
} from "@chakra-ui/react";
import Select from "react-select";
import _ from "lodash";
import { useApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";
import tw, { css } from "twin.macro";
import { useLocalStorage } from "../../helpers/useLocalStorage";
import { usePeopleContext } from "../../helpers/usePeopleContext";
import { ProjectsTableDataContainer } from "./ProjectsTableDataContainer";
import { useAuthContext } from "../../useAuth";
import { FullSpinner } from "../../FullSpinner";
import { projectStatuses, ProjectStatus } from "./projectStatuses";
import "./ProjectsPage.css";

export type ProjectFilters = {
  minStartDate: Date | undefined | null;
  selectedProjectStatuses: ProjectStatus[];
  selectedOwnerIds: string[];
  selectedSeniorBrandMavenLeadNames: Array<string | null>;
}

type ProjectPageFiltersContents = ProjectFilters | null;

export const ProjectsPage = () => {
  const [filtersToUse, setFiltersToUse] = useState<ProjectPageFiltersContents>(
    null
  );

  return (
    <div
      className="horizontal-scroll-container vertical-scroll-container"
      // css={[
      //   tw`h-full`,
      //   css`
      //     min-width: 100%;
      //     width: fit-content;
      //     max-width: 100vw;
      //     overflow-x: auto;
      //   `,
      // ]}
    >
      <ProjectPageFilters
        setFiltersToUse={setFiltersToUse}
        filtersToUse={filtersToUse}
      />
      {filtersToUse && <ProjectsTableDataContainer {...filtersToUse} />}
    </div>
  );
};

const selectStyles = {
  input: (provided: any) => {
    return {
      ...provided,
      margin: 0
    };
  },
  menu: (provided: any) => {
    return {
      ...provided,
      "z-index": 9999,
    };
  }
};

const ProjectPageFilters = ({
  setFiltersToUse,
  filtersToUse,
}: {
  setFiltersToUse: React.Dispatch<
    React.SetStateAction<ProjectPageFiltersContents>
  >;
  filtersToUse: ProjectPageFiltersContents;
}) => {
  const peopleData = usePeopleContext();
  const apolloClient = useApolloClient();
  const { 
    // isAdmin,
    email: myEmail 
  } = useAuthContext();

  // const podMateIds = peopleData.data?.me?.pods.flatMap((pod) =>
  //   pod.members.map((member) => member.id)
  // );

  const loggedInPerson = peopleData?.data?.people.find(
    (person) => person.email_address === myEmail
  );

  const [minStartDate, setMinStartDate] = useLocalStorage<Date | null>(
    "minStartDate",
    subMonths(startOfYear(new Date()), 1)
  );

  const [ownerId, setOwnerIds] = useLocalStorage<string | string[] | null>(
    "ownerId",
    loggedInPerson?.id ?? null
  );

  const [projectStatus, setProjectStatuses] = useLocalStorage<
    ProjectStatus | ProjectStatus[] | null
  >("projectStatus", "In Progress");

  const [
    selectedSeniorBrandMavenLeadNames,
    setSelectedSeniorBrandMavenLeadNames,
  ] = useLocalStorage<Array<string | null>>("sbmNames", []);

  const selectedProjectStatuses = Array.isArray(projectStatus)
    ? projectStatus
    : projectStatus
    ? [projectStatus]
    : [];

  const selectedOwnerIds = Array.isArray(ownerId)
    ? ownerId
    : ownerId
    ? [ownerId]
    : [];

  useEffect(() => {
    loggedInPerson?.id && !ownerId && setOwnerIds([loggedInPerson?.id]);
  }, [loggedInPerson?.id, ownerId, setOwnerIds]);

  const actualMinStartDate =
    typeof minStartDate === "string" ? new Date(minStartDate) : minStartDate;

  if (
    actualMinStartDate && // not january first
    (actualMinStartDate.getUTCDate() !== 1 ||
      actualMinStartDate.getUTCMonth() !== 0)
  ) {
    // migrate old minStartDate to start at start of year
    setMinStartDate(new Date(`${actualMinStartDate.getUTCFullYear()}-01-01`));
  }

  useEffect(() => {
    apolloClient.resetStore();
  }, [apolloClient]);

  if (peopleData.loading) {
    return <FullSpinner />;
  }

  return (
    <Stack
      css={css`
        position: relative;
      `}
      as="form"
      onSubmit={(e) => {
        e.preventDefault();
        setFiltersToUse(() => ({
          minStartDate,
          selectedProjectStatuses,
          selectedOwnerIds,
          selectedSeniorBrandMavenLeadNames,
        }));
      }}
      direction="row"
      justifyContent="flex-start"
      mb="2"
      padding="6"
    >
      <FormControl width="auto">
        <FormLabel>Minimum Start Date</FormLabel>
        <NumberInput
          defaultValue={actualMinStartDate?.getUTCFullYear()}
          onChange={(newYear) => setMinStartDate(new Date(`${newYear}-01-01`))}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>

      <FormControl
        width={Math.max(
          Math.min(100 + selectedProjectStatuses.length * 100, 100 * 4),
          150
        )}
      >
        <FormLabel>Project Status</FormLabel>
        <Select<{ value: ProjectStatus; label: string }, true>
          isMulti
          styles={selectStyles}
          closeMenuOnSelect={false}
          defaultValue={selectedProjectStatuses.map((projectStatus) => ({
            value: projectStatus,
            label: projectStatus as string,
          }))}
          onChange={(selectedOptions) => {
            setProjectStatuses(
              Array.from((selectedOptions ?? []).map((x) => x.value))
            );
          }}
          options={projectStatuses.map((status) => {
            return {
              value: status,
              label: status ?? "[unassigned]",
            };
          })}
        />
      </FormControl>

      {loggedInPerson?.id && (
        <FormControl
          width={Math.max(
            Math.min(100 + selectedOwnerIds.length * 120, 120 * 4),
            150
          )}
        >
          <FormLabel>Owner</FormLabel>
          <Select<{ value: string; label: string }, true>
            isMulti
            styles={selectStyles}
            defaultValue={selectedOwnerIds.map((ownerId) => {
              const person = peopleData.data?.people.find(
                (x) => x.id === ownerId
              );
              return {
                value: ownerId,
                label: `${person?.first_name} ${person?.last_name}`,
              };
            })}
            onChange={(selectedOptions) => {
              setOwnerIds(
                Array.from((selectedOptions ?? []).map((x) => x.value))
              );
            }}
            options={_.orderBy(
              peopleData?.data?.people ?? [],
              (x) => x.first_name
            ).flatMap((person) => {
              if (!person.email_address.toLowerCase().includes("mavenmm.com")) {
                return [];
              }
              return {
                value: person?.id ?? "NONE",
                label: person
                  ? `${person.first_name} ${person.last_name}`
                  : "NONE",
              };
            })}
          />
        </FormControl>
      )}

      <FormControl
        width={Math.max(
          Math.min(
            100 + selectedSeniorBrandMavenLeadNames.length * 120,
            120 * 4
          ),
          150
        )}
      >
        <FormLabel>Pod</FormLabel>
        <Select<{ value: string | null; label: string }, true>
          isMulti
          styles={selectStyles}
          defaultValue={selectedSeniorBrandMavenLeadNames.map((sbmName) => {
            return {
              value: sbmName ?? null,
              label: sbmName ?? "None",
            };
          })}
          onChange={(selectedOptions) => {
            setSelectedSeniorBrandMavenLeadNames(
              Array.from((selectedOptions ?? []).map((x) => x.value))
            );
          }}
          options={(peopleData?.data?.pods ?? [])
            .flatMap((pod) => {
              if (!pod) {
                return {
                  value: null,
                  label: "None",
                };
              }
              return {
                value: pod.name,
                label: pod.name,
              };
            })
            .concat({
              value: null,
              label: "None",
            })}
        />
      </FormControl>

      <FormControl width={160}>
        <FormLabel>&nbsp;</FormLabel>
        <Button
          type="submit"
          disabled={_.isEqual(filtersToUse, {
            minStartDate,
            projectStatus,
            ownerId,
          })}
        >
          {filtersToUse ? "Update Filters" : "Fetch Projects"}
        </Button>
      </FormControl>
    </Stack>
  );
};
