import { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Box,
  Stack,
} from "@chakra-ui/react";
import tw from "twin.macro";
import {
  useAdminCreateRoleMutation,
  useAdminCreateTeamMutation,
  useAdminUpdateRoleMutation,
  useAdminUpdateTeamMutation,
  useAdminDeleteRoleMutation,
  useAdminDeleteTeamMutation,
  useAdminUpdateRoleOrderMutation,
  useAdminUpdateTeamOrderMutation,
  AdminGetAllRolesQuery,
  AdminGetAllTeamsQuery,
  Role,
  Team,
} from "../../codegen/graphql-types";
import DragSortList from "./DragSortList";

const RolesAndTeams = (props: {
  rolesData: AdminGetAllRolesQuery;
  teamsData: AdminGetAllTeamsQuery;
  refetchRoles: () => void;
  refetchTeams: () => void;
}) => {
  const [addNewRole, setAddNewRole] = useState(false);
  const [addNewTeam, setAddNewTeam] = useState(false);

  // Create
  const [createRole] = useAdminCreateRoleMutation();
  const [createTeam] = useAdminCreateTeamMutation();
  // Update
  const [updateRole] = useAdminUpdateRoleMutation();
  const [updateTeam] = useAdminUpdateTeamMutation();
  // Delete
  const [deleteRole] = useAdminDeleteRoleMutation();
  const [deleteTeam] = useAdminDeleteTeamMutation();

  // Update order
  const [updateRoleOrder] = useAdminUpdateRoleOrderMutation();
  const [updateTeamOrder] = useAdminUpdateTeamOrderMutation();

  const OPTIMISTIC_TEMP_PREFIX = "@@TEMP_ID@@"; // Prefix for optimistic temp IDs

  if (!props.rolesData || !props.teamsData) return null;

  if (!props.rolesData.roles || !props.teamsData.teams) return null;

  const roles = props.rolesData.roles as Role[];
  const teams = props.teamsData.teams as Team[];

  return (
    <>
      <Heading size="lg" color="#e535ab">
        Roles & Teams
      </Heading>
      <Stack direction="row" css={tw`gap-10 flex-wrap px-6 py-2`}>
        {/* Roles */}
        <Stack direction="column" css={tw`w-1/3`}>
          <Heading size="md">Roles</Heading>
          <DragSortList
            type="Role"
            items={roles}
            onUpdateOrder={(updatedList) => {
              updateRoleOrder({
                variables: {
                  roles: updatedList.map((role) => ({
                    id: role.id,
                    order: updatedList.indexOf(role),
                  })),
                },
                onCompleted: () => {
                  props.refetchRoles();
                },
              });
            }}
            onUpdateName={(id: string, newName: string) => {
              updateRole({
                variables: { id, roleTitle: newName },
                onCompleted: () => {
                  props.refetchRoles();
                },
              });
            }}
            onDelete={(id: string) => {
              deleteRole({
                variables: { id },
                onCompleted: () => {
                  props.refetchRoles();
                },
              });
            }}
          />
          {!addNewRole && (
            <Button size="xs" width="100px" onClick={() => setAddNewRole(true)}>
              Add New Role
            </Button>
          )}
          {addNewRole && (
            <Box css={tw`w-1/2 border rounded-md p-4`}>
              {/* Use the native form element for form submission */}
              <form
                css={tw`flex flex-col gap-y-2`}
                id="roleTitleForm"
                onSubmit={(e) => {
                  e.preventDefault();
                  const form = e.target as HTMLFormElement; // Cast the target to HTMLFormElement type
                  const newRoleTitle = (form.elements.namedItem(
                    "roleTitle"
                  ) as HTMLInputElement).value;
                  createRole({
                    variables: { roleTitle: newRoleTitle },
                    optimisticResponse: {
                      __typename: "Mutation",
                      createRole: {
                        __typename: "Role",
                        id: `${OPTIMISTIC_TEMP_PREFIX}:${newRoleTitle}`,
                        roleTitle: newRoleTitle,
                        order: 0,
                      },
                    },
                    onCompleted: () => {
                      props.refetchRoles();
                    },
                  });
                  setAddNewRole(false); // Close the form after submission
                }}
              >
                <FormControl size="xs" id="roleTitle">
                  <FormLabel>Add New Role</FormLabel>
                  <Input
                    size="xs"
                    required
                    name="roleTitle"
                    id="roleTitle"
                    aria-label="Role Title"
                  />
                </FormControl>
                <div css={tw`flex justify-between`}>
                  <Button size="xs" onClick={() => setAddNewRole(false)}>
                    Cancel
                  </Button>
                  <Button size="xs" type="submit">
                    Submit
                  </Button>
                </div>
              </form>
            </Box>
          )}
        </Stack>
        {/* Teams */}
        <Stack direction="column" css={tw`w-1/3`}>
          <Heading size="md">Teams</Heading>
          <DragSortList
            type="Team"
            items={teams}
            onUpdateOrder={(updatedList) => {
              updateTeamOrder({
                variables: {
                  teams: updatedList.map((team) => ({
                    id: team.id,
                    order: updatedList.indexOf(team),
                  })),
                },
                onCompleted: () => {
                  props.refetchTeams();
                },
              });
            }}
            onUpdateName={(id: string, newName: string) => {
              updateTeam({
                variables: { id, teamName: newName },
                onCompleted: () => {
                  props.refetchTeams();
                },
              });
            }}
            onDelete={(id: string) => {
              deleteTeam({
                variables: { id },
                onCompleted: () => {
                  props.refetchTeams();
                },
              });
            }}
          />
          {!addNewTeam && (
            <Button size="xs" width="100px" onClick={() => setAddNewTeam(true)}>
              Add New Team
            </Button>
          )}
          {addNewTeam && (
            <Box css={tw`w-1/2 border rounded-md p-4`}>
              {/* Use the native form element for form submission */}
              <form
                css={tw`flex flex-col gap-y-2`}
                id="teamNameForm"
                onSubmit={(e) => {
                  e.preventDefault();
                  const form = e.target as HTMLFormElement; // Cast the target to HTMLFormElement type
                  const newTeamName = (form.elements.namedItem(
                    "teamName"
                  ) as HTMLInputElement).value;
                  createTeam({
                    variables: { teamName: newTeamName },
                    optimisticResponse: {
                      __typename: "Mutation",
                      createTeam: {
                        __typename: "Team",
                        id: `${OPTIMISTIC_TEMP_PREFIX}:${newTeamName}`,
                        teamName: newTeamName,
                        order: 0,
                      },
                    },
                    onCompleted: () => {
                      props.refetchTeams();
                    },
                  });
                }}
              >
                <FormControl size="xs" id="teamName">
                  <FormLabel>Add New Team</FormLabel>
                  <Input
                    size="xs"
                    required
                    name="teamName"
                    id="teamName"
                    aria-label="Team Name"
                  />
                </FormControl>
                <div css={tw`flex justify-between`}>
                  <Button size="xs" onClick={() => setAddNewTeam(false)}>
                    Cancel
                  </Button>
                  <Button size="xs" type="submit">
                    Submit
                  </Button>
                </div>
              </form>
            </Box>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default RolesAndTeams;
