import tw, { css } from "twin.macro";
import DOMPurify from "dompurify";
import { useState } from "react";
import {
  PricingComment,
  ProjectPricing,
  useCreatePricingCommentMutation,
  useUpdatePricingCommentMutation,
  useDeletePricingCommentMutation,
} from "../../codegen/graphql-types";
import { Button, IconButton } from "@chakra-ui/react";
import {
  EditIcon,
  DeleteIcon,
  CloseIcon,
  CheckCircleIcon,
} from "@chakra-ui/icons";
import { usePeopleContext } from "../../helpers/usePeopleContext";
import { usePricingDataContext } from "../../helpers/usePricingData";
import TiptapEditor from "./TiptapEditor";

export type CommentType = "assumptions" | "learnings";

const NewComment = ({
  commentType,
  onSubmit,
}: {
  commentType?: CommentType;
  onSubmit: (
    content: string,
    isAssumptions: boolean,
    isLearnings: boolean
  ) => void;
}) => {
  // initial value was copied from the DOM after typing in what we wanted the default comment to be
  const [assumptionsContent, setAssumptionsContent] = useState(``);
  const [learningsContent, setLearningsContent] = useState(``);
  const [edit, setEdit] = useState(false);

  const handleSubmit = () => {
    if (!commentType) {
      // create a comment for assumptions
      onSubmit(assumptionsContent, true, false);
      setAssumptionsContent("");
    
      // create a comment for learnings
      onSubmit(learningsContent, false, true);
      setLearningsContent("");
    }

    if (commentType === "assumptions") {
      onSubmit(assumptionsContent, true, false);
      setAssumptionsContent("");
    }

    if (commentType === "learnings") {
      onSubmit(learningsContent, false, true);
      setLearningsContent("");
    }
  };

  if (!edit) {
    return (
      <section css={tw`w-full flex justify-end items-center gap-x-3`}>
        <span css={tw`italic text-sm`}>Assumptions & Learnings</span>
        <IconButton
          size="sm"
          colorScheme={"blue"}
          aria-label="Write assumptions & learnings"
          icon={<EditIcon />}
          onClick={() => setEdit(true)}
        />
      </section>
    );
  }

  if (edit && commentType === "assumptions") {
    return (
      <div css={tw`flex flex-col gap-y-2`}>
        <TiptapEditor
          id={"new-comment-editor-assumptions"}
          value={assumptionsContent}
          setValue={setAssumptionsContent}
          onSave={handleSubmit}
          edit={edit}
          setEdit={setEdit}
          placeholder={"Assumptions..."}
          disabled={false}
        />
        <Button size="sm" colorScheme="blue" onClick={handleSubmit}>
          Post 
        </Button>
      </div>
    )
  }

  if (edit && commentType === "learnings") {
    return (
      <div css={tw`flex flex-col gap-y-2`}>
        <TiptapEditor
          id={"new-comment-editor-learnings"}
          value={learningsContent}
          setValue={setLearningsContent}
          onSave={handleSubmit}
          edit={edit}
          setEdit={setEdit}
          placeholder={"Learnings..."}
          disabled={false}
        />
        <Button size="sm" colorScheme="blue" onClick={handleSubmit}>
          Post 
        </Button>
      </div>
    )
  }

  return (
    <section css={tw`flex flex-col gap-y-2`}>
      <div css={tw`flex flex-col`}>
        <label>Assumptions</label>
        <TiptapEditor
          id={"new-comment-editor-assumptions"}
          value={assumptionsContent}
          setValue={setAssumptionsContent}
          onSave={handleSubmit}
          edit={edit}
          setEdit={setEdit}
          placeholder={"Assumptions..."}
          disabled={false}
        />
      </div>
      <div css={tw`flex flex-col`}>
        <label>Learnings</label>
        <TiptapEditor
          id={"new-comment-editor-learnings"}
          value={learningsContent}
          setValue={setLearningsContent}
          onSave={handleSubmit}
          edit={edit}
          setEdit={setEdit}
          placeholder={"Learnings..."}
          disabled={false}
        />
        <Button size="sm" colorScheme="blue" onClick={handleSubmit}>
          Post 
        </Button>
      </div>
    </section>
  );
};

const TasklistComments = ({
  projectId,
  projectPricing,
  tasklistId,
  tasklistName,
}: {
  projectId: string;
  projectPricing: Pick<ProjectPricing, "id" | "project_id" | "comments">;
  tasklistId: number | null;
  tasklistName: string;
}) => {
  const [commentType, setCommentType] = useState<CommentType>("assumptions");
  const isForCogs = tasklistName === "COGS";
  const { refetchProjectPricing } = usePricingDataContext();
  const peopleData = usePeopleContext();
  const userId = peopleData.data?.me?.id;
  const [createPricingComment] = useCreatePricingCommentMutation();
  // const isThereAtLeastOneComment = projectPricing.comments.length > 0;

  const handleCommentSubmit = async (
    comment: string,
    isAssumptions: boolean,
    isLearnings: boolean
  ) => {
    if (!userId || !projectId || !projectPricing.id) {
      console.log("projectId", projectId);
      console.log("projectPricing.id", projectPricing.id);
      console.log("userId", userId);
      console.error("Missing required data for comment submission");
      return;
    }

    try {
      await createPricingComment({
        variables: {
          projectId: projectId,
          projectPricingId: projectPricing.id,
          tasklistId: tasklistId?.toString() ?? "0",
          createdById: userId,
          comment: comment,
          isForCogs: isForCogs,
          isAssumptions: isAssumptions,
          isLearnings: isLearnings,
        },
        onCompleted: (data) => {
          refetchProjectPricing();
        },
        onError: (error) => {
          console.error("Error creating comment:", error);
        },
      });
    } catch (error) {
      console.error("Error creating project pricing data:", error);
    }
  };

  const commentsForTasklist = projectPricing.comments.filter(
    (comment) =>
      comment.tasklist_id === tasklistId?.toString() && !comment.is_for_cogs
  );
  const commentsForCogs = projectPricing.comments.filter(
    (comment) => comment.is_for_cogs
  );

  const comments = isForCogs ? commentsForCogs : commentsForTasklist;

  return (
    <div
      css={[
        tw`w-1/4 flex flex-col justify-end p-4 border border-gray-300 rounded-md shadow-sm bg-gray-50`,
        css`
          transition: all 0.3s;
          min-width: max-content;
          max-width: 400px;
        `,
      ]}
    >
      {/* Tabs for switching commentType */}
      {comments.length > 0 && (
        <div css={tw`flex gap-2`}>
          <Button
            size="sm"
            colorScheme={commentType === "assumptions" ? "blue" : "gray"}
            onClick={() => setCommentType("assumptions")}
          >
            Assumptions
          </Button>
          <Button
            size="sm"
            colorScheme={commentType === "learnings" ? "blue" : "gray"}
            onClick={() => setCommentType("learnings")}
          >
            Learnings
          </Button>
        </div>
      )}

      {comments.length > 0 && (
        <CommentList
          commentType={commentType}
          comments={comments}
          handleCommentSubmit={handleCommentSubmit}
        />
      )}

      {(comments.length === 0) && (
        <NewComment onSubmit={handleCommentSubmit} />
      )}
    </div>
  );
};

// This is called CommentList because we originally intended to have a list of comments, but we only have one comment per tasklist for now which we just update.
const CommentList = ({
  commentType,
  comments,
  handleCommentSubmit,
}: {
  commentType: CommentType;
  comments: PricingComment[];
  handleCommentSubmit: (
    content: string,
    isAssumptions: boolean,
    isLearnings: boolean
  ) => void;
}) => {
  const { refetchProjectPricing } = usePricingDataContext();
  const [editingCommentId, setEditingCommentId] = useState<number | null>(null);
  const [updatedContent, setUpdatedContent] = useState("");
  const [updatePricingComment] = useUpdatePricingCommentMutation();
  const [deletePricingComment] = useDeletePricingCommentMutation();
  const peopleData = usePeopleContext();
  const userId = peopleData.data?.me?.id;

  const handleEdit = (comment: PricingComment) => {
    setEditingCommentId(comment.id);
    setUpdatedContent(comment.comment);
  };

  const handleUpdate = async (commentId: number) => {
    if (!userId) {
      console.error("Missing required data for comment update");
      return;
    }
    try {
      await updatePricingComment({
        variables: {
          id: commentId,
          comment: updatedContent,
          updatedById: userId,
        },
        onCompleted: () => {
          setEditingCommentId(null);
          refetchProjectPricing();
        },
        onError: (error) => {
          console.error("Error updating comment:", error);
        },
      });
    } catch (error) {
      console.error("Error updating comment:", error);
    }
  };

  const handleDelete = async (commentId: number) => {
    if (window.confirm("Are you sure you want to delete this comment?")) {
      try {
        await deletePricingComment({
          variables: { id: commentId },
          onCompleted: () => {
            refetchProjectPricing();
          },
          onError: (error) => {
            console.error("Error deleting comment:", error);
          },
        });
      } catch (error) {
        console.error("Error deleting comment:", error);
      }
    }
  };

  const isThereAtLeastOneComment = comments.length > 0;

  const learningsComment = comments.find((comment) => comment.is_learnings);
  const assumptionsComment = comments.find((comment) => comment.is_assumptions);

  return (
    <article>
      {/* Only render the first comment for each type */}
      {isThereAtLeastOneComment &&
        commentType === "assumptions" &&
        assumptionsComment && (
          <Comment
            key={assumptionsComment.id}
            comment={assumptionsComment}
            updatedContent={updatedContent}
            handleEdit={handleEdit}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            editingCommentId={editingCommentId}
            setEditingCommentId={setEditingCommentId}
            setUpdatedContent={setUpdatedContent}
          />
        )}

      {isThereAtLeastOneComment &&
        commentType === "learnings" &&
        learningsComment && (
          <Comment
            key={learningsComment.id}
            comment={learningsComment}
            updatedContent={updatedContent}
            handleEdit={handleEdit}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            editingCommentId={editingCommentId}
            setEditingCommentId={setEditingCommentId}
            setUpdatedContent={setUpdatedContent}
          />
        )}

      {/* Render a new comment form if there are no comments */}
      {!isThereAtLeastOneComment && (
        <NewComment
          onSubmit={(content, isForAssumptions, isForLearnings) => {
            handleCommentSubmit(content, isForAssumptions, isForLearnings);
          }}
        />
      )}

      {!assumptionsComment && commentType === "assumptions" && (
        <NewComment
          commentType={commentType}
          onSubmit={(content, isForAssumptions, isForLearnings) => {
            handleCommentSubmit(content, isForAssumptions, isForLearnings);
          }}
        />
      )}

      {!learningsComment && commentType === "learnings" && (
        <NewComment
          commentType={commentType}
          onSubmit={(content, isForAssumptions, isForLearnings) => {
            handleCommentSubmit(content, isForAssumptions, isForLearnings);
          }}
        />
      )}

      {/* Render a list of comments */}
      {/* {comments.map((comment) => (
        <Comment
          key={comment.id}
          comment={comment}
          updatedContent={updatedContent}
          handleEdit={handleEdit}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
          editingCommentId={editingCommentId}
          setEditingCommentId={setEditingCommentId}
          setUpdatedContent={setUpdatedContent}
        />
      ))} */}
    </article>
  );
};

interface CommentProps {
  key: number;
  comment: PricingComment;
  updatedContent: string;
  handleEdit: (comment: PricingComment) => void;
  handleUpdate: (commentId: number) => void;
  handleDelete: (commentId: number) => void;
  editingCommentId: number | null;
  setEditingCommentId: (commentId: number | null) => void;
  setUpdatedContent: (content: string) => void;
}

export const editorContentStyles = css`
  div {
    max-width: 350px;
    min-width: 350px;
  }

  p {
    max-width: 350px; /* Adjust as needed to match the parent container */
    overflow-wrap: break-word; /* Allows breaking long words */
    word-break: auto-phrase; /* Experimental css */
    hyphens: auto; /* Adds hyphenation where possible */
  }

  a {
    color: #3182ce;
    text-decoration: underline;
  }

  ol {
    list-style-type: decimal;
    padding-left: 1rem;
    padding-inline-start: 1rem;
  }

  ul {
    list-style-type: disc;
    padding-left: 1rem;
  }

  /* For nested lists */
  ol ol {
    list-style-type: lower-roman; /* i, ii, iii */
    padding-left: 1.5rem;
  }

  ul ul {
    list-style-type: circle; /* Hollow circles */
    padding-left: 1.5rem;
  }

  /* Additional nesting levels if needed */
  ol ol ol {
    list-style-type: lower-alpha; /* a, b, c */
    padding-left: 2rem;
  }

  ul ul ul {
    list-style-type: square; /* Solid squares */
    padding-left: 2rem;
  }
`;

const Comment = ({
  comment,
  updatedContent,
  handleEdit,
  handleUpdate,
  handleDelete,
  editingCommentId,
  setEditingCommentId,
  setUpdatedContent,
}: CommentProps) => {
  const [showButtons, setShowButtons] = useState(false);
  const buttonTw = showButtons ? tw`` : tw`invisible`;
  const wasUpdated = comment.updated_at
    ? comment.updated_at !== comment.created_at
    : false;
  const wasUpdatedBy = comment.updated_by
    ? comment.updated_by.first_name
    : null;
  const wasCreatedBy = comment.created_by.first_name;
  const createdAt = new Date(comment.created_at).toLocaleString();
  const updatedAt = new Date(comment.updated_at).toLocaleString();

  // Replace all <p></p> with <br> to handle empty paragraphs
  const commentWithBreaks = comment.comment.split("<p></p>").join("<br>");

  return (
    <div
      css={[
        tw`border-b py-2 hover:bg-white rounded-md`,
        css`
          min-width: 350px;
        `,
      ]}
      onMouseEnter={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
    >
      {editingCommentId === comment.id ? (
        <div>
          <TiptapEditor
            id={`edit-comment-editor-${comment.id}`}
            value={updatedContent}
            setValue={setUpdatedContent}
            onSave={() => handleUpdate(comment.id)}
            edit={true}
            setEdit={() => {}}
            placeholder={"Edit your comment..."}
            disabled={false}
          />
          <div css={tw`pb-2 flex justify-end items-center w-full gap-x-1`}>
            <IconButton
              size="xs"
              aria-label="Save"
              icon={<CheckCircleIcon />}
              colorScheme="green"
              onClick={() => handleUpdate(comment.id)}
              css={buttonTw}
            />
            <IconButton
              size="xs"
              aria-label="Cancel"
              icon={<CloseIcon />}
              colorScheme="gray"
              onClick={() => setEditingCommentId(null)}
              css={buttonTw}
            />
          </div>
        </div>
      ) : (
        <div css={tw`px-2`}>
          <div css={tw`pb-2 flex justify-end items-center w-full gap-x-1`}>
            <IconButton
              size="xs"
              aria-label="Save"
              icon={<EditIcon />}
              colorScheme="blue"
              onClick={() => handleEdit(comment)}
              css={buttonTw}
            />
            <IconButton
              size="xs"
              aria-label="Delete"
              icon={<DeleteIcon />}
              colorScheme="red"
              onClick={() => handleDelete(comment.id)}
              css={buttonTw}
            />
          </div>
          <div
            css={[tw`text-sm`, editorContentStyles]}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(commentWithBreaks),
            }}
          />
          <div css={tw`text-xs text-gray-500 mt-8 text-right`}>
            — {wasUpdated ? `Updated by ${wasUpdatedBy}` : wasCreatedBy} on{" "}
            {wasUpdated ? updatedAt : createdAt}
          </div>
        </div>
      )}
    </div>
  );
};

export default TasklistComments;
