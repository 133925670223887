
import React from 'react';
import tw, { css } from "twin.macro";
import { DateTime } from "luxon";
import { Person, TimeEntry, DayOff } from "../../codegen/graphql-types";
import { sumTime, weekAverageTimePerDay, daysInDateRange } from "../dates";
import { filterBillableTime, filterNonBillableTime } from '../constants';
import { CompanyNameById, ProjectNameById } from './NamesById';
import { TimeLog } from './TimeLog';
import Day from './Day';
import { DayTotals } from './DayTotals';
import { Stack, Text, Tooltip } from "@chakra-ui/react";
import { TimeIcon } from '@chakra-ui/icons';

type WeekViewProps = {
  user: Person;
  daysOff: DayOff[];
  yearInFocus: number;
  weekInFocus: number;
  timeEntries: TimeEntry[]; // time entries for the year
  startDate: string;
  isWeekInFuture: boolean;
  refetchYearTimeEntries: () => void;
};

type DayEntries = Record<string, TimeEntry[]>;
type ProjectEntries = Record<string, DayEntries>;
type CompanyEntries = Record<string, ProjectEntries>;

const WeekView = ({ user, daysOff, weekInFocus, yearInFocus, timeEntries, startDate, isWeekInFuture, refetchYearTimeEntries }: WeekViewProps) => {
  const dateFormat = 'ccc MMM dd yyyy';
  const startOfWeek = DateTime.fromISO(startDate).startOf('week');
  const endOfWeek = startOfWeek.plus({ days: 6 }).endOf('day');
  const yesterday = DateTime.now().minus({ days: 1 }).endOf('day');
  
  // If yesterday is within the week in focus, then endRangeDateTime is yesterday
  const endRangeDateTime = yesterday <= endOfWeek && yesterday >= startOfWeek ? yesterday : endOfWeek;
  
  const daysOfWeek = Array.from({ length: 7 }, (_, i) => startOfWeek.plus({ days: i }).toFormat(dateFormat));
  
  // Filter Time Entries by the week in focus
  const timeEntriesForWeek: TimeEntry[] = timeEntries.filter((entry) => {
    const firstDayOfYear = DateTime.fromObject({ year: yearInFocus });
    const startOfWeek = firstDayOfYear.plus({ weeks: weekInFocus - 1 }).startOf('week');
    const endOfWeek = startOfWeek.plus({ days: 6 }).endOf('day');
    const entryDate = DateTime.fromISO(entry.date);
    return entryDate >= startOfWeek && entryDate <= endOfWeek;
  });
  
  const {
    numberOfWorkingDaysInRange: workingDays
  } = daysInDateRange(startOfWeek.toUTC().toJSDate(), endRangeDateTime.toUTC().toJSDate(), daysOff);
  
  const groupEntries = (entries: TimeEntry[], billable: boolean): CompanyEntries => {
    return entries
      .filter(entry => (billable ? filterBillableTime(entry) : filterNonBillableTime(entry)))
      .filter(entry => entry.company_id)
      .reduce<CompanyEntries>((acc, entry) => {
        const companyGroup = acc[entry.company_id] ?? {};
        const projectGroup = companyGroup[entry.project_id] ?? {};
        const entryDate = DateTime.fromISO(entry.date).toFormat(dateFormat);
        const dayEntries = projectGroup[entryDate] ?? [];
        
        dayEntries.push(entry);
        projectGroup[entryDate] = dayEntries;
        companyGroup[entry.project_id] = projectGroup;
        acc[entry.company_id] = companyGroup;
        return acc;
      }, {} as CompanyEntries);
  };

  // Billable Teamwork Time Entries
  const billableTimeEntries: TimeEntry[] = timeEntriesForWeek.filter(filterBillableTime);
  const nonBillableTimeEntries: TimeEntry[] = timeEntriesForWeek.filter(filterNonBillableTime);
  
  // Group time entries by company, project, and day
  const billableEntries: CompanyEntries = groupEntries(timeEntriesForWeek, true);
  const nonBillableEntries: CompanyEntries = groupEntries(timeEntriesForWeek, false);

  const renderGroupedEntries = (groupedEntries: CompanyEntries, { billable }: { billable: boolean }) => {
    return Object.entries(groupedEntries).map(([company_id, projects]) => (
      <React.Fragment 
        // @ts-ignore
        key={company_id}>
        <tr>
          <th colSpan={7} css={tw`border-b-2 text-center py-3 uppercase text-xl`}>
            {company_id && <CompanyNameById id={company_id} />}
          </th>
          <td css={tw`border-b-2`}>
            <div css={tw`text-center font-bold py-3 text-xl`}>{sumTime(Object.values(projects).flatMap(Object.values).flatMap(Object.values))}</div>
          </td>
        </tr>
        {Object.entries(projects).map(([project_id, days]) => (
          <React.Fragment 
            // @ts-ignore
            key={project_id}>
            <tr>
              <td colSpan={7} css={tw`text-center py-3 font-bold`}>
                {project_id && <ProjectNameById id={project_id} />}
              </td>
            </tr>
            <tr>
              {daysOfWeek.map(day => {
                const dayEntries = days[day] ?? [];
                const timeSum = sumTime(dayEntries);
                return (
                  <td key={day} style={{ minWidth: "200px" }}>
                    <TimeLog 
                      timeSum={timeSum} 
                      companyId={company_id} 
                      projectId={project_id} 
                      userId={user.id} 
                      date={day}
                      billable={billable}
                      refetchYearTimeEntries={refetchYearTimeEntries}
                    />
                  </td>
                );
              })}
              <td>
                <div css={tw`p-3 text-center whitespace-nowrap`}>
                  {sumTime(Object.values(days).flatMap(Object.values))}
                </div>
              </td>
            </tr>
          </React.Fragment>
        ))}
      </React.Fragment>
    ));
  };

  // Week View Table
  return (
    <section css={tw`w-full`}>
      <table css={tw`w-full`}>
        <thead>
          <tr>
            {daysOfWeek.map(day => (
              <th key={day}>
                <DayTotals day={day} timeEntriesForWeek={timeEntriesForWeek} />                
              </th>
            ))}
            <th>
              <Tooltip
                label={
                  <Stack>
                    <Text>{`So far this week (to yesterday):`}</Text>
                    <Text>{`Total hours: ${sumTime(timeEntriesForWeek)}`}</Text>
                    <Text>{`Billable hours: ${sumTime(billableTimeEntries)}`}</Text>
                    <Text>{`Non-billable hours: ${sumTime(nonBillableTimeEntries)}`}</Text>
                    <Text>&divide;</Text>
                    <Text>{`${workingDays} working days`}</Text>
                  </Stack>
                }
              >
                <div css={tw`text-sm p-4 font-bold whitespace-nowrap`}>
                  
                  Total μ = {weekAverageTimePerDay(timeEntriesForWeek, workingDays)}<br/>
                  Billable μ = {weekAverageTimePerDay(billableTimeEntries, workingDays)}<br/>
                  Non-billable μ = {weekAverageTimePerDay(nonBillableTimeEntries, workingDays)}
                </div>
              </Tooltip>
            </th>
          </tr>
          <tr>
            {daysOfWeek.map(day => (
              <th key={day} css={tw`sticky bg-white z-10 top-0`}>
                <div css={tw`text-sm`}>
                  <Day yearInFocus={yearInFocus} day={day} user={user} />
                </div>
              </th>
            ))}
            <th css={tw`sticky bg-white z-10 top-0`}>
              <div css={tw`font-bold`}>
                <TimeIcon w={8} h={8} />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colSpan={7}>
              <div css={tw`text-2xl py-3 my-2 rounded-md bg-gray-800 text-white`}>
                Billable
              </div>
            </th>
            <th>
              <div css={tw`text-2xl py-3 my-2 rounded-md bg-gray-800 text-white`}>
                {sumTime(billableTimeEntries)}
              </div>
            </th>
          </tr>
          {!isWeekInFuture && renderGroupedEntries(billableEntries, { billable: true })}
          <tr>
            <th colSpan={7}>
              <div css={tw`text-2xl py-3 my-2 rounded-md bg-gray-800 text-white`}>
                Non-billable
              </div>
            </th>
            <th>
              <div css={tw`text-2xl py-3 my-2 rounded-md bg-gray-800 text-white`}>
                {sumTime(nonBillableTimeEntries)}
              </div>
            </th>
          </tr>
          {!isWeekInFuture && renderGroupedEntries(nonBillableEntries, { billable: false })}
        </tbody>
      </table>
    </section>
  );
};

type TimeLogsByWeekProps = {
  user: Person;
  daysOff: DayOff[];
  yearInFocus: number;
  userTimeEntriesForYear: TimeEntry[]; 
  weekInFocus: number;
  refetchYearTimeEntries: () => void;
}

export const TimeLogsByWeek = ({
  user,
  daysOff,
  yearInFocus,
  userTimeEntriesForYear,
  weekInFocus,
  refetchYearTimeEntries,
}: TimeLogsByWeekProps) => {
  const thisYear = DateTime.now().year;
  const thisWeek = DateTime.now().weekNumber;
  // start date of the week in focus
  const startDate = DateTime.fromObject({ year: yearInFocus }).plus({ weeks: weekInFocus - 1 }).startOf('week').toISODate();

  // do not render the time entries if the weekInFocus is greater than the current week
  const isWeekInFuture = yearInFocus >= thisYear && weekInFocus > thisWeek;

  // console.log("TimeLogsByWeek user: ", user);

  return (
    <section css={tw`flex flex-col p-3 w-full`}>
      <div css={tw`flex flex-grow flex-col items-start w-full`}>
        <WeekView
          user={user}
          daysOff={daysOff}
          yearInFocus={yearInFocus}
          weekInFocus={weekInFocus}
          timeEntries={userTimeEntriesForYear}
          startDate={startDate}
          isWeekInFuture={isWeekInFuture}
          refetchYearTimeEntries={refetchYearTimeEntries}
        />
      </div>
    </section>
  )
}