
import _ from "lodash";
import React, { useMemo, useState } from "react";
import Select from "react-select";
import tw, { css } from "twin.macro";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryPie,
  VictoryScatter,
  VictoryTooltip,
} from "victory";
import {
  GrossProfitSummaryQuery,
  Person,
} from "../../codegen/graphql-types";
import { FullSpinner } from "../../FullSpinner";
import { usePeopleContext } from "../../helpers/usePeopleContext";
import { useAuthContext } from "../../useAuth";
import { ChartHeading } from "./Charts/chartHelpers";
import {
  chartContainerWidth,
  currencyFormatter,
  palette,
} from "./Charts/constants";
import { MonthOverMonthChart } from "./Charts/MonthOverMonthChart";
import { YearComparisonChart } from "./Charts/YearComparisonChart";
import { NetSalesReportTable } from "./NetSalesReportTable";
import { useNetSalesReportData } from "./useNetSalesReportData";
import { AdminProfitReportContents } from "./AdminProfitReportContents";
import { NetSalesPerEmployee } from "./NetSalesPerEmployee";

export const GrossProfitReportContents = ({
  data,
  startYear,
  endYear,
}: {
  data: GrossProfitSummaryQuery;
  startYear: number;
  endYear: number;
}) => {
  const { isAdmin } = useAuthContext();
  const { data: peopleData, loading: loadingPeopleData } = usePeopleContext();

  const idToPersonMap = useMemo(() => {
    if (!peopleData?.people) return {};

    return Object.fromEntries(
      peopleData.people.map((person) => [person.id, person])
    );
  }, [peopleData]);

  const loggedInPerson = peopleData?.me;

  const {
    columnDefinitions,
    ownerIds,
    postProcessedRows,
  } = useNetSalesReportData({
    startYear,
    endYear,
    entries: data.grossProfitReport?.entries ?? [],
    idToPersonMap,
  });

  const podMemberIds = peopleData?.me?.pods.flatMap((pod) =>
    pod.members.map((member) => member.id)
  );

  const pods = peopleData?.pods ?? [];

  const {
    columnDefinitions: columnDefinitionsForPod,
    postProcessedRows: postProcessedRowsForPod,
  } = useNetSalesReportData({
    startYear,
    endYear,
    entries:
      data.grossProfitReport?.entries.filter((x) =>
        podMemberIds?.includes(x.owner_id)
      ) ?? [],
    idToPersonMap,
  });

  const [ownerInFocus, setOwnerInFocus] = useState<Person | null>(null);
  const [podIdInFocus, setPodIdInFocus] = useState<string | null>(null);

  const podInFocus = pods.find((pod) => pod.id === podIdInFocus);

  if (loadingPeopleData) {
    return <FullSpinner />;
  }

  return (
    <>
      <div
        css={[
          tw`flex flex-wrap flex-col w-max`,
          css`
            min-width: 1200px;
          `,
        ]}
      >
        {isAdmin && (
          <AdminProfitReportContents 
            postProcessedRowsForPod={postProcessedRowsForPod}
            columnDefinitionsForPod={columnDefinitionsForPod}
            startYear={startYear}
            endYear={endYear}
            chartContainerWidth={chartContainerWidth}
          />
        )}

        <div css={tw`flex flex-col items-start`}>
          <h2
            css={[
              tw`w-full font-bold text-xl text-center my-4 py-2`,
              css`
                background: linear-gradient(
                  45deg,
                  #dbeafe00,
                  #dbeafe9e,
                  #dbeafe00
                );
              `,
            ]}
          >
            {isAdmin ? <>POs opened by Company</> : <>POs opened by Pod</>}

            <div css={tw`text-base font-normal`}>
              <p>(cumulative)</p>
              <p>(Value of POs opened minus project flow-through costs)</p>
            </div>
          </h2>
          {isAdmin && (
            <>
              <div
                data-comment="per employee charts"
                css={[tw`flex flex-row flex-wrap gap-x-12 w-full mb-12`]}
              >
                <NetSalesPerEmployee
                  postProcessedRows={postProcessedRows}
                  endYear={endYear}
                  idToPersonMap={idToPersonMap}
                  ownerIds={ownerIds}
                />

                <div
                  css={[tw`flex-shrink-0`]}
                  // style={{ width: chartContainerWidth }}
                >
                  <div style={{ width: chartContainerWidth }}>
                    <YearComparisonChart
                      year1Data={postProcessedRows.map((row) => ({
                        year: startYear,
                        monthName: row.monthName,
                        x: row.monthName,
                        y:
                          row.startYearCumulativeGrossProfitByOwnerDict?.[
                            ownerInFocus?.id ?? (loggedInPerson?.id as string)
                          ] ?? 0,
                      }))}
                      year2Data={postProcessedRows.map((row) => ({
                        year: endYear,
                        monthName: row.monthName,
                        x: row.monthName,
                        y:
                          row.endYearCumulativeGrossProfitByOwnerDict?.[
                            ownerInFocus?.id ?? (loggedInPerson?.id as string)
                          ] ?? 0,
                      }))}
                    />
                  </div>
                  <ChartHeading>
                    <h2 css={tw`text-center`}>
                      Net Sales: Current Year vs Previous Year (employee)
                    </h2>
                    {peopleData?.people && loggedInPerson && (
                      <div css={tw`mx-auto w-52`}>
                        <Select
                          placeholder="Select employee..."
                          // @ts-ignore
                          options={_.orderBy(
                            peopleData.people ?? [],
                            (x) => x.first_name
                          ).flatMap((person) => {
                            if (!/maven/i.test(person.title)) return [];
                            return {
                              value: person,
                              label: `${person.first_name} ${person.last_name}`,
                            };
                          })}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          onChange={(selectedOption) => {
                            if (!selectedOption?.value) return;
                            // @ts-expect-error
                            setOwnerInFocus(selectedOption.value);
                          }}
                          defaultValue={{
                            value: loggedInPerson,
                            label: `${loggedInPerson.first_name} ${loggedInPerson.last_name}`,
                          }}
                          value={
                            ownerInFocus && {
                              value: ownerInFocus,
                              label: `${ownerInFocus.first_name} ${ownerInFocus.last_name}`,
                            }
                          }
                        />
                      </div>
                    )}
                  </ChartHeading>
                </div>

                <div css={[tw`flex-shrink-0 w-1/3`]}>
                  <div style={{ width: chartContainerWidth }}>
                    <VictoryPie
                      height={500}
                      width={600}
                      domainPadding={{ y: 10 }}
                      padding={80}
                      colorScale={palette}
                      data={ownerIds.flatMap((ownerId) => {
                        const owner = idToPersonMap[ownerId];
                        if (!owner) return {};
                        return {
                          x: `${owner?.first_name} ${owner?.last_name}`,
                          y:
                            _.last(postProcessedRows)
                              ?.endYearCumulativeGrossProfitByOwnerDict?.[
                              ownerId
                            ] ?? 0,
                          year: endYear,
                        };
                      })}
                      labels={({ datum }) => `${datum.x}`}
                      labelComponent={<CustomLabel />}
                      innerRadius={100}
                      // style={{ labels: { fill: "white" } }}
                    />
                  </div>
                  <ChartHeading>
                    <h2 css={tw`text-center`}>
                      Net Sales Contribution (employee)
                    </h2>
                  </ChartHeading>
                </div>
              </div>

              <div
                data-comment="per pod charts"
                css={[tw`flex flex-row flex-wrap w-full mb-12`]}
              >
                <div
                  css={[tw`flex-shrink-0 w-1/3`]}
                  // style={{ width: chartContainerWidth }}
                >
                  <div style={{ width: chartContainerWidth }}>
                    <VictoryChart
                      height={500}
                      width={600}
                      domainPadding={{ y: 20 }}
                      padding={80}
                    >
                      {pods.map((pod, i) => {
                        const indexColor = palette[i % palette.length];

                        return [
                          <VictoryLine
                            data={postProcessedRows.map((row) => ({
                              year: endYear,
                              x: row.monthName,
                              y: pod.members
                                .map(
                                  (member) =>
                                    row
                                      .endYearCumulativeGrossProfitByOwnerDict?.[
                                      member.id
                                    ] ?? 0
                                )
                                .reduce((a, b) => a + b, 0),
                            }))}
                            style={{
                              data: {
                                stroke: indexColor,
                                strokeWidth: 2,
                                pointerEvents: "none",
                              },
                              labels: { fill: indexColor },
                            }}
                          />,
                          <VictoryScatter
                            data={postProcessedRows.map((row) => ({
                              year: endYear,
                              monthName: row.monthName,
                              x: row.monthName,
                              y: pod.members
                                .map(
                                  (member) =>
                                    row
                                      .endYearCumulativeGrossProfitByOwnerDict?.[
                                      member.id
                                    ] ?? 0
                                )
                                .reduce((a, b) => a + b, 0),
                            }))}
                            size={5}
                            style={{ data: { fill: indexColor } }}
                            labels={({ datum }) =>
                              `${pod.name} ${datum.monthName} ${
                                datum.year
                              }: $${currencyFormatter.format(datum.y)}`
                            }
                            labelComponent={
                              <VictoryTooltip
                                cornerRadius={0}
                                flyoutStyle={{ fill: "white" }}
                              />
                            }
                          />,
                        ];
                      })}
                      <VictoryAxis
                        style={{
                          tickLabels: {
                            fontSize: 15,
                            paddingTop: 20,
                            angle: 30,
                            textAnchor: "left",
                            verticalAnchor: "start",
                          },
                          ticks: {
                            stroke: "#000000",
                            size: 8,
                          },
                        }}
                      />
                      <VictoryAxis dependentAxis />
                    </VictoryChart>
                  </div>
                  <ChartHeading>
                    <h2 css={tw`text-center`}>
                      Net Sales: Pod Contributions
                    </h2>
                  </ChartHeading>
                </div>

                <div
                  css={[tw`flex-shrink-0 w-1/3`]}
                  // style={{ width: chartContainerWidth }}
                >
                  <div style={{ width: chartContainerWidth }}>
                    <YearComparisonChart
                      year1Data={postProcessedRows.map((row) => ({
                        year: startYear,
                        monthName: row.monthName,
                        x: row.monthName,
                        y:
                          podInFocus?.members
                            .map(
                              (member) =>
                                row.startYearCumulativeGrossProfitByOwnerDict?.[
                                  member.id
                                ] ?? 0
                            )
                            .reduce((a, b) => a + b, 0) ?? 0,
                      }))}
                      year2Data={postProcessedRows.map((row) => ({
                        year: endYear,
                        monthName: row.monthName,
                        x: row.monthName,
                        y:
                          podInFocus?.members
                            .map(
                              (member) =>
                                row.endYearCumulativeGrossProfitByOwnerDict?.[
                                  member.id
                                ] ?? 0
                            )
                            .reduce((a, b) => a + b, 0) ?? 0,
                      }))}
                    />
                  </div>
                  <ChartHeading>
                    <h2 css={tw`text-center`}>
                      Net Sales: Current Year vs Previous Year (Pod)
                    </h2>
                    {peopleData?.people && loggedInPerson && (
                      <div css={tw`mx-auto w-52`}>
                        <Select
                          placeholder="Select Pod..."
                          options={pods.flatMap((pod) => {
                            return {
                              value: pod.id,
                              label: `${pod.name}`,
                            };
                          })}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          onChange={(selectedOption) => {
                            if (!selectedOption?.value) return;
                            setPodIdInFocus(selectedOption.value);
                          }}
                          defaultValue={{
                            value: pods[0].id,
                            label: `${pods[0].name}`,
                          }}
                          value={
                            podIdInFocus
                              ? {
                                  value: podIdInFocus,
                                  label: `${podInFocus?.name}`,
                                }
                              : null
                          }
                        />
                      </div>
                    )}
                  </ChartHeading>
                </div>

                <div css={[tw`flex-shrink-0 w-1/3`]}>
                  <div style={{ width: chartContainerWidth }}>
                    <VictoryPie
                      height={500}
                      width={600}
                      domainPadding={{ y: 10 }}
                      padding={80}
                      colorScale={palette}
                      data={_.orderBy(
                        pods.map((pod) => {
                          return {
                            x: `${pod.name}`,
                            y:
                              pod.members
                                .map(
                                  (member) =>
                                    _.last(postProcessedRows)
                                      ?.endYearCumulativeGrossProfitByOwnerDict?.[
                                      member.id
                                    ] ?? 0
                                )
                                .reduce((a, b) => a + b, 0) ?? 0,
                            year: endYear,
                          };
                        }),
                        ["y", "asc"],
                      )}
                      startAngle={0}
                      endAngle={-360}
                      labels={({ datum }) => `${datum.x}`}
                      labelComponent={<CustomLabel />}
                      innerRadius={100}
                      // style={{ labels: { fill: "white" } }}
                    />
                  </div>
                  <ChartHeading>
                    <h2 css={tw`text-center`}>
                      Net Sales Contribution (Pod)
                    </h2>
                  </ChartHeading>
                </div>
              </div>
            </>
          )}
          <div css={tw`mb-8 mt-3 flex flex-row`}>
            {isAdmin ? (
              <div css={[tw`flex-shrink-0`]}>
                <div style={{ width: chartContainerWidth }}>
                  <YearComparisonChart
                    year1Data={postProcessedRows.map((row) => ({
                      year: startYear,
                      monthName: row.monthName,
                      x: row.monthName,
                      y: row.startYearCumulativeGrossProfit,
                    }))}
                    year2Data={postProcessedRows.map((row) => ({
                      year: endYear,
                      monthName: row.monthName,
                      x: row.monthName,
                      y: row.endYearCumulativeGrossProfit,
                    }))}
                  />
                </div>
                <ChartHeading>
                  <h2 css={tw`text-center`}>
                    Net Sales: Current Year vs Previous Year
                    <br />
                    (company)
                  </h2>
                </ChartHeading>
              </div>
            ) : (
              <div
                css={[tw`flex-shrink-0`]}
                style={{ width: chartContainerWidth }}
              >
                <div style={{ width: chartContainerWidth }}>
                  <YearComparisonChart
                    year1Data={postProcessedRows.map((row) => ({
                      year: startYear,
                      monthName: row.monthName,
                      x: row.monthName,
                      y: Object.values(row.startYearCumulativeGrossProfitByOwnerDict ?? {}).reduce((acc, val) => acc + val, 0)
                    }))}
                    year2Data={postProcessedRows.map((row) => ({
                      year: endYear,
                      monthName: row.monthName,
                      x: row.monthName,
                      y: ownerIds
                        .map(
                          (ownerId) =>
                            row.endYearCumulativeGrossProfitByOwnerDict?.[
                              ownerId
                            ]
                        )
                        .reduce((acc, val) => acc + val, 0),
                    }))}
                  />
                </div>
                <ChartHeading>
                  <h2 css={tw`text-center`}>
                    Net Sales: Current Year vs Previous Year
                  </h2>
                </ChartHeading>
              </div>
            )}
            <NetSalesReportTable
              columnDefinitions={columnDefinitions}
              postProcessedRows={postProcessedRows}
              isCumulative={true}
            />
          </div>
        </div>
        <div css={tw`flex flex-col items-start`}>
          <h2
            css={[
              tw`w-full font-bold text-xl text-center my-4 py-2`,
              css`
                background: linear-gradient(
                  45deg,
                  #dbeafe00,
                  #dbeafe9e,
                  #dbeafe00
                );
              `,
            ]}
          >
            {isAdmin ? <>POs opened by Company</> : <>POs opened by Pod</>}
            <div css={tw`text-base font-normal`}>
              <p>(monthly)</p>
              <p>(Value of POs opened minus project flow-through costs)</p>
            </div>
          </h2>
          <div css={tw`my-3 flex flex-row`}>
            <div css={[tw`flex-shrink-0`]}>
              <div style={{ width: chartContainerWidth }}>
                <MonthOverMonthChart
                  year1Data={postProcessedRows.map((row) => ({
                    monthName: row.monthName,
                    year: startYear,
                    x: row.monthName,
                    y: row.startYearGrossProfit,
                  }))}
                  year2Data={postProcessedRows.map((row) => ({
                    year: endYear,
                    monthName: row.monthName,
                    x: row.monthName,
                    y: row.endYearGrossProfit,
                  }))}
                />
              </div>
              <ChartHeading>
                <h2 css={tw`text-center`}>
                  Net Sales: Month over Month
                  {isAdmin && (
                    <>
                      <br />
                      (company)
                    </>
                  )}
                </h2>
              </ChartHeading>
            </div>
            <div>
              <NetSalesReportTable
                columnDefinitions={columnDefinitions}
                postProcessedRows={postProcessedRows}
                isCumulative={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

class CustomLabel extends React.Component {
  static defaultEvents = VictoryTooltip.defaultEvents;
  render() {
    return (
      <g>
        {/* @ts-ignore */}
        <VictoryLabel {...this.props} style={{ fill: "black" }} />
        <VictoryTooltip
          // {...this.props}
          x={300}
          y={300}
          text={`${_.round(
            Math.abs(
              // @ts-ignore
              (this.props.datum.startAngle - this.props.datum.endAngle) / 360
            ) * 100,
            1
          )}%`}
          orientation="top"
          pointerLength={0}
          cornerRadius={50}
          width={100}
          height={100}
          flyoutStyle={{ fill: "white", stroke: "white" }}
        />
      </g>
    );
  }
}
