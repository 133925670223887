import { useEffect, useState, useMemo, useContext, ReactNode } from "react";
import tw, { css } from "twin.macro";
import { PricingContext, TimeEntryWithRate } from "../../helpers/usePricing";
import { ProjectedTime } from "../../codegen/graphql-types";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  CellContext,
} from "@tanstack/react-table";
import { 
  Stack, 
  Text, 
  Tooltip,
  Popover,
  PopoverArrow,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverBody,
} from "@chakra-ui/react";

const updateColour = "#FFFAC8";
const totalCss = tw`font-bold`;

// const columnHelper = createColumnHelper<ProjectedTimeType>();
const TableCell = ({ getValue, row, column, table }: any) => {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  const onBlur = () => {
    table.options.meta?.updateData(row.index, column.id, value);
  };
  return (
    <input
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onBlur={onBlur}
      css={[
        tw`text-center w-full h-full`,
        css`
          background-color: ${updateColour};
        `,
      ]}
      style={{ width: `${value.toString().length + 2}ch` }}
      type="number"
    />
  );
};

export const TotalCostCell = ({ getValue, row }: any) => {
  const { formatNumber } = useContext(PricingContext);
  // render the cell with red text if the value is negative and use a "-" sign
  const value = getValue();
  const isNegative = value < 0;
  const isLastRow = row.index === 2; // Adjust this condition as needed
  return isNegative && isLastRow ? (
    <span css={totalCss} style={{ color: "red" }}>
      -{`$${formatNumber(Math.abs(value / 100))}`}
    </span>
  ) : (
    <span css={totalCss}>{`$${formatNumber(value / 100)}`}</span>
  );
};

const formatHours = (hours: number) => {
  // if zero, return 0
  if (hours === 0 || !hours) {
    return 0;
  } else {
    // otherwise, round to 1 decimal place
    return hours.toFixed(1);
  }
}

const TasklistDetailsTeamTable = ({
  groupedData,
  rowData,
  transformedTimeEntries,
  selectedTasklist,
}: {
  groupedData: { [key: string]: ProjectedTime[] };
  rowData: { [key: string]: number }[];
  transformedTimeEntries: TimeEntryWithRate[] | null;
  selectedTasklist: { id: number; name: string } | null;
}) => {
  const { 
    updateTeamRoleProjectedTime, 
    formatNumber, 
    uniqueRoles, 
    uniqueTeams,
    projectRatesData,
    meanProjectRates,
  } = useContext(
    PricingContext
  );

  const peopleInProjectRates = useMemo(() => {
    if (!projectRatesData || !meanProjectRates || !transformedTimeEntries) {
      return [];
    }
    return projectRatesData.projectRatesByProjectId.map((projectRate) => {
      const person = projectRate.person;
      const meanProjectRate = meanProjectRates.find(
        rate =>
          rate.team.teamName === person.team?.teamName &&
          rate.role.roleTitle === person.role?.roleTitle
      );
      return {
        id: person.id,
        firstName: person.first_name,
        lastName: person.last_name,
        title: person.title,
        global_rate: person.rate,
        mean_project_rate: meanProjectRate?.meanRateInCents ?? 0,
        company: person.company, 
        team: person.team?.teamName,
        role: person.role?.roleTitle,
        rate_in_cents: projectRate.rate_in_cents,
        time_entries: transformedTimeEntries.filter(
          (entry) => entry.user_id === person.id
        ),
      };
    });
  }, [projectRatesData]);

  const columns = useMemo(
    () => [
      {
        header: "",
        id: "column-header",
        accessorFn: (_: any, index: number) => {
          if (index === 0) {
            return "Projected Budget";
          } else if (index === 1) {
            return "Actuals";
          } else {
            return "";
          }
        },
        meta: {
          tdProps: {
            style: {
              backgroundColor: "#f7f7f7",
            },
          },
        },
        cell: (props: CellContext<{ [key: string]: number }, string>) => {
          return <strong>{props.getValue()}</strong>;
        }
      },
      ...Object.entries(groupedData)
        .map(([teamName, projectedTimes]: [string, ProjectedTime[]]) => {
          return {
            header: () => {
              return (
                <Tooltip label={teamName} aria-label={teamName}>
                  {teamName}
                </Tooltip>
              )
            },
            id: teamName,
            accessorFn: `${teamName}-team`,
            columns: [
            // Columns for each role
            ...projectedTimes.map((pt: ProjectedTime) => {
                const roleTitle = uniqueRoles.find(
                  (role) => role.id === pt.role_id
                )?.roleTitle;
                return {
                  header: () => {
                    const jobTitles = peopleInProjectRates
                      .filter(person => person.team === teamName && person.role === roleTitle)
                      .map(person => person.title)
                      .filter((value, index, self) => self.indexOf(value) === index)
                    return (
                      <Tooltip label={
                        <Stack spacing={1}>
                          <Text fontWeight="bold">{roleTitle} - {teamName}</Text>
                          {jobTitles.map((title, index) => (
                            <Text size="sm" key={index}>{title}</Text>
                          ))}
                        </Stack>
                      } aria-label={teamName}>
                        {roleTitle}
                      </Tooltip>
                    )
                  },
                  accessorFn: `${teamName}_${roleTitle}_name`,
                  id: `${teamName}_${roleTitle}_cost`,
                  columns: [
                    {
                      header: "Hrs",
                      id: pt.id, // use the id of the projected time from the database to make updates easier
                      accessorFn: (row: { [key: string]: number }) => {
                        return row[`${teamName}-${roleTitle}-time`];
                      },
                      meta: {
                        tdProps: {
                          style: {
                            backgroundColor: updateColour,
                          },
                        },
                      },
                      cell: (
                        props: CellContext<{ [key: string]: number }, number>
                      ) => {
                        // Sometimes value can be NaN if data is not available
                        const value = props.getValue();
                        return props.row.index === 0 ? (
                          <TableCell {...props} />
                        ) : (
                          <div>
                            {typeof value === 'number' ? formatHours(value) : value}
                          </div>
                        );
                      },
                    },
                    {
                      header: () => {
                        if (!meanProjectRates) {
                          return "Cost";
                        }
                        const meanRate = meanProjectRates
                          .find(rate => rate.team.teamName === teamName && rate.role.roleTitle === roleTitle)
                          ?.meanRateInCents ?? 0;

                        const usersWithTime = peopleInProjectRates
                          .filter(person => (person.team === teamName) && (person.role === roleTitle) && (person.time_entries.length > 0));

                        if (usersWithTime.length === 0) {
                          return "Cost";
                        }

                        const peopleWithCosts = usersWithTime
                          .map((person) => {
                            const timeEntries = person.time_entries;
                            const timeEntriesForTasklist = timeEntries
                              .filter((entry) => entry.tasklist_id === selectedTasklist?.id);
                            const totalMinutes = timeEntriesForTasklist
                              .reduce((acc, entry) => acc + entry.minutes, 0);
                            const totalHours = totalMinutes / 60;
                            const totalCostInCents = totalHours * person.rate_in_cents;
                            return {
                              ...person,
                              cost: {
                                rate_in_cents: person.rate_in_cents,
                                hours: totalHours,
                                actual_cost: totalCostInCents,
                              },
                            };
                          });

                        return (  
                          <Popover placement="right">
                            <PopoverTrigger>
                              <div css={tw`cursor-pointer`}>
                                Cost
                              </div>
                            </PopoverTrigger>
                            <PopoverContent sx={{ backgroundColor: "#233B4D", color: "white", padding: "1em 0.5em" }} css={tw`w-full`}>
                              <PopoverArrow />
                              <PopoverCloseButton />
                              <PopoverBody>
                                <Stack>
                                  <Text size="sm" textAlign="left" fontWeight="normal">
                                    Projected budget:<br/> Estimated Hrs x Mean Project Rate for the Team and Role = <b>${formatNumber(meanRate / 100)}</b>
                                  </Text>
                                  <Text size="sm" textAlign="left" fontWeight="normal">
                                    Actual cost:<br/> Time logs x Actual User Project Rate for each person who logged time.
                                  </Text>
                                  <br/>
                                  {peopleWithCosts.map((person, index) => (
                                    <Stack css={tw`flex w-full text-left`} key={index} spacing={1}>
                                      <Text>
                                        {person.firstName} {person.lastName} ({person.title})
                                      </Text>
                                      <Text>
                                        {formatHours(person.cost.hours)} hrs x ${formatNumber(person.cost.rate_in_cents / 100)} = ${formatNumber(person.cost.actual_cost / 100)}
                                      </Text>
                                    </Stack>
                                  ))}
                                </Stack>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        )
                      },
                      id: `${teamName}-${roleTitle}-cost`,
                      accessorFn: (row: { [key: string]: number }) => {
                        if (row[`${teamName}-${roleTitle}-cost`]) {
                          return `$${formatNumber(
                            row[`${teamName}-${roleTitle}-cost`] / 100
                          )}`;
                        } else {
                          return "";
                        }
                      },
                    },
                  ],
                };
              }
            ),
              // Column for the total (Team total)
              {
                header: "Total",
                accessorFn: `${teamName}-total-name`,
                id: `${teamName}-total-name`,
                columns: [
                  {
                    header: "Hrs",
                    id: `${teamName}-total-time`,
                    accessorFn: (row: any) => row[`${teamName}-total-time`],
                    cell: (props: CellContext<{ [key: string]: number }, number>) => {
                      const value: number = props.getValue();
                      return <span css={totalCss}>{formatHours(value)}</span>;
                    },
                  },
                  {
                    header: "Cost",
                    id: `${teamName}-total-cost`,
                    accessorFn: (row: any) => row[`${teamName}-total-cost`],
                    cell: (props: CellContext<{ [key: string]: number }, ReactNode>) => (
                      <TotalCostCell {...props} />
                    ),
                  },
                ],
              },
            ].sort((a, b) => {
              // Sort the columns by Role order

              // ensure header is defined
              if (!a.header || !b.header) {
                return 0;
              }
              // make sure the total is always last
              if (a.header === "Total") {
                return 1;
              }

              const headerA = a.id.split("_")[1];
              const headerB = b.id.split("_")[1];

              const roleA = uniqueRoles.find(
                (role) => role.roleTitle === headerA
              );
              const roleB = uniqueRoles.find(
                (role) => role.roleTitle === headerB
              );
              
              if (roleA && roleB) {
                return (roleA?.order ?? 0) - (roleB?.order ?? 0);
              }
              
              return 0;
            })
            .filter((column) => {
              // This filter callback ensures we don't render blank columns

              // Always render the total column
              if (column.header === "Total") {
                return true;
              }

              // Filter out columns for team + roles that are not represented by any users in project rates data
              // E.g. "Design/Dev/Copy Team" will not have any users with the "Offshore" role
              const columnTeam = column.id.split("_")[0];
              const columnRole = column.id.split("_")[1];
              return peopleInProjectRates.some(
                (person) => person.team === columnTeam && person.role === columnRole
              );
            }),
          };
        })
        .sort((a, b) => {
          // sort the teams by order
          const teamA = uniqueTeams.find((team) => team.teamName === a.id);
          const teamB = uniqueTeams.find((team) => team.teamName === b.id);

          if (!teamA || !teamB) {
            return 0;
          }

          return (teamA?.order ?? 0) - (teamB?.order ?? 0);
        }),
      {
        header: "Tasklist Totals",
        id: "total",
        accessorFn: (row: any) => row[`total-cost`],
        columns: [
          {
            header: "Hrs",
            id: "total-time",
            accessorFn: (row: any) => row[`total-time`],
            cell: (props: CellContext<{ [key: string]: number }, number>) => {
              const value = props.getValue();
              return <span css={totalCss}>{formatHours(value)}</span>;
            },
          },
          {
            header: "Cost",
            id: "total-cost",
            accessorFn: (row: any) => row[`total-cost`], // Only access the raw data with accessorFn
            cell: (props: CellContext<{ [key: string]: number }, unknown>) => (
              <TotalCostCell {...props} />
            ),
          },
        ],
      },
    ],
    [groupedData]
  );

  const table = useReactTable({
    data: rowData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: (
        rowIndex: number,
        projectedTimeId: string,
        value: string
      ) => {
        updateTeamRoleProjectedTime(
          rowIndex,
          Number(projectedTimeId),
          Number(value)
        );
      },
      updateCogs: (rowIndex: number, columnId: string, value: string) => {
        console.log("updateCogs", rowIndex, columnId, value);
      },
    },
    debugTable: true,
  });

  return (
    <div>
      <table css={tw`border-collapse border border-gray-200 text-sm`}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    css={tw`border border-gray-200 px-2 py-1`}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, rowIndex, rowArray) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => {
                const columnHeader = cell.column.columnDef.header;
                // @ts-ignore
                const tdProps = cell.column.columnDef.meta?.tdProps || {}; // Access tdProps from meta, default to empty object if not defined

                // if this is the first column, render the row header
                if (cell.column.id === "column-header") {
                  return (
                    <td
                      key={cell.id}
                      css={tw`border border-gray-200 px-2 py-1`}
                      {...tdProps}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                }

                // If this is the first row, use tdProps in the returned td
                if (rowIndex === 0 && columnHeader === "Hrs") {
                  return (
                    <td
                      key={cell.id}
                      css={tw`border border-gray-200 text-center`}
                      {...tdProps}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                }

                // If this is the last row render the cell empty if it's the Hrs column
                if (
                  rowIndex === rowArray.length - 1 &&
                  columnHeader === "Hrs"
                ) {
                  return <td key={cell.id}>&nbsp;</td>;
                }

                return (
                  <td
                    key={cell.id}
                    css={tw`border border-gray-200 text-center`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TasklistDetailsTeamTable;
