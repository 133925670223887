
import tw, { css } from "twin.macro";
// import { Stack } from "@chakra-ui/react";
import _ from "lodash";
import { DateTime, Interval } from "luxon";
import { Fragment, useMemo, useState } from "react";
import {
  VictoryChart,
  VictoryVoronoiContainer,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryAxis,
  VictoryLabel,
  VictoryArea,
} from "victory";
import {
  usePodRevenueForecastQuery,
  PodRevenueForecastQuery,
} from "../../../codegen/graphql-types";
import { ChartHeading } from "../Charts/chartHelpers";
// constants
import {
  chartContainerWidth,
  currencyFormatter,
  forecastColor, // yellow
  aboveForecastColor, // green
  belowForecastColor // red
} from "../Charts/constants";

const soldColor = "#095AB2";
// const earnedColor = "#FB0005";
// const podBonusColor = "#5C1A8E";

export const PodRevenueForecasts = ({
  yearInFocus,
}: {
  yearInFocus: number;
}) => {
  // const [year, setYear] = useState(new Date().getFullYear());
  const { data, loading } = usePodRevenueForecastQuery({
    variables: {
      year: yearInFocus,
    },
  });
  return (
    <>
      {loading && <div>Loading pod revenue forecast data...</div>}

      {data?.me?.pods.map((pod) => (
        <Fragment
          // @ts-ignore
          key={pod.id}
        >
          <SinglePodRevenueForecast pod={pod} />
        </Fragment>
      ))}
    </>
  );
};

const SinglePodRevenueForecast = ({
  pod,
}: {
  pod: NonNullable<PodRevenueForecastQuery["me"]>["pods"][number];
}) => {
  const monthlyDataNetSales =
    _.sumBy(pod.projects, (x) => {
      const netSales = (x?.budget ?? 0) * 100 - (x?.cogs_in_cents ?? 0);
      if (!x.sales_split_pod) {
        return netSales;
      } else if (x.senior_brand_maven_lead === pod.senior_brand_maven_lead) {
        // user belongs to pod that owns this project
        return Math.round((netSales * (100 - x.sales_split_ratio)) / 100);
      } else if (x?.sales_split_pod.id === pod.id) {
        // user belongs to pod that is splitting this project
        return Math.round((netSales * x.sales_split_ratio) / 100);
      } else return 0;
    }) / 100;

  type PodProjects = {
    projects: NonNullable<
      NonNullable<PodRevenueForecastQuery["me"]>["pods"][number]
    >["projects"];
    budget: number;
    cogs: number;
  };

  const {
    completedProjects,
    cancelledProjects,
    inProgressProjects,
    onHoldProjects,
  } = useMemo(() => {
    return pod.projects.reduce<{
      completedProjects: PodProjects;
      cancelledProjects: PodProjects;
      inProgressProjects: PodProjects;
      onHoldProjects: PodProjects;
    }>(
      (acc, project) => {
        const participationFactor = !project.sales_split_pod
          ? 1
          : project.senior_brand_maven_lead === pod.senior_brand_maven_lead
          ? // user belongs to pod that owns this project
            (100 - project.sales_split_ratio) / 100
          : project?.sales_split_pod.id === pod.id
          ? // user belongs to pod that is splitting this project
            project.sales_split_ratio / 100
          : 0;
        const mapProjectStatusToAccField = {
          Completed: "completedProjects",
          Cancelled: "cancelledProjects",
          "In Progress": "inProgressProjects",
          "On Hold": "onHoldProjects",
        } as const;

        const projectStatus = project.project_status as keyof typeof mapProjectStatusToAccField;

        acc[mapProjectStatusToAccField[projectStatus]].projects.push(project);
        acc[mapProjectStatusToAccField[projectStatus]].budget +=
          (project.budget ?? 0) * participationFactor;
        acc[mapProjectStatusToAccField[projectStatus]].cogs +=
          (project.cogs_in_cents ?? 0) * participationFactor;
        return acc;
      },
      {
        completedProjects: {
          projects: [],
          budget: 0,
          cogs: 0,
        },
        cancelledProjects: {
          projects: [],
          budget: 0,
          cogs: 0,
        },
        inProgressProjects: {
          projects: [],
          budget: 0,
          cogs: 0,
        },
        onHoldProjects: {
          projects: [],
          budget: 0,
          cogs: 0,
        },
      }
    );
  }, [pod]);

  const monthlyDataNetEarnedSales = _.sortBy(
    Object.values(
      _.groupBy(
        completedProjects.projects.concat(cancelledProjects.projects),
        (project) => {
          return DateTime.fromISO(project?.end_date, { zone: 'America/Toronto' }).toFormat("YYYY-MM");
        }
      )
    ).flatMap((group) => {
      if (group == null) return [];
      return {
        startOfMonth: DateTime.fromISO(group[0].end_date, { zone: 'America/Toronto' }).startOf("month"),
        year: DateTime.fromISO(group[0].end_date, { zone: 'America/Toronto' }).toFormat("YYYY"),
        month: DateTime.fromISO(group[0].end_date, { zone: 'America/Toronto' }).toFormat("MM"),
        netSales:
          _.sumBy(group, (project) => {
            const participationFactor = !project.sales_split_pod
              ? 1
              : project.senior_brand_maven_lead === pod.senior_brand_maven_lead
              ? // user belongs to pod that owns this project
                (100 - project.sales_split_ratio) / 100
              : project?.sales_split_pod.id === pod.id
              ? // user belongs to pod that is splitting this project
                project.sales_split_ratio / 100
              : 0;
            return (
              participationFactor *
              ((project?.budget ?? 0) * 100 - (project?.cogs_in_cents ?? 0))
            );
          }) / 100,
      };
    }),
    (x) => x.startOfMonth.toJSDate().getTime()
  ).map((x, i, arr) => ({
    ...x,
    cumulativeNetSales: _.sumBy(arr.slice(0, i + 1), "netSales") ?? 0,
  }));

  const firstDayOfYear = monthlyDataNetEarnedSales[0].startOfMonth.startOf(
    "year"
  );
  const lastDayOfYear = monthlyDataNetEarnedSales[0].startOfMonth.endOf("year");

  const lowPodForecast = pod.annual_revenue_target.revenue_target_dollars ?? 0;
  const highPodForecast = Math.floor(lowPodForecast * 1.3);
  const redPodForecast = Math.floor(lowPodForecast * 0.7);
  const greenPodForecast = Math.floor(lowPodForecast * 1.5);
  const currency = (value: number | bigint) => currencyFormatter.format(value);

  console.log("PodRevenueForecast -> completedProjects: ", completedProjects);
  console.log("PodRevenueForecast -> cancelledProjects: ", cancelledProjects);
  console.log("PodRevenueForecast -> Number of projects: ", completedProjects.projects.length + cancelledProjects.projects.length);
  console.log("PodRevenueForecast -> Completed + Cancelled Budget: ", currency(completedProjects.budget + cancelledProjects.budget));

  return (
    <div css={[tw`flex flex-col items-start gap-y-6`]}>
      <div style={{ width: chartContainerWidth }}>
        <ChartHeading>
          <div
            css={css`
              color: #e535ab;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding-top: 1rem;
            `}
          >
            Pod Performance
          </div>
        </ChartHeading>
        <PodRevenueChart
          firstDayOfYear={firstDayOfYear}
          lastDayOfYear={lastDayOfYear}
          revenueTargetDollars={
            pod.annual_revenue_target.revenue_target_dollars ?? 0
          }
          dollarsSold={monthlyDataNetSales}
          monthlyDataNetEarnedSales={monthlyDataNetEarnedSales}
        />
      </div>
      <table
        css={[
          tw`ml-12`,

          css`
            text-align: right;
            td,
            th {
              border: 1px solid #ccc;
              padding: 0.5rem;
              vertical-align: top;
            }
            th {
              font-weight: bold;
            }
          `,
        ]}
      >
        <tbody>
          <tr
            css={css`
              background: aliceblue;
              border-right: solid 1px #ddd;
              border-left: solid 1px #ddd;
            `}
          >
            <td css={tw`font-bold`}>Pod Lead:</td>
            <td css={tw`text-left font-bold`}>{pod.senior_brand_maven_lead}</td>
          </tr>
          <tr>
            <th>Completed in {pod.annual_revenue_target.year}:</th>
            <td>
              $
              {currency(
                (completedProjects.budget * 100 - completedProjects.cogs) / 100
              )}
            </td>
          </tr>
          <tr>
            <th>Cancelled in {pod.annual_revenue_target.year}:</th>
            <td>
              $
              {currency(
                (cancelledProjects.budget * 100 - cancelledProjects.cogs) / 100
              )}
            </td>
          </tr>
          <tr>
            <th>In Progress:</th>
            <td>
              $
              {currency(
                (inProgressProjects.budget * 100 - inProgressProjects.cogs) /
                  100
              )}
            </td>
          </tr>
          <tr>
            <th>On Hold:</th>
            <td>
              $
              {currency(
                (onHoldProjects.budget * 100 - onHoldProjects.cogs) / 100
              )}
            </td>
          </tr>
          <tr>
            <th>Total:</th>
            <td>
              $
              {currency(
                (completedProjects.budget * 100 - completedProjects.cogs) /
                  100 +
                  (cancelledProjects.budget * 100 - cancelledProjects.cogs) /
                    100 +
                  (inProgressProjects.budget * 100 - inProgressProjects.cogs) /
                    100 +
                  (onHoldProjects.budget * 100 - onHoldProjects.cogs) / 100
              )}
            </td>
          </tr>
          <tr>
            <td colSpan={3}></td>
          </tr>
          <tr>
            <th>Pod forecast:</th>
            <td>
              <span css={css`color:green;`}>Target: ${currency(highPodForecast)}</span>
              <br />
              <span css={css`color:red;`}>Base: ${currency(lowPodForecast)}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const PodRevenueChart = ({
  firstDayOfYear,
  lastDayOfYear,
  revenueTargetDollars,
  dollarsSold,
  monthlyDataNetEarnedSales,
}: {
  firstDayOfYear: DateTime;
  lastDayOfYear: DateTime;
  revenueTargetDollars: number;
  dollarsSold: number;
  monthlyDataNetEarnedSales: {
    startOfMonth: DateTime;
    year: string;
    month: string;
    netSales: number;
    cumulativeNetSales: number;
  }[];
}) => {
  const hasSoldTarget = dollarsSold > revenueTargetDollars;
  const max = _.max([
    ...monthlyDataNetEarnedSales.map((row) => row.netSales),
    dollarsSold,
    revenueTargetDollars,
  ]);

  const currency = (value: number | bigint) => currencyFormatter.format(value);

  return (
    <VictoryChart
      height={500}
      width={600}
      domainPadding={{ y: 20 }}
      padding={80}
      containerComponent={<VictoryVoronoiContainer voronoiDimension="x" />}
    >

      {/* <VictoryScatter
        data={_.range(1, 13).map((month, i) => {
          const monthStart = firstDayOfYear.set({ month });
          return {
            i,
            x: monthStart.toSeconds(),
            y: (revenueTargetDollars / 12) * month,
          };
        })}
        size={5}
        style={{
          data: { fill: podBonusColor },
          labels: { fill: podBonusColor },
        }}
        labels={({ datum }) => [
          `Pod net sales target: $${currency(datum.y)}`,
          Math.floor((datum.y / revenueTargetDollars) * 100) + "%",
        ]}
        labelComponent={
          <VictoryTooltip
            cornerRadius={0}
            flyoutStyle={{ fill: "white" }}
            dy={({ datum, index }) => {
              return (monthlyDataNetEarnedSales[datum.i]?.cumulativeNetSales ??
                0) >
                (revenueTargetDollars / 12) * datum.i
                ? 40
                : -0;
            }}
          />
        }
      /> */}

      {/* Green Cone representing the high (1.3) to very high (1.5) brand forecast data from January to December */}
      <VictoryArea
        data={Array.from({ length: 12 }, (_, i) => {
          const startOfMonth = DateTime.fromObject({
            year: firstDayOfYear.year,
            month: i + 1,
            day: 1,
          });
          const month = startOfMonth.month; // 1-12
          // For now we are using a 30% increase for the high forecast
          const highForecastDollars = Math.floor(revenueTargetDollars * 1.3);
          // Increasing the low forecast by 50% to get the green forecast
          const greenForecastDollars = Math.floor(revenueTargetDollars * 1.6);

          return {
            x: startOfMonth.toSeconds(),
            y0: (highForecastDollars / 12) * month,
            y: (greenForecastDollars / 12) * month,
          };
        })}
        style={{
          data: {
            fill: aboveForecastColor,
            fillOpacity: 0.3,
            pointerEvents: "none",
          },
        }}
      />
      {/* Green line at top of grey cone */}
      <VictoryLine
        data={_.range(1, 13).map((month) => {
          const monthStart = firstDayOfYear.set({ month });
          // For now we are using a 30% increase for the high forecast
          const highForecastDollars = Math.floor(revenueTargetDollars * 1.3);
          return {
            i: month,
            x: monthStart.toSeconds(),
            y: (highForecastDollars / 12) * month,
          };
        })}
        style={{
          data: {
            stroke: "green",
            strokeWidth: 3,
          },
        }}
      />
      {/* Yellow Cone representing the low to high brand forecast data from January to December */}
      <VictoryArea
        data={Array.from({ length: 12 }, (_, i) => {
          const startOfMonth = DateTime.fromObject({
            year: firstDayOfYear.year,
            month: i + 1,
            day: 1,
          });
          const month = startOfMonth.month; // 1-12
          // For now we are using the revenue target set by Admin/Forecasting as the low forecast
          const lowForecastDollars = revenueTargetDollars;
          // For now we are using a 30% increase for the high forecast
          const highForecastDollars = Math.floor(revenueTargetDollars * 1.3);

          return {
            x: startOfMonth.toSeconds(),
            y0: (lowForecastDollars / 12) * month,
            y: (highForecastDollars / 12) * month,
          };
        })}
        style={{
          data: {
            fill: forecastColor,
            fillOpacity: 0.3,
            pointerEvents: "none",
          },
        }}
      />
      {/* Red line at bottom of yellow cone */}
      <VictoryLine
        data={_.range(1, 13).map((month) => {
          const monthStart = firstDayOfYear.set({ month });
          return {
            i: month,
            x: monthStart.toSeconds(),
            y: (revenueTargetDollars / 12) * month,
          };
        })}
        style={{
          data: {
            stroke: "red",
            strokeWidth: 3,
          },
        }}
      />
      {/* Red Cone representing the very low to low brand forecast data from January to December */}
      <VictoryArea
        data={Array.from({ length: 12 }, (_, i) => {
          const startOfMonth = DateTime.fromObject({
            year: firstDayOfYear.year,
            month: i + 1,
            day: 1,
          });
          const month = startOfMonth.month; // 1-12
          // Reducing the low forecast by 30% to get the red forecast
          const redForecastDollars = Math.floor(revenueTargetDollars * 0.7);
          // We are using the revenue target set by Admin/Forecasting as the low forecast
          const lowForecastDollars = revenueTargetDollars;

          return {
            x: startOfMonth.toSeconds(),
            y0: (redForecastDollars / 12) * month,
            y: (lowForecastDollars / 12) * month,
          };
        })}
        style={{
          data: {
            fill: belowForecastColor,
            fillOpacity: 0.3,
            pointerEvents: "none",
          },
        }}
      />
      {/** Line at top of graph that shows the Y where the revenue would end up if Pod finished all work */}
      <VictoryLine
        data={[
          {
            i: 0,
            x: firstDayOfYear.toSeconds(),
            y: dollarsSold,
          },
          {
            i: 1,
            x: lastDayOfYear.toSeconds(),
            y: dollarsSold,
          },
        ]}
        style={{
          data: {
            stroke: soldColor,
            strokeWidth: 2,
          },
          labels: { fill: soldColor },
        }}
        labels={({ datum }) =>
          datum.i === 0
            ? `(Completed + Cancelled in ${
                firstDayOfYear.year
              }) + In Progress + On Hold: $${currency(datum.y)}`
            : null
        }
        labelComponent={
          <VictoryLabel
            textAnchor="start"
            dx="10"
            dy={hasSoldTarget ? -6 : 20}
          />
        }
      />
      {/* Line that shows how the POD is doing each month */}
      <VictoryLine
        data={monthlyDataNetEarnedSales.map((row, i, arr) => ({
          year: row.year,
          x: row.startOfMonth.toSeconds(),
          y: _.sumBy(arr.slice(0, i + 1), (x) => x.netSales),
        }))}
        style={{
          data: {
            stroke: "#000",
            strokeWidth: 2,
          },
          labels: { fill: "#000" },
        }}
      />
      {/* Scatter points (dots) that show how the POD is doing each month */}
      <VictoryScatter
        data={monthlyDataNetEarnedSales.map((row, i, arr) => ({
          year: row.year,
          x: row.startOfMonth.toSeconds(),
          y: _.sumBy(arr.slice(0, i + 1), (x) => x.netSales),
        }))}
        size={5}
        style={{ data: { fill: "#000" } }}
        labels={({ datum }) => [
          `${DateTime.fromSeconds(datum.x).toFormat(
            "yyyy-MM"
          )} (Completed + Cancelled): $${currency(datum.y)}`,
          Math.floor((datum.y / Math.floor(revenueTargetDollars * 1.3)) * 100) + "%",
        ]}
        labelComponent={
          <VictoryTooltip
            cornerRadius={0}
            flyoutStyle={{ fill: "white" }}
            dy={({ datum, index }) => {
              const idx = Number(index);
              return (monthlyDataNetEarnedSales[idx]?.cumulativeNetSales ?? 0) >
                (revenueTargetDollars / 12) * (idx + 1)
                ? 0
                : 80;
            }}
          />
        }
      />
      {/** X-axis */}
      <VictoryAxis
        style={{
          tickLabels: {
            fontSize: 15,
            paddingTop: 20,
            angle: 30,
            textAnchor: "left",
            verticalAnchor: "start",
          },
          ticks: {
            stroke: "#000000",
            size: 8,
          },
        }}
        tickValues={Interval.fromDateTimes(
          monthlyDataNetEarnedSales[0].startOfMonth,
          monthlyDataNetEarnedSales[0].startOfMonth.plus({ months: 12 })
        )
          .splitBy({ months: 1 })
          .map((x) => x.start.toSeconds())}
        tickFormat={(x) => DateTime.fromSeconds(x, { zone: 'America/Toronto' }).toFormat("MMMM")}
      />
      {/** Y-axis */}
      <VictoryAxis dependentAxis domain={[0, max ?? 0]} />
    </VictoryChart>
  );
};
