
import { gql, NetworkStatus, useMutation, useQuery } from "@apollo/client";
import { EditIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Button,
  Tooltip,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { differenceInBusinessDays, isWithinInterval } from "date-fns";
import { getYear } from "date-fns/esm";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useState } from "react";
import Select from "react-select";
import {
  CellProps,
  Column,
  FilterProps,
  HeaderProps,
  Renderer,
  UseFiltersColumnOptions,
  UseFiltersColumnProps,
  UseGroupByColumnOptions,
  UseSortByColumnOptions,
} from "react-table";
import tw, { css } from "twin.macro";
import * as GraphQLTypes from "../../codegen/graphql-types";
import {
  GetProjectsQuery,
  GetProjectsQueryVariables,
  ProjectInvoicesQuery,
  ProjectInvoicesQueryVariables,
  PullProjectDetailsFromTeamworkMutation,
  PullProjectDetailsFromTeamworkMutationVariables,
  UpsertProjectBudgetMutation,
  UpsertProjectBudgetMutationVariables,
  UpsertProjectCategoryMutation,
  UpsertProjectCategoryMutationVariables,
  UpsertProjectClientBrandManagerMutation,
  UpsertProjectClientBrandManagerMutationVariables,
  UpsertProjectComponentCodeMutation,
  UpsertProjectComponentCodeMutationVariables,
  UpsertProjectNotesMutation,
  UpsertProjectNotesMutationVariables,
  useUpsertProjectOwnerMutation,
  useUpsertProjectSbmLeadMutation,
  useUpsertProjectSalesSplitPodMutation,
  useUpsertProjectSalesSplitRatioPercentMutation,
} from "../../codegen/graphql-types";
import { Money } from "../../components/Money";
import { EstimateBudgetUnaccountedForTooltipContent } from "../../Financials/Estimates/EstimateBudgetUnaccountedForTooltipContent";
import { FullSpinner } from "../../FullSpinner";
import { PopoverInput } from "../../helpers/PopoverInput";
import { PopoverSelect } from "../../helpers/PopoverSelect";
import { useEstimatesContext } from "../../helpers/useEstimates";
import { useLocalStorage } from "../../helpers/useLocalStorage";
import { usePeopleContext } from "../../helpers/usePeopleContext";
import { InvoiceFragment } from "../Expenses/ProjectInvoices";
import {
  getBlobUrlFromInvoiceFileName,
  SupplierInvoiceFile,
} from "../Expenses/SupplierInvoiceFile";
import { useAuthContext } from "../../useAuth";
import { MultiSelectColumnFilter } from "./MultiSelectColumnFilter";
import { ProjectsTable } from "./ProjectsTable";
import { ProjectStatus, projectStatuses } from "./projectStatuses";
import { statutoryHolidays } from "../statutoryHolidays";
import { TextTruncateRevealOnHover } from "./TextTruncateRevealOnHover";
import { usePromptSelectProject } from "../Expenses/usePromptSelectProject";
import { cancelledSymbol } from "../../helpers/usePromptState";
import { useUpdateSingleInvoice } from "../Expenses/useUpdateSingleInvoice";
import { RequiresReviewFilter } from "./RequiresReviewFilter";
import type { ProjectFilters } from "./ProjectsPage";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  // style: 'currency',
  // currency: 'CAD',
  // currencyDisplay: ''
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const hourFormatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 1,
});

const ProjectFragment = gql`
  fragment ProjectFragment on Project {
    id
    name
    description
    owner_id
    owner {
      id
      first_name
      last_name
      title
    }
    client {
      id
      name
    }
    client_brand_manager
    budget
    component_code
    category {
      id
      name
      color
    }
    time_entries {
      id
      minutes
      user_id
    }
    project_type
    project_notes
    project_notes_2
    is_reviewed
    language
    internal_review
    external_reviewer
    last_synced_details_at
    project_status
    start_date
    end_date
    created_at
    senior_brand_maven_lead

    rates {
      id
      person_id
      rate_in_cents
    }

    # too expensive to query this field. prisma does not batch aggregations
    # cogs_in_cents

    invoices {
      id
      amount_in_cents
    }

    associated_estimate {
      id
      name
      po_subtotal
      projects {
        id
        budget
      }
      # too expensive to query this field. prisma does not batch aggregations
      # budget_unaccounted_for
    }

    sales_split_ratio
    sales_split_pod {
      id
      senior_brand_maven_lead
    }
  }
`;

export const ProjectsTableDataContainer = ({
  minStartDate,
  selectedProjectStatuses,
  selectedOwnerIds,
  selectedSeniorBrandMavenLeadNames,
}: ProjectFilters) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [endYear, setEndYear] = useLocalStorage<string | null>(
    "endYear",
    null
  );
  const peopleData = usePeopleContext();
  const pods = peopleData.data?.pods;
  const [isSyncing, setIsSyncing] = useState(false);
  const titlesRates = peopleData.data?.people.reduce((acc, person) => {
    return {
      ...acc,
      [person.title]: person.rate,
    };
  }, {}) as Record<string, number>;

  const {
    data,
    networkStatus,
    refetch: refetchProjects,
    loading: projectsLoading,
  } = useQuery<GetProjectsQuery, GetProjectsQueryVariables>(
    gql`
      query getProjects($where: projectWhereInput) {
        projects(orderBy: { created_at: desc }, where: $where) {
          ...ProjectFragment
        }

        categories {
          id
          name
          color
        }
      }
      ${ProjectFragment}
    `,
    {
      // fetchPolicy: "network-only",
      variables: {
        where: {
          AND: [
            // { start_date: { gt: new Date('2020-01-01') } },
            // { end_date: { lt: new Date('2020-12-31') } },
            // { is_archived: { equals: false } },
            { is_deleted: { equals: false } },
            ...(minStartDate
              ? [
                  {
                    OR: [
                      { start_date: { gt: minStartDate } },
                      { start_date: { equals: null } },
                    ],
                  },
                ]
              : []),
            ...(selectedProjectStatuses.length
              ? [
                  {
                    OR: selectedProjectStatuses.map((x) => ({
                      project_status: { equals: x },
                    })),
                  },
                ]
              : []),
            ...(selectedOwnerIds.length
              ? [
                  {
                    project_owner_id: {
                      in: selectedOwnerIds.flatMap((x) =>
                        x === null ? [] : x
                      ),
                    },
                  },
                ]
              : []),
            ...(selectedSeniorBrandMavenLeadNames.length
              ? [
                  {
                    OR: [
                      {
                        senior_brand_maven_lead: {
                          in: selectedSeniorBrandMavenLeadNames.flatMap((x) =>
                            x === null ? [] : x
                          ),
                        },
                      },
                      {
                        sales_split_pod_id: {
                          in: pods
                            ?.filter(
                              (pod) =>
                                pod.senior_brand_maven_lead &&
                                selectedSeniorBrandMavenLeadNames.includes(
                                  pod.senior_brand_maven_lead
                                )
                            )
                            .map((pod) => pod.id),
                        },
                      },

                      ...(selectedSeniorBrandMavenLeadNames.includes(null)
                        ? [{ senior_brand_maven_lead: { equals: null } }]
                        : []),
                    ],
                  },
                ]
              : []),
          ],
        },
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );

  const [upsertProjectComponentCode] = useMutation<
    UpsertProjectComponentCodeMutation,
    UpsertProjectComponentCodeMutationVariables
  >(
    gql`
      mutation UpsertProjectComponentCode(
        $projectId: ID!
        $newComponentCode: String!
      ) {
        upsertProjectComponentCode(
          projectId: $projectId
          componentCode: $newComponentCode
        ) {
          id
          component_code
        }
      }
    `
  );

  const [upsertProjectCategoryId] = useMutation<
    UpsertProjectCategoryMutation,
    UpsertProjectCategoryMutationVariables
  >(
    gql`
      mutation UpsertProjectCategory(
        $upsertProjectCategoryIdProjectId: ID!
        $upsertProjectCategoryIdProjectCategoryId: ID!
      ) {
        upsertProjectCategoryId(
          projectId: $upsertProjectCategoryIdProjectId
          projectCategoryId: $upsertProjectCategoryIdProjectCategoryId
        ) {
          id
          category {
            id
            name
          }
        }
      }
    `
  );

  const [upsertProjectBudget] = useMutation<
    UpsertProjectBudgetMutation,
    UpsertProjectBudgetMutationVariables
  >(
    gql`
      mutation UpsertProjectBudget($projectId: ID!, $budget: Int!) {
        upsertProjectBudget(projectId: $projectId, budget: $budget) {
          id
          budget
        }
      }
    `,
    {
      optimisticResponse: ({ projectId }) => ({
        upsertProjectBudget: {
          __typename: "Project",
          id: projectId,
          budget: ("..." as unknown) as number,
        },
      }),
    }
  );

  const [upsertProjectClientBrandManager] = useMutation<
    UpsertProjectClientBrandManagerMutation,
    UpsertProjectClientBrandManagerMutationVariables
  >(
    gql`
      mutation UpsertProjectClientBrandManager(
        $projectId: ID!
        $clientBrandManager: String!
      ) {
        upsertProjectClientBrandManager(
          projectId: $projectId
          clientBrandManager: $clientBrandManager
        ) {
          id
          client_brand_manager
        }
      }
    `,
    {
      optimisticResponse: ({ projectId }) => ({
        upsertProjectClientBrandManager: {
          __typename: "Project",
          id: projectId,
          client_brand_manager: "...",
        },
      }),
    }
  );

  const [upsertProjectNotes] = useMutation<
    UpsertProjectNotesMutation,
    UpsertProjectNotesMutationVariables
  >(
    gql`
      mutation UpsertProjectNotes($projectId: ID!, $newNotes: String!) {
        upsertProjectNotes(projectId: $projectId, newNotes: $newNotes) {
          id
          project_notes
        }
      }
    `,
    {
      optimisticResponse: ({ projectId }) => ({
        upsertProjectNotes: {
          __typename: "Project",
          id: projectId,
          project_notes: "...",
        },
      }),
    }
  );

  const [upsertProjectNotes2] = useMutation<
    GraphQLTypes.UpsertProjectNotes2Mutation,
    GraphQLTypes.UpsertProjectNotes2MutationVariables
  >(GraphQLTypes.UpsertProjectNotes2Document,
    {
      optimisticResponse: ({ projectId }) => ({
        upsertProjectNotes2: {
          __typename: "Project",
          id: projectId,
          project_notes_2: "...",
        },
      }),
    }
  );

  const [upsertProjectType] = useMutation<
    GraphQLTypes.UpsertProjectTypeMutation,
    GraphQLTypes.UpsertProjectTypeMutationVariables
  >(gql`
    mutation UpsertProjectType(
      $upsertProjectTypeProjectId: ID!
      $upsertProjectTypeProjectType: project_type!
    ) {
      upsertProjectType(
        projectId: $upsertProjectTypeProjectId
        projectType: $upsertProjectTypeProjectType
      ) {
        id
        project_type
      }
    }
  `);

  const [upsertProjectLanguage] = useMutation<
    GraphQLTypes.UpsertProjectLanguageMutation,
    GraphQLTypes.UpsertProjectLanguageMutationVariables
  >(gql`
    mutation UpsertProjectLanguage(
      $upsertProjectLanguageProjectId: ID!
      $upsertProjectLanguageLanguageType: project_language!
    ) {
      upsertProjectLanguage(
        projectId: $upsertProjectLanguageProjectId
        languageType: $upsertProjectLanguageLanguageType
      ) {
        id
        language
      }
    }
  `);

  const [upsertProjectStatus] = useMutation<
    GraphQLTypes.UpsertProjectStatusMutation,
    GraphQLTypes.UpsertProjectStatusMutationVariables
  >(gql`
    mutation UpsertProjectStatus(
      $upsertProjectStatusProjectId: ID!
      $upsertProjectStatusStatus: String!
    ) {
      upsertProjectStatus(
        projectId: $upsertProjectStatusProjectId
        status: $upsertProjectStatusStatus
      ) {
        id
        project_status
      }
    }
  `);
  const [upsertProjectOwner] = useUpsertProjectOwnerMutation();
  const [upsertProjectSbm] = useUpsertProjectSbmLeadMutation();
  const [upsertProjectSalesSplitPod] = useUpsertProjectSalesSplitPodMutation();
  const [
    upsertProjectSalesSplitRatioPercentMutation,
  ] = useUpsertProjectSalesSplitRatioPercentMutation();

  const [upsertProjectExternalReviewType] = useMutation<
    GraphQLTypes.UpsertProjectExternalReviewTypeMutation,
    GraphQLTypes.UpsertProjectExternalReviewTypeMutationVariables
  >(gql`
    mutation UpsertProjectExternalReviewType(
      $upsertProjectExternalReviewTypeProjectId: ID!
      $upsertProjectExternalReviewTypeExternalReviewType: external_reviewer!
    ) {
      upsertProjectExternalReviewType(
        projectId: $upsertProjectExternalReviewTypeProjectId
        externalReviewType: $upsertProjectExternalReviewTypeExternalReviewType
      ) {
        id
        external_reviewer
      }
    }
  `);

  const [setProjectInternalReview] = useMutation<
    GraphQLTypes.SetProjectInternalReviewMutation,
    GraphQLTypes.SetProjectInternalReviewMutationVariables
  >(gql`
    mutation SetProjectInternalReview(
      $setProjectInternalReviewProjectId: ID!
      $setProjectInternalReviewInternalReview: Boolean!
    ) {
      setProjectInternalReview(
        projectId: $setProjectInternalReviewProjectId
        internalReview: $setProjectInternalReviewInternalReview
      ) {
        id
        internal_review
      }
    }
  `);

  const [updateOneProject] = useMutation<
    GraphQLTypes.UpdateOneProjectMutation,
    GraphQLTypes.UpdateOneProjectMutationVariables
  >(
    gql`
      mutation UpdateOneProject(
        $updateOneProjectData: projectUpdateInput!
        $updateOneProjectWhere: projectWhereUniqueInput!
      ) {
        updateOneProject(
          data: $updateOneProjectData
          where: $updateOneProjectWhere
        ) {
          ...ProjectFragment
        }
      }
      ${ProjectFragment}
    `,
    {
      // optimisticResponse: ({ projectId }) => ({
      //   upsertProjectClientBrandManager: {
      //     __typename: "Project",
      //     id: projectId,
      //     client_brand_manager: "...",
      //   },
      // }),
    }
  );

  const projects = data?.projects;
  const categories = data?.categories;

  const { data: estimatesData } = useEstimatesContext();
  const estimates = estimatesData?.estimates ?? [];

  const peopleGroupedByTitleCategory = React.useMemo(() => {
    if (!peopleData || peopleData.loading || !projects) {
      return {};
    }
    const userIdsWithTimeEntries = _.uniq(
      projects?.flatMap((project) =>
        project.time_entries.flatMap((timeEntry) => timeEntry.user_id)
      )
    );

    const mavenEmployeesWithTimeEntries = userIdsWithTimeEntries
      .map((userId) => peopleData.peopleByPersonId[userId])
      .filter((person) => person.company === "Maven Medical Marketing Inc.");

    return _.groupBy(mavenEmployeesWithTimeEntries, (person) => {
      const category = person.title || 'No Title';
      return category;
    });
  }, [peopleData, projects]);

  const postProcessedProjectRows = React.useMemo(() => {
    return projects?.map((project) => {
      const projectTimeEntries = project.time_entries;

      const timeEntriesToUse = projectTimeEntries.flatMap((entry) => {
        const person = peopleData.peopleByPersonId[entry.user_id];
        if (person?.company !== "Maven Medical Marketing Inc.") {
          return [];
        } else {
          return [
            {
              ...entry,
              person,
              rateInCents:
                project.rates.find(
                  (x) => x.person_id.toString() === person.id.toString()
                )?.rate_in_cents ?? 0,
            },
          ];
        }
      });

      const timeEntriesGroupedByPeopleTitleCategory = _.groupBy(
        timeEntriesToUse,
        (timeEntry) => {
          const category = timeEntry.person.title || 'No Title';
          return category;
        }
      );
      const timeEntriesGroupedByPersonId = _.groupBy(
        timeEntriesToUse,
        (timeEntry) => {
          return timeEntry.person.id;
        }
      );

      const cogs =
        (_.sumBy(project.invoices, (x) => x.amount_in_cents) ?? 0) / 100;

      const subtotalByTimeEntries = Math.round(
        timeEntriesToUse.reduce((sum, entry) => {
          const rate = entry.rateInCents / 100;
          if (rate === undefined || rate === null) {
            return sum;
            // throw new Error(
            //   `Could not find rate for ${person.id} ${person.first_name} ${person.last_name} with title of ${person.title}`
            // );
          }
          return sum + (entry.minutes / 60) * rate;
        }, 0)
      );

      // Calculated as project budget minus actual cogs
      const netIncome = (project.budget ?? 0) - cogs;

      // Tracking VS Budget; Calculated as Maven NET INCOME - ACTUAL subtotal
      const netIncomeMinusSubtotal =
        (project.budget ?? 0) - cogs - subtotalByTimeEntries;

      const totalHoursOnProject =
        _.sumBy(timeEntriesToUse, (entry) => entry.minutes) / 60;

      const blendedRateBasedOnNetIncome = totalHoursOnProject
        ? netIncome / totalHoursOnProject
        : 0;

      let businessDays;
      let statutoryHolidaysInPeriod;

      if (project.start_date > project.end_date) {
        setErrors([
          ...errors,
          `Error in Project: ${project.name}. Start date is after end date. Please check start & end dates in teamwork and ensure they are accurate. After updating, please re-sync the project.`,
        ]);
        console.error("Project start date is after end date. See console for details.");
        console.log("Project with start date after end date: ", project);
        console.log("start date: ", project.start_date);
        console.log("end date: ", project.end_date);
      }

      if (project.start_date && project.end_date && project.start_date <= project.end_date) {
        statutoryHolidaysInPeriod = statutoryHolidays.filter((holiday: any) => {
          // filter out days that only maven employees get off
          if (holiday.internalOnly) return false;
          return isWithinInterval(new Date(holiday.observedDate), {
            start: new Date(project.start_date),
            end: new Date(project.end_date),
          })
        });
        businessDays =
          differenceInBusinessDays(
            new Date(project.end_date),
            new Date(project.start_date)
          ) - statutoryHolidaysInPeriod.length;
      }

      const relativeProfitability = subtotalByTimeEntries
        ? netIncome / subtotalByTimeEntries
        : 0;

      return {
        ...project,
        cogs,
        subtotalByTimeEntries,
        blendedRateBasedOnNetIncome,
        netIncome,
        timeEntriesGroupedByPeopleTitleCategory,
        timeEntriesGroupedByPersonId,
        netIncomeMinusSubtotal,
        businessDays,
        statutoryHolidaysInPeriod,
        relativeProfitability,
      };
    });
  }, [peopleData.peopleByPersonId, projects]);

  const uniqueOwners = React.useMemo(() => {
    if (!projects) return [];
    return _.uniqBy(
      projects.map((project) => project.owner),
      (owner) => owner?.id
    );
  }, [projects]);

  const [updateSingleInvoice] = useUpdateSingleInvoice();
  const { modal, promptSelectProject } = usePromptSelectProject();

  const columns = React.useMemo(() => {
    if (!postProcessedProjectRows) return [];
    type ProjectRow = typeof postProcessedProjectRows[number];
    type DataColumn = UseSortByColumnOptions<ProjectRow> &
      UseFiltersColumnOptions<ProjectRow> &
      UseGroupByColumnOptions<ProjectRow> &
      Column<ProjectRow> & {
        Header2?: Renderer<UseFiltersColumnProps<ProjectRow>>;
      };
    const NONE = `(None)`;

    const startDateYears = _.uniq(
      postProcessedProjectRows.flatMap((row) => {
        if (!row.start_date) return [];
        return getYear(new Date(row.start_date));
      })
    );
    const endDateYears = _.uniq(
      postProcessedProjectRows.flatMap((row) => {
        if (!row.start_date) return [];
        return getYear(new Date(row.end_date));
      })
    );

    const columns: Array<DataColumn | { columns: Array<DataColumn> }> = [
      {
        Header: "Owner",
        accessor: (x) => x.owner?.id ?? NONE,
        filter: "multiple",
        Filter: ({
          column,
        }: FilterProps<ProjectRow> & {
          column: UseFiltersColumnProps<ProjectRow>;
        }) => (
          <div
            css={css`
              min-width: 10rem;
            `}
          >
            <Select
              isMulti={true}
              closeMenuOnSelect={true}
              defaultValue={column.filterValue}
              onChange={(selectedOptions) => {
                const allValues = Array.from(selectedOptions ?? [])
                  .map((o) => o.value)
                  .filter(Boolean);
                column.setFilter(
                  allValues && allValues.length ? allValues : undefined
                );
              }}
              options={[
                {
                  value: undefined,
                  label: "All",
                },
              ].concat(
                // @ts-expect-error
                uniqueOwners.map((owner) => {
                  return {
                    value: owner?.id ?? NONE,
                    label: owner
                      ? `${owner.first_name} (${
                          column.preFilteredRows.filter(
                            (preFilteredRow) =>
                              preFilteredRow.values[column.id] === owner.id
                          ).length
                        })`
                      : NONE,
                  };
                })
              )}
            />
          </div>
        ),
        Cell: ({ row }: CellProps<ProjectRow, string | undefined>) => {
          const owner = row.original.owner;
          if (!owner) return NONE;
          return (
            <div
              css={css`
                min-width: 6em;
              `}
            >
              <PopoverSelect
                displayText={`${owner.first_name} ${owner.last_name}`}
                options={peopleData.data?.people
                  .map((person) => ({
                    id: person.id as string,
                    label: `${person.first_name} ${person.last_name}`,
                  })) ?? []}
                onSubmit={async (e, newValue) => {
                  if (!newValue) return;
                  await upsertProjectOwner({
                    variables: {
                      projectId: row.original.id,
                      projectOwnerId: newValue.id,
                    },
                  });
                }}
              />
            </div>
          );
        }
      },
      {
        Header: "Pod Lead",
        accessor: (x) => x.senior_brand_maven_lead ?? NONE,
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
        Cell: ({ row }: CellProps<ProjectRow, string | undefined>) => {
          // upsertProjectSbm
          return (
            <div
              css={css`
                min-width: 6em;
              `}
            >
              {pods && (
                <PopoverSelect
                  displayText={row.original.senior_brand_maven_lead ?? NONE}
                  options={pods
                    .map((pod) => ({
                      value: pod.senior_brand_maven_lead as string,
                      label: pod.senior_brand_maven_lead as string,
                    }))
                  }
                  onSubmit={async (e, newValue) => {
                    if (!newValue || !newValue?.value) return;
                    await upsertProjectSbm({
                      variables: {
                        projectId: row.original.id,
                        sbmLeadName: newValue.value,
                      },
                    });
                  }}
                />)}
            </div>
          );
        },
      },
      {
        Header: "Split Net Sales With",
        accessor: (x) => x.sales_split_pod?.senior_brand_maven_lead ?? NONE,
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
        Cell: ({ row }: CellProps<ProjectRow, string | undefined>) => {
          return (
            <div
              css={css`
                min-width: 6em;
              `}
            >
              {/* Select which pod to split net sales with */}
              {/* {pods && (
                <Select
                  isMulti={false}
                  options={pods.map((pod) => ({
                    value: pod.id,
                    label: pod.senior_brand_maven_lead as string,
                  }))}
                  defaultValue={pods.find(
                    (x) =>
                      x.senior_brand_maven_lead ===
                      row.original.sales_split_pod?.senior_brand_maven_lead
                  )}
                />
              )} */}
              {pods && (
                <PopoverSelect
                  displayText={
                    row.original.sales_split_pod?.senior_brand_maven_lead ??
                    NONE
                  }
                  options={pods
                    .map((pod) => ({
                      value: pod.id as string | null,
                      label: pod.senior_brand_maven_lead as string,
                    }))
                    .concat({
                      value: null,
                      label: NONE,
                    })}
                  onSubmit={async (e, selection) => {
                    await upsertProjectSalesSplitPod({
                      variables: {
                        projectId: row.original.id,
                        salesSplitPodId: selection?.value ?? null,
                      },
                    });
                  }}
                />
              )}
            </div>
          );
        },
      },
      {
        Header: "Split Ratio (OG Pod / Split Pod)",
        accessor: (x) => x.sales_split_ratio ?? NONE,
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
        Cell: ({ row }: CellProps<ProjectRow, string | undefined>) => {
          const formatSplitRatio = (splitRatio: number) => {
            return `${100 - splitRatio}% / ${splitRatio}%`;
          };
          return (
            <div
              css={css`
                min-width: 6.2em;
              `}
            >
              <PopoverSelect
                displayText={
                  row.original.sales_split_ratio
                    ? formatSplitRatio(row.original.sales_split_ratio)
                    : ""
                }
                options={[10, 20, 30, 40, 50, 60, 70, 80, 90]
                  .map((x) => ({
                    value: x as number | null,
                    label: formatSplitRatio(x),
                  }))
                  .concat({
                    value: null,
                    label: NONE,
                  })}
                onSubmit={async (e, selection) => {
                  await upsertProjectSalesSplitRatioPercentMutation({
                    variables: {
                      projectId: row.original.id,
                      salesSplitRatioPercent: selection?.value ?? null,
                    },
                  });
                }}
              />
            </div>
          );
        },
      },
      {
        Header: "Client",
        columns: [
          {
            Header: "Client: Company - Brand",
            accessor: (x) => x.client.name,
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Cell: ({ row }: CellProps<ProjectRow>) => {
              return (
                <TextTruncateRevealOnHover
                  css={css`
                    max-width: 14rem;
                  `}
                  text={row.original.client.name ?? ""}
                />
              );
            },
          },
          {
            Header: "Client: Brand Manager",
            accessor: (x) => x.client_brand_manager,
            Cell: ({ row }: CellProps<ProjectRow>) => {
              return (
                <PopoverInput
                  initialValue={row.original.client_brand_manager ?? ""}
                  displayText={row.original.client_brand_manager}
                  onSubmit={async (e, newValue) => {
                    await upsertProjectClientBrandManager({
                      variables: {
                        projectId: row.original.id,
                        clientBrandManager: newValue,
                      },
                    });
                  }}
                />
              );
              // upsertProjectClientBrandManager
            },
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
          },
        ],
      },
      {
        id: "project status",
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
        Header: (
          <div style={{ width: "max-content", minWidth: "10em" }}>
            Project Status
          </div>
        ),
        accessor: "project_status",
        Cell: ({ cell }: CellProps<ProjectRow>) => {
          const projectId = cell.row.original.id;
          return (
            <PopoverSelect
              displayText={cell.value}
              options={projectStatuses.map((value) => ({
                value: value,
                label: value,
              }))}
              onSubmit={async (e, selectedOption) => {
                // console.log({ selectedOption });
                if (!selectedOption?.value) {
                  return;
                }
                await upsertProjectStatus({
                  variables: {
                    upsertProjectStatusProjectId: projectId,
                    upsertProjectStatusStatus: selectedOption.value,
                  },
                });
              }}
            />
          );
        },
      },
      {
        Header: "Last Synced",
        sticky: true,
        // @ts-ignore
        accessor: "last_synced_details_at",
        Cell: ({ cell, row }) => {
          if (!cell.value) return null;
          const lastSynced = new Date(cell.value);
          return (
            <LastSynced
              dateSynced={lastSynced}
              projectId={row.original.id}
              onSyncStart={() => setIsSyncing(true)}
              onSyncComplete={async () => {
                await refetchProjects();
                setIsSyncing(false);
              }}
            />
          );
        },
      },
      {
        Header: "Project Name",
        // @ts-ignore
        accessor: "name",
        sticky: true,
        stickyLeft: 128,
        // filter: 'fuzzyText',
        // Filter: TextColumnFilter,
        Cell: ({ cell, row }) => (
          <div style={{ display: "flex" }}>
            <a
              href={`https://mavenmm.teamwork.com/#/projects/${row.original.id}/overview`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "block",
                position: "relative",
                marginRight: 5,
                padding: 6,
                marginTop: -6,
                marginBottom: -6,
                background: "#ffffff",
              }}
              css={{
                ".xx-tooltip": {
                  opacity: 0,
                },
                "&:hover .xx-tooltip": {
                  opacity: 1,
                },
              }}
            >
              <span
                className="xx-tooltip"
                style={{
                  position: "absolute",
                  pointerEvents: "none",
                  right: -3,
                  top: -3,
                  background: "#ffffff",
                  boxShadow: "0 0 12px 1px rgba(0, 0, 0, 0.1)",
                  padding: 8,
                  width: "max-content",
                }}
              >
                open in teamwork
                <ExternalLinkIcon style={{ marginLeft: 4 }} />
              </span>
              <ExternalLinkIcon />
            </a>
            <PopoverInput
              supressEditIcon={true}
              initialValue={cell.value}
              displayText={
                <>
                  <TextTruncateRevealOnHover
                    css={css`
                      max-width: min(33vw, 40em);
                      .group-hover-show {
                        text-decoration: underline;
                      }
                    `}
                    text={
                      <>
                        {cell.value ?? ""}
                        <EditIcon
                          className="cell-hover-visible"
                          style={{ marginLeft: "0.5em" }}
                        />
                      </>
                    }
                  />
                </>
              }
              onSubmit={async (e, newValue) => {
                await updateOneProject({
                  variables: {
                    updateOneProjectData: {
                      name: {
                        set: newValue,
                      },
                    },
                    updateOneProjectWhere: {
                      id: row.original.id,
                    },
                  },
                });
              }}
            />
          </div>
        ),
      },
      {
        Header: "Project Notes",
        accessor: (x) => x.project_notes,
        Cell: ({ row }: CellProps<ProjectRow>) => {
          return (
            <PopoverInput
              initialValue={row.original.project_notes ?? ""}
              supressEditIcon={true}
              displayText={
                <TextTruncateRevealOnHover
                  css={css`
                    max-width: 30em;
                    .group-hover-show {
                      text-decoration: underline;
                    }
                  `}
                  text={
                    <>
                      {row.original.project_notes ?? ""}
                      <EditIcon
                        className="cell-hover-visible"
                        style={{ marginLeft: "0.5em" }}
                      />
                    </>
                  }
                />
                // <span style={{
                //   whiteSpace: 'nowrap'
                // }}>{row.original.project_notes}</span>
              }
              onSubmit={async (e, newValue) => {
                await upsertProjectNotes({
                  variables: {
                    projectId: row.original.id,
                    newNotes: newValue,
                  },
                });
              }}
            />
          );
        },
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
      },
      {
        Header: "Project Notes 2",
        accessor: (x) => x.project_notes_2,
        Cell: ({ row }: CellProps<ProjectRow>) => {
          return (
            <PopoverInput
              initialValue={row.original.project_notes_2 ?? ""}
              supressEditIcon={true}
              displayText={
                <TextTruncateRevealOnHover
                  css={css`
                    max-width: 30em;
                    .group-hover-show {
                      text-decoration: underline;
                    }
                  `}
                  text={
                    <>
                      {row.original.project_notes_2 ?? ""}
                      <EditIcon
                        className="cell-hover-visible"
                        style={{ marginLeft: "0.5em" }}
                      />
                    </>
                  }
                />
              }
              onSubmit={async (e, newValue) => {
                await upsertProjectNotes2({
                  variables: {
                    projectId: row.original.id,
                    newNotes: newValue,
                  },
                });
              }}
            />
          );
        },
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
      },
      {
        Header: "STM Reviewed",
        // @ts-ignore
        accessor: (x) => ({
          is_reviewed: x.is_reviewed,
          relativeProfitability: x.relativeProfitability
        }),
        getCellStyles: (cell: any) => {
          const { is_reviewed, relativeProfitability } = cell.value;
          const roundedRP: number = Math.round(relativeProfitability * 100) / 100;
          return {
            textAlign: "center",
            backgroundColor: !relativeProfitability || is_reviewed
            ? undefined
            : roundedRP >= 1
            ? undefined
            : roundedRP >= 0.67
            ? "#FCFFAA"
            : "#FFC6B1",
          };
        },
        Cell: ({ cell, row }: CellProps<ProjectRow>) => {
          const { is_reviewed, relativeProfitability } = cell.value;
          // Round profitability to 2 decimal places in a float number;
          const roundedRP: number = Math.round(relativeProfitability * 100) / 100
          const isProfitable = roundedRP >= 1;
          if (isProfitable || !relativeProfitability) return "N/A";
          return (
            <div css={css`width: 100px;text-align:center;`}>
              <PopoverSelect
                center={true}
                supressEditIcon={true}
                displayText={is_reviewed ? "Reviewed" : "Requires review"}
                options={[
                  { value: "Reviewed", label: "Reviewed" },
                  { value: "Requires review", label: "Requires review" },
                ]}
                onSubmit={async (e, newValue) => {
                  await updateOneProject({
                    variables: {
                      updateOneProjectData: {
                        is_reviewed: {
                          set: newValue?.value === "Reviewed" ? true : false,
                        },
                      },
                      updateOneProjectWhere: {
                        id: row.original.id,
                      },
                    },
                  });
                }}
              />
            </div>
          );
        },
        Filter: RequiresReviewFilter,
        filter: (rows, id, filterValue) => {
          return rows.filter(row => {
            const columnId = Array.isArray(id) ? id[0] : id;
            const { is_reviewed, relativeProfitability } = row.values[columnId];
            
            if (!relativeProfitability || relativeProfitability >= 1) return false;
            if (!filterValue || filterValue.length === 0) return true;
            return filterValue.includes(is_reviewed ? "Reviewed" : "Requires review");
          });
        },
      },
      {
        Header: "Relative profitability",
        Header2: ({ filteredRows }) => {
          // console.log(filteredRows);
          let sumNetSales = 0;
          let countNetSales = 0;

          let sumSubtotalByHours = 0;
          let countSubtotalByHours = 0;
          filteredRows.forEach((row) => {
            if (row.original.netIncome) {
              sumNetSales += row.original.netIncome;
              countNetSales += 1;
            }

            const subtotalByTimeEntries = row.original.subtotalByTimeEntries;
            if (subtotalByTimeEntries) {
              sumSubtotalByHours += subtotalByTimeEntries;
              countSubtotalByHours += 1;
            }
          });
          return (
            <div
              css={css`
                width: max-content;
                text-align: right;
              `}
            >
              μ ={(sumNetSales / sumSubtotalByHours).toFixed(2)}
            </div>
          );
        },
        // @ts-ignore
        accessor: x => {
          return x.relativeProfitability;
        },
        sortType: (rowA, rowB, columnId) => {
          // need to manually provide sort order because
          // the default sort mixes numbers up for unknown reasons; 
          // can filter stefko's 2023 projects to only include ones with names of 'SIMPONI October 2023', ''Imbruvica Extension Study eDetail', 'NNS Training Device Packaging' to reproduce
          // [0.31..., 0.54..., 0.6..., 0.7...] gets sorted ascendingly into [0.54..., 0.31..., 0.60..., 0.69...]
          return rowA.original.relativeProfitability - rowB.original.relativeProfitability;
        },
        getCellStyles: (cell: any) => {
          return {
            backgroundColor:
              !cell.value
                ? undefined
                : cell.value >= 1
                ? "#C8FFAF"
                : cell.value >= 0.67
                ? "#FCFFAA"
                : "#FFC6B1",
          };
        },
        Cell: ({ cell }: { cell: any, row: any }) => {
          if (!cell.value) return null;
          return cell.value.toFixed(2);
        },
      },

      {
        id: "budget_columns",
        Header: "Budget",
        columns: [
          {
            Header: (
              <span style={{ width: "max-content" }}>
                Gross Sales <br /> <small>(Project Budget)</small>
              </span>
            ),
            Header2: ({ filteredRows }) => {
              let sum = 0;
              let entriesWithValues = 0;
              filteredRows.forEach((row) => {
                if (row.original.budget) {
                  sum += row.original.budget;
                  entriesWithValues += 1;
                }
              });
              // const countedBuckets = _.omit(
              //   _.countBy(
              //     filteredRows,
              //     (row) => Math.floor((row.original.budget ?? 0) / 5000) * 5000
              //   ),
              //   "0"
              // );

              return (
                <div
                  css={css`
                    width: max-content;
                    text-align: right;
                  `}
                >
                  Σ = ${currencyFormatter.format(sum)}
                  <br />μ = $
                  {currencyFormatter.format(
                    Math.floor(sum / entriesWithValues)
                  )}
                  {/* <Popover isLazy trigger="hover">
                    <PopoverTrigger>
                      <span>
                        μ = $
                        {currencyFormatter.format(
                          Math.floor(sum / entriesWithValues)
                        )}
                      </span>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverBody>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          {Object.entries(countedBuckets).map(
                            ([key, value]) => (
                              <>
                                <div style={{ textAlign: "right" }}>
                                  {currencyFormatter.format(Number(key) - 5000)}{" "}
                                  - {currencyFormatter.format(Number(key))}
                                </div>
                                <div
                                  style={{ textAlign: "left", paddingLeft: 10 }}
                                >
                                  {value}
                                </div>
                              </>
                            )
                          )}
                        </div>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover> */}
                </div>
              );
            },
            accessor: "budget",
            Cell: ({
              cell,
              row,
            }: CellProps<ProjectRow, number | undefined>) => {
              return (
                <div
                  style={{
                    display: "flex",
                    width: "max-content",
                    alignContent: "center",
                    cursor: "pointer",
                    minWidth: "100%",
                    justifyContent: "flex-end",
                  }}
                  onClick={() => {
                    const value = window.prompt(
                      "Enter new Budget",
                      cell.value?.toString() || undefined
                    );

                    if (!value) return;

                    const valueNumber = Number(value);
                    if (Number.isNaN(valueNumber)) return;

                    upsertProjectBudget({
                      variables: {
                        projectId: row.original.id,
                        budget: valueNumber,
                      },
                    });
                  }}
                >
                  <EditIcon
                    className="cell-hover-visible"
                    style={{ marginRight: "auto" }}
                  />
                  {isNaN(cell.value as any)
                    ? "..."
                    : cell.value && "$" + currencyFormatter.format(cell.value)}
                </div>
              );
            },
            // aggregate: 'count',
            // Aggregated: ({ value }) => `${value}`
          },
          {
            Header: (
              <span>
                COGS <br /> <small>(Actual)</small>
              </span>
            ),
            Header2: ({ filteredRows }) => {
              let sum = 0;
              let entriesWithValues = 0;
              filteredRows.forEach((row) => {
                const val = row.original.cogs;
                if (val) {
                  sum += val;
                  entriesWithValues += 1;
                }
              });
              // const countedBuckets = _.omit(
              //   _.countBy(
              //     filteredRows,
              //     (row) => Math.floor((row.original.cogs ?? 0) / 500) * 500
              //   ),
              //   "0"
              // );

              return (
                <div
                  css={css`
                    width: max-content;
                    text-align: right;
                  `}
                >
                  Σ = $
                  {currencyFormatter.format(
                    _.sumBy(filteredRows, (row) => row.original.cogs)
                  )}
                  <br />μ = $
                  {currencyFormatter.format(
                    Math.floor(sum / entriesWithValues)
                  )}
                  {/* <Popover isLazy trigger="hover">
                    <PopoverTrigger>
                      <span>
                        μ = $
                        {currencyFormatter.format(
                          Math.floor(sum / entriesWithValues)
                        )}
                      </span>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverBody>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          {Object.entries(countedBuckets).map(
                            ([key, value]) => (
                              <>
                                <div style={{ textAlign: "right" }}>
                                  {currencyFormatter.format(Number(key) - 500)}{" "}
                                  - {currencyFormatter.format(Number(key))}
                                </div>
                                <div
                                  style={{ textAlign: "left", paddingLeft: 10 }}
                                >
                                  {value}
                                </div>
                              </>
                            )
                          )}
                        </div>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover> */}
                </div>
              );
            },
            accessor: "cogs",
            Cell: ({
              cell,
              row,
            }: CellProps<ProjectRow, number | undefined>) => {
              return (
                <div>
                  <Popover isLazy trigger="hover">
                    <PopoverTrigger>
                      <span>
                        {cell.value &&
                          "$" + currencyFormatter.format(cell.value)}
                      </span>
                    </PopoverTrigger>
                    <PopoverContent
                      minW={{ base: "100%", lg: "max-content" }}
                      backgroundColor="rgb(45, 55, 72)"
                      color="white"
                    >
                      <PopoverBody padding={0}>
                        <ProjectInvoicesTooltip
                          projectId={row.original.id}
                          handleClickReassign={async (invoice) => {
                            const response = await promptSelectProject();
                            if (response === cancelledSymbol) {
                              return;
                            }
                            if (response.selectedProjectId === row.original.id)
                              return;
                            updateSingleInvoice({
                              variables: {
                                invoiceId: invoice.id,
                                invoiceData: {
                                  project: response.selectedProjectId
                                    ? {
                                        connect: {
                                          id: response.selectedProjectId,
                                        },
                                      }
                                    : invoice.project
                                    ? // if invoice is already not connected to a project, attempting to disconnect will result in
                                      // Unhandled Rejection (Error): The records for relation `projects_invoices` between the `invoices` and `projects` models are not connected.
                                      {
                                        disconnect: true,
                                      }
                                    : undefined,
                                  notes: response.notes
                                    ? {
                                        set: response.notes,
                                      }
                                    : undefined,
                                },
                              },
                              update: (cache, { data }) => {
                                cache.modify({
                                  id: cache.identify({
                                    __typename: "Project",
                                    id: row.original.id,
                                  }),
                                  fields: {
                                    invoices(
                                      existingInvoices = [],
                                      { readField }
                                    ) {
                                      return existingInvoices.filter(
                                        (
                                          invoiceRef: GraphQLTypes.InvoiceFragmentFragment
                                        ) =>
                                          readField("id", invoiceRef) !==
                                          invoice.id
                                      );
                                    },
                                  },
                                });
                              },
                            });
                          }}
                        />
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>

                  {/* <Popover>
                    <PopoverTrigger>
                      <span>
                        {cell.value && "$" + currencyFormatter.format(cell.value)}
                      </span>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <ProjectInvoices projectId={row.original.id} />
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover> */}
                </div>
              );
            },
          },
          {
            Header: ({
              setHiddenColumns,
              visibleColumns,
            }: HeaderProps<DataColumn>) => {
              const isExpanded = visibleColumns.some(
                (x) => x.id === `initiallyHidden:netSalesSplit`
              );
              return (
                <span>
                  Net Sales
                  {!isExpanded && (
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        setHiddenColumns((oldHiddenColumns) => {
                          return _.difference(oldHiddenColumns, [
                            `initiallyHidden:netSalesSplit`,
                          ]);
                        });
                      }}
                      className="net-sales-expander"
                    >
                      ↔️
                    </span>
                  )}
                </span>
              );
            },
            Header2: ({ filteredRows }) => {
              let sum = 0;
              let entriesWithValues = 0;
              filteredRows.forEach((row) => {
                if (row.original.netIncome) {
                  sum += row.original.netIncome;
                  entriesWithValues += 1;
                }
              });

              // const countedBuckets = _.omit(
              //   _.countBy(
              //     filteredRows,
              //     (row) => Math.floor((row.original.netIncome ?? 0) / 5000) * 5000
              //   ),
              //   "0"
              // );

              return (
                <div
                  css={css`
                    width: max-content;
                    text-align: right;
                  `}
                >
                  Σ = ${currencyFormatter.format(sum)}
                  <br />μ = $
                  {currencyFormatter.format(
                    Math.floor(sum / entriesWithValues)
                  )}
                  {/* <Popover isLazy trigger="hover">
                    <PopoverTrigger>
                      <span>
                        μ = $
                        {currencyFormatter.format(
                          Math.floor(sum / entriesWithValues)
                        )}
                      </span>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverBody>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          {Object.entries(countedBuckets).map(
                            ([key, value]) => (
                              <>
                                <div style={{ textAlign: "right" }}>
                                  {currencyFormatter.format(Number(key) - 5000)}{" "}
                                  - {currencyFormatter.format(Number(key))}
                                </div>
                                <div
                                  style={{ textAlign: "left", paddingLeft: 10 }}
                                >
                                  {value}
                                </div>
                              </>
                            )
                          )}
                        </div>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover> */}
                </div>
              );
            },
            accessor: "netIncome",
            Cell: ({ cell }: CellProps<ProjectRow, number | undefined>) =>
              cell.value && "$" + currencyFormatter.format(cell.value),
          },

          {
            id: "initiallyHidden:netSalesSplit",
            accessor: (x) => {
              if (
                peopleData.data?.me?.pods
                  .map((x) => x.senior_brand_maven_lead)
                  .includes(x.senior_brand_maven_lead)
              ) {
                // user belongs to pod that owns this project
                return Math.round(
                  (x.netIncome * (100 - x.sales_split_ratio)) / 100
                );
              } else if (
                x.sales_split_pod &&
                peopleData.data?.me?.pods
                  .map((x) => x.id)
                  .includes(x.sales_split_pod.id)
              ) {
                // user belongs to pod that is splitting this project
                return Math.round((x.netIncome * x.sales_split_ratio) / 100);
              } else {
                return 0;
              }
            },
            Header: ({
              setHiddenColumns,
              visibleColumns,
            }: HeaderProps<DataColumn>) => {
              const isExpanded = visibleColumns.some(
                (x) => x.id === `initiallyHidden:netSalesSplit`
              );
              return (
                <span style={{ minWidth: "8em" }}>
                  Net Sales
                  <br />
                  (Based on Split)
                  {isExpanded && (
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        setHiddenColumns((oldHiddenColumns) => {
                          return [
                            ...oldHiddenColumns,
                            `initiallyHidden:netSalesSplit`,
                          ];
                        });
                      }}
                      className="net-sales-collapser"
                    >
                      ↦↤
                    </span>
                  )}
                </span>
              );
            },
            Header2: ({ filteredRows }) => {
              let sum = 0;
              let entriesWithValues = 0;
              filteredRows.forEach((row) => {
                if (row.values["initiallyHidden:netSalesSplit"] !== null) {
                  sum += row.values["initiallyHidden:netSalesSplit"];
                  entriesWithValues += 1;
                }
              });
              return (
                <div
                  css={css`
                    width: max-content;
                    text-align: right;
                  `}
                >
                  Σ = ${currencyFormatter.format(sum)}
                  <br />μ = $
                  {currencyFormatter.format(
                    Math.floor(sum / entriesWithValues)
                  )}
                </div>
              );
            },

            Cell: ({ cell }: CellProps<ProjectRow, number | undefined>) =>
              cell.value && "$" + currencyFormatter.format(cell.value),
          },

          {
            Header: "Estimate",
            accessor: (row) => row.associated_estimate?.name ?? "",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Cell: ({
              row,
            }: CellProps<
              ProjectRow,
              NonNullable<ProjectRow["associated_estimate"]>["name"]
            >) => {
              return (
                <PopoverSelect
                  supressEditIcon={true}
                  displayText={
                    <TextTruncateRevealOnHover
                      css={css`
                        max-width: 14rem;
                        .group-hover-show {
                          text-decoration: underline;
                        }
                      `}
                      text={
                        <>
                          {row.original?.associated_estimate?.name ?? ""}

                          <EditIcon
                            className="cell-hover-visible"
                            style={{ marginLeft: "0.5em" }}
                          />
                        </>
                      }
                    />
                  }
                  options={[
                    ...(estimates.map((estimate) => {
                      // const budgetUnaccountedFor =
                      //   (estimate?.po_subtotal ?? 0) -
                      //   // @ts-expect-error
                      //   (estimate?.projects ?? 0);
                      const budgetUnaccountedFor = "unknown";
                      return {
                        value: estimate.id,
                        label: `${estimate.po_number} ${estimate.name}`,
                        po_number: estimate.po_number,
                        name: estimate.name,
                        budgetUnaccountedFor,
                        // jsxNode: (
                        //   <>
                        //     PO:{estimate.po_number} <br />
                        //     {estimate.name}
                        //     <br />
                        //     Budget unaccounted for: ${currencyFormatter.format(budgetUnaccountedFor)}
                        //   </>
                        // ),
                      };
                    }) ?? []),
                    {
                      value: null,
                      label: <em>None</em>,
                    },
                  ]}
                  selectProps={{
                    // @ts-ignore
                    formatOptionLabel: ({
                      name,
                      po_number,
                      budgetUnaccountedFor,
                    }) => {
                      return (
                        <>
                          PO: {po_number} <br />
                          {name}
                          <br />
                          Budget unaccounted for: $
                          {/* {currencyFormatter.format(budgetUnaccountedFor)} */}
                        </>
                      );
                    },
                  }}
                  onSubmit={async (e, selectedOption) => {
                    // console.log({ selectedOption });
                    await updateOneProject({
                      variables: {
                        updateOneProjectData: {
                          associated_estimate: selectedOption?.value
                            ? {
                                connect: {
                                  id: selectedOption?.value,
                                },
                              }
                            : {
                                disconnect: true,
                              },
                        },
                        updateOneProjectWhere: {
                          id: row.original.id,
                        },
                      },
                    });
                  }}
                />
              );
            },
          },

          {
            Header: "Budget unaccounted for",
            Cell: ({ row }: CellProps<ProjectRow, number>) => {
              if (!row.original?.associated_estimate) return null;

              const budgetUnaccountedFor =
                (row.original?.associated_estimate?.po_subtotal ?? 0) -
                (_.sumBy(
                  row.original?.associated_estimate?.projects,
                  (x) => x.budget ?? 0
                ) ?? 0);
              return (
                <Tooltip
                  maxWidth="40vw"
                  label={
                    <EstimateBudgetUnaccountedForTooltipContent
                      estimateId={row.original?.associated_estimate.id}
                    />
                  }
                >
                  <span>
                    <Money
                      value={budgetUnaccountedFor}
                      title={`PO subtotal: ${row.original?.associated_estimate?.po_subtotal}`}
                    />
                  </span>
                </Tooltip>
              );
            },
          },
        ],
      },

      {
        Header: "Blended rate (actual)",
        Header2: ({ filteredRows }) => {
          const sum = _.sumBy(
            filteredRows,
            (row) => row.original.blendedRateBasedOnNetIncome
          );

          return (
            <div
              css={css`
                width: max-content;
                text-align: right;
              `}
            >
              μ = $
              {hourFormatter.format(
                Math.floor(
                  sum /
                    filteredRows.filter(
                      (row) => row.original.blendedRateBasedOnNetIncome
                    ).length
                )
              )}
            </div>
          );
        },
        accessor: "blendedRateBasedOnNetIncome",
        Cell: ({ cell }) => {
          if (!cell.value) return null;
          return <Money value={Math.round(cell.value)} />;
        },
      },
      {
        Header: "Tracking VS Budget",
        Header2: ({ filteredRows }) => {
          const sum = _.sumBy(
            filteredRows,
            (row) => row.original.netIncomeMinusSubtotal
          );

          return (
            <div
              css={css`
                width: max-content;
                text-align: right;
              `}
            >
              Σ = ${currencyFormatter.format(Math.floor(sum))}
              <br />μ = $
              {hourFormatter.format(Math.floor(sum / filteredRows.length))}
            </div>
          );
        },
        accessor: "netIncomeMinusSubtotal",
        Cell: ({ cell }) => cell.value && <Money value={cell.value} />,
      },

      {
        Header: "Subtotal based on hours",
        accessor: "subtotalByTimeEntries",
        Cell: ({ cell }) => "$" + currencyFormatter.format(cell.value),
        Header2: ({ filteredRows }) => {
          let sum = 0;
          let entriesWithValues = 0;
          filteredRows.forEach((row) => {
            const val = row.original.subtotalByTimeEntries;
            if (val) {
              sum += val;
              entriesWithValues += 1;
            }
          });

          return (
            <div
              css={css`
                width: max-content;
                text-align: right;
              `}
            >
              Σ = ${currencyFormatter.format(sum)}
              <br />μ = $
              {hourFormatter.format(Math.floor(sum / entriesWithValues))}
            </div>
          );
        },
      },

      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: ({ cell }) => {
          if (!cell.value) return null;
          return new Date(cell.value).toLocaleDateString();
        },
        filter: "dateIsSameYear",
        Filter: ({
          column,
        }: FilterProps<ProjectRow> & {
          column: UseFiltersColumnProps<ProjectRow>;
        }) => (
          <div
            css={css`
              min-width: 6rem;
            `}
          >
            <Select
              isMulti={false}
              closeMenuOnSelect={true}
              defaultValue={column.filterValue}
              onChange={(selectedOption) => {
                column.setFilter(selectedOption.value ?? undefined);
              }}
              options={[
                {
                  value: undefined,
                  label: "All",
                },
                ...startDateYears.map((year) => ({
                  value: year.toString(),
                  label: year.toString(),
                })),
              ]}
            />
          </div>
        ),
      },
      {
        Header: "End Date",
        accessor: "end_date",
        Cell: ({ cell }) => {
          if (!cell.value) return null;
          return new Date(cell.value).toLocaleDateString();
        },
        filter: "dateIsSameYear",
        Filter: ({
          column,
        }: FilterProps<ProjectRow> & {
          column: UseFiltersColumnProps<ProjectRow>;
        }) => (
          <div
            css={css`
              min-width: 6rem;
            `}
          >
            <Select
              isMulti={false}
              closeMenuOnSelect={true}
              defaultValue={column.filterValue}
              onChange={(selectedOption) => {
                column.setFilter(selectedOption.value ?? undefined);
              }}
              options={[
                {
                  value: undefined,
                  label: "All",
                },
                ...endDateYears.map((year) => ({
                  value: year.toString(),
                  label: year.toString(),
                })),
              ]}
            />
          </div>
        ),
      },
      {
        Header: "Business Days",
        accessor: "businessDays",
        Cell: ({ cell, row }) => {
          if (!cell.value) return null;
          return <div>{cell.value}</div>;
        },
      },

      {
        Header: "Category",
        accessor: (x) => x.category?.name ?? NONE,
        Cell: ({ cell }: CellProps<ProjectRow>) => {
          const projectId = cell.row.original.id;
          return (
            <PopoverSelect
              supressEditIcon={true}
              displayText={
                <TextTruncateRevealOnHover
                  css={css`
                    max-width: 14rem;
                    .group-hover-show {
                      text-decoration: underline;
                    }
                  `}
                  text={
                    <>
                      <span
                        style={{
                          borderRadius: "50%",
                          display: "inline-block",
                          width: 10,
                          height: 10,
                          marginRight: 4,
                          backgroundColor: cell.row?.original?.category?.color
                            ? `#${cell.row.original.category.color}`
                            : undefined,
                        }}
                      />
                      {cell.value ?? ""}

                      <EditIcon
                        className="cell-hover-visible"
                        style={{ marginLeft: "0.5em" }}
                      />
                    </>
                  }
                />
              }
              options={[
                ...((_.orderBy(categories, (x) => x.name) ?? []).map(
                  (category) => {
                    return {
                      value: category.id,
                      label: category.name,
                      name: category.name,
                      categoryColor: category.color,
                    };
                  }
                ) ?? []),
              ]}
              selectProps={{
                // @ts-ignore
                formatOptionLabel: ({ name, categoryColor }) => {
                  return (
                    <span>
                      <span
                        style={{
                          borderRadius: "50%",
                          display: "inline-block",
                          width: 10,
                          height: 10,
                          marginRight: 4,
                          backgroundColor: categoryColor
                            ? `#${categoryColor}`
                            : undefined,
                        }}
                      />
                      {name}
                    </span>
                  );
                },
              }}
              onSubmit={async (e, selectedOption) => {
                // console.log({ selectedOption });
                if (!selectedOption?.value) {
                  return;
                }
                await upsertProjectCategoryId({
                  variables: {
                    upsertProjectCategoryIdProjectId: projectId,
                    upsertProjectCategoryIdProjectCategoryId:
                      selectedOption.value,
                  },
                });
              }}
            />
          );
        },
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
      },

      ..._.orderBy(Object.entries(peopleGroupedByTitleCategory), ([key]) => {
        // const orderedTitleCategories = Object.keys(titlesRates);
        return [
          "Senior Client Services Director",
          "Client Services Director",
          "Senior Brand Maven",
          "Brand Maven",
          "Project Manager",
          "Associate Project Manager",
          "APM assistant",
          "Associate Creative Director",
          "Associate Brand Maven",
          "art director",
          "Art Director",
          "Senior Designer",
          "Designer - Senior",
          "Designer",
          "Production Designer",
          "Senior Developer",
          "Technical Lead",
          "Developer",
          "Junior Developer",
          "Coder",
          "Junior coder",
          "Medical Writer",
          "Proof Reader",
          "Proofreader",
          "No Title",
        ].indexOf(key);
      }).flatMap(([titleCategory, people]) => {
        const titleAggregateColumns: Array<DataColumn> = [
          {
            id: `grouped: Hours Logged ${titleCategory}`,
            Header: `Hours logged`,
            sortDescFirst: true,
            accessor: (x) => {
              const sum =
                _.sumBy(
                  x.timeEntriesGroupedByPeopleTitleCategory[titleCategory],
                  (entry) => entry.minutes
                ) / 60;

              return sum === 0 ? sum : Math.round(sum * 10) / 10;
            },
            Header2: ({ filteredRows }) => {
              let sum = 0;
              let entriesWithValues = 0;
              filteredRows.forEach((row) => {
                const val = _.sumBy(
                  row.original.timeEntriesGroupedByPeopleTitleCategory[
                    titleCategory
                  ],
                  (entry) => entry.minutes
                );
                if (val) {
                  sum += val;
                  entriesWithValues += 1;
                }
              });

              return (
                <div
                  css={css`
                    width: max-content;
                    text-align: right;
                  `}
                >
                  Σ ={hourFormatter.format(sum / 60)}
                  <br />μ ={hourFormatter.format(sum / 60 / entriesWithValues)}
                </div>
              );
            },
          },
          {
            id: `grouped: Subtotal ${titleCategory}`,
            Header: `Subtotal`,
            sortDescFirst: true,
            Header2: ({ filteredRows }) => {
              let sumMoney = 0;
              let entriesWithValues = 0;
              filteredRows.forEach((row) => {
                const val = _.sumBy(
                  row.original.timeEntriesGroupedByPeopleTitleCategory[
                    titleCategory
                  ],
                  (entry) => entry.minutes * (entry.rateInCents / 60 / 100)
                );
                if (val) {
                  sumMoney += val;
                  entriesWithValues += 1;
                }
              });
              const mean = Math.floor(sumMoney / entriesWithValues);

              return (
                <div
                  css={css`
                    width: max-content;
                    text-align: right;
                  `}
                >
                  Σ = ${currencyFormatter.format(Math.floor(sumMoney))}
                  <br />μ = ${currencyFormatter.format(mean)}
                </div>
              );
            },
          },
        ];

        const titleCategoryHeader = ({
          toggleHideColumn,
          setHiddenColumns,
          visibleColumns,
        }: HeaderProps<DataColumn>) => {
          const isExpanded = !visibleColumns.some(
            (x) => x.id === `grouped: Hours Logged ${titleCategory}`
          );
          return (
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                width: "100%",
                alignItems: "center",
              }}
              onClick={() => {
                setHiddenColumns((oldHiddenColumns) => {
                  return _.xor(oldHiddenColumns, [
                    `grouped: Hours Logged ${titleCategory}`,
                    `grouped: Subtotal ${titleCategory}`,
                    ...people.flatMap((person) => [
                      `individual: Hours Logged ${person.id}`,
                      `individual: Subtotal ${person.id}`,
                    ]),
                  ]);
                });
              }}
            >
              {titleCategory}
              <span style={{ marginLeft: "auto" }}>
                {isExpanded ? "↦↤" : "↔️"}
              </span>
            </div>
          );
        };

        return {
          id: titleCategory,
          Header: titleCategoryHeader,
          columns: [
            {
              Header: `Aggregate ${titleCategory}s`,
              columns: titleAggregateColumns,
            },
            ...people.flatMap((person) => {
              const columns: Array<DataColumn> = [
                {
                  id: `individual: Hours Logged ${person.id}`,
                  Header: `Hours logged`,
                  sortDescFirst: true,
                  accessor: (x) => {
                    const sum =
                      _.sumBy(
                        x.timeEntriesGroupedByPersonId[person.id],
                        (entry) => entry.minutes
                      ) / 60;

                    return sum === 0 ? sum : Math.round(sum * 100) / 100;
                  },
                  Header2: ({ filteredRows }) => {
                    let sum = 0;
                    let entriesWithValues = 0;
                    filteredRows.forEach((row) => {
                      const val = _.sumBy(
                        row.original.timeEntriesGroupedByPersonId[person.id],
                        (entry) => entry.minutes
                      );
                      if (val) {
                        sum += val;
                        entriesWithValues += 1;
                      }
                    });

                    const mean = sum / entriesWithValues / 60;

                    return (
                      <div
                        css={css`
                          width: max-content;
                          text-align: right;
                        `}
                      >
                        Σ = {hourFormatter.format(sum / 60)}
                        <br />μ ={hourFormatter.format(mean)}
                      </div>
                    );
                  },
                },
                {
                  id: `individual: Subtotal ${person.id}`,
                  Header: `Subtotal`,
                  sortDescFirst: true,
                  accessor: (x) => {
                    const totalMinutes = _.sumBy(
                      x.timeEntriesGroupedByPersonId[person.id],
                      (entry) => entry.minutes
                    );
                    if (!totalMinutes) return 0;

                    const subtotal =
                      _.sumBy(
                        x.timeEntriesGroupedByPersonId[person.id],
                        (entry) => entry.minutes * (entry.rateInCents / 60 / 100)
                      );

                    if (
                      totalMinutes &&
                      (!x.timeEntriesGroupedByPersonId[person.id] ||
                        !x.timeEntriesGroupedByPersonId[person.id].find(
                          (x) => x.rateInCents
                        ))
                    ) {
                      return (
                        <Tooltip
                          label={`Can't find project rates for ${person.first_name} ${person.last_name}, try resyncing.`}
                        >
                          missing rates
                        </Tooltip>
                      );
                    }

                    if (!subtotal) {
                      return 0;
                    }
                    return (
                      <Tooltip
                        label={Object.entries(
                          _.groupBy(
                            x.timeEntriesGroupedByPersonId[person.id],
                            (x) => x.rateInCents
                          )
                        ).map(([rateInCents, entries]) => (
                          <div>
                            {_.sum(entries.map((x) => x.minutes))} minutes at $
                            {currencyFormatter.format(
                              Number(rateInCents) / 100
                            )} per hour
                          </div>
                        ))}
                      >
                        <span>
                          <Money value={Math.round(subtotal)} />
                        </span>
                      </Tooltip>
                    );
                  },
                  Header2: ({ filteredRows }) => {
                    const personRate = person.rate / 100;
                    if (!personRate) return null;

                    let sum = 0;
                    let entriesWithValues = 0;
                    filteredRows.forEach((row) => {
                      const val = _.sumBy(
                        row.original.timeEntriesGroupedByPersonId[person.id],
                        (entry) => entry.minutes * (entry.rateInCents / 60 / 100)
                      );
                      if (val) {
                        sum += val;
                        entriesWithValues += 1;
                      }
                    });
                    const mean = Math.floor(sum / entriesWithValues);

                    return (
                      <div
                        css={css`
                          width: max-content;
                        `}
                      >
                        Σ = $
                        {currencyFormatter.format(
                          Math.floor(sum)
                        )}
                        <br />μ = ${currencyFormatter.format(mean)}
                      </div>
                    );
                  },
                },
              ];
              return {
                id: `hours_subtotal:${person.first_name}:${person.last_name}`,
                Header: `${person.first_name} ${person.last_name} $${
                  person.rate / 100
                }`,
                columns,
              };
            }),
          ],
        };
      }),

      // {
      //   Header: "Created At",
      //   accessor: "created_at",
      //   Cell: ({ cell }) => {
      //     if (!cell.value) return null;
      //     const createdAt = new Date(cell.value);
      //     return createdAt.toLocaleDateString();
      //   },
      // },
    ];
    return columns.map((columnDefinition) => ({
      ...columnDefinition,
      ...("sortDescFirst" in columnDefinition ? null : { sortDescFirst: true }),
    }));
  }, [
    categories,
    estimates,
    peopleGroupedByTitleCategory,
    postProcessedProjectRows,
    refetchProjects,
    uniqueOwners,
    updateOneProject,
    upsertProjectBudget,
    upsertProjectCategoryId,
    upsertProjectClientBrandManager,
    upsertProjectNotes,
    upsertProjectNotes2,
    upsertProjectSbm,
    upsertProjectStatus,
    promptSelectProject,
    updateSingleInvoice,
  ]);

  if (networkStatus === NetworkStatus.loading) {
    return <FullSpinner />;
  }

  if (!projects) {
    console.error("no projects", projects, data);
    return null;
  }

  return (
    <div css={tw`p-6`}>
      {errors.length > 0 && (
        <div
          css={css`
            background-color: red;
            color: white;
            padding: 1rem;
            border-radius: 0.5rem;
          `}
        >
          {errors.map((error) => (
            <div>{error}</div>
          ))}
        </div>
      )}
      <ProjectsTable
        // @ts-expect-error
        columnDefinitions={columns}
        // @ts-expect-error
        projectRows={postProcessedProjectRows}
        isSyncing={networkStatus === NetworkStatus.refetch || isSyncing}
      />
      {modal}
    </div>
  );
};

// Define a default UI for filtering
// function TextColumnFilter({
//   column: { filterValue, preFilteredRows, setFilter },
// }) {
//   const count = preFilteredRows.length;

//   return (
//     <input
//       value={filterValue || ""}
//       onChange={(e) => {
//         setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
//       }}
//       placeholder={`Search ${count} records...`}
//     />
//   );
// }

// // // a unique option from a list
// function SelectColumnFilter<D extends object>({
//   column: { filterValue, setFilter, preFilteredRows, id },
// }: FilterProps<D> & {
//   column: UseFiltersColumnProps<D> & { id: string };
// }) {
//   // Calculate the options for filtering using the preFilteredRows
//   const options = React.useMemo(() => {
//     const options = new Set<D[]>();
//     preFilteredRows.forEach((row) => {
//       options.add(row.values[id]);
//     });
//     return Array.from(options);
//   }, [id, preFilteredRows]);

//   return (
//     <select
//       value={filterValue}
//       onClick={(e) => e.stopPropagation()}
//       onChange={(e) => {
//         setFilter(e.target.value || undefined);
//       }}
//     >
//       <option value="">All</option>
//       {options.map((option, i) => (
//         // @ts-expect-error
//         <option key={i} value={option}>
//           {option ?? "(None)"} (
//           {preFilteredRows.filter((row) => row.values[id] === option).length})
//         </option>
//       ))}
//     </select>
//   );
// }

export const LastSynced = ({
  projectId,
  dateSynced,
  onSyncStart,
  onSyncComplete,
}: {
  projectId: string;
  dateSynced: Date;
  onSyncStart: () => void;
  onSyncComplete: () => void;
}) => {
  const [pullProjectDetailsFromTeamwork, { loading, error }] = useMutation<
    PullProjectDetailsFromTeamworkMutation,
    PullProjectDetailsFromTeamworkMutationVariables
  >(gql`
    mutation PullProjectDetailsFromTeamwork($projectIds: [ID!]!) {
      pullProjectDetailsFromTeamwork(projectIds: $projectIds) {
        id
        ...ProjectFragment
      }
    }
    ${ProjectFragment}
  `);
  if (error)
    return (
      <div
        css={css`
          width: 100%;
          text-align: center;
        `}
      >
        {`${error}`}
      </div>
    );
  if (loading)
    return (
      <div
        css={css`
          width: 100%;
          text-align: center;
        `}
      >
        loading
      </div>
    );

  return (
    <div
      css={css`
        position: relative;
        min-width: 7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        & .hide-on-hover {
          opacity: 1;
        }

        & .show-on-hover {
          opacity: 0;
        }

        &:hover {
          & .hide-on-hover {
            opacity: 0;
          }

          & .show-on-hover {
            opacity: 1;
          }
        }
      `}
    >
      <span className="hide-on-hover">
        {DateTime.fromJSDate(dateSynced).toRelative()}
      </span>
      <div
        className="show-on-hover"
        css={css`
          position: absolute;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
        `}
      >
        <Button
          size="small"
          variant="ghost"
          css={css`
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            width: 100%;
          `}
          onClick={async () => {
            onSyncStart();
            await pullProjectDetailsFromTeamwork({
              variables: {
                projectIds: [projectId],
              },
            });

            await onSyncComplete();
          }}
        >
          sync
        </Button>
      </div>
    </div>
  );
};

export const ProjectInvoicesTooltip = ({
  projectId,
  handleClickReassign,
}: {
  projectId: string;
  handleClickReassign: (invoice: GraphQLTypes.InvoiceFragmentFragment) => void;
}) => {
  const { accessToken } = useAuthContext();
  const { data, loading } = useQuery<
    ProjectInvoicesQuery,
    ProjectInvoicesQueryVariables
  >(
    gql`
      query ProjectInvoices($projectId: String!) {
        projects(where: { id: { equals: $projectId } }) {
          id
          invoices {
            id
            ...InvoiceFragment
          }
        }
      }
      ${InvoiceFragment}
    `,
    {
      variables: {
        projectId,
      },
    }
  );

  if (loading) return <FullSpinner />;

  return (
    <table
      css={css`
        & > tbody > tr > td {
          padding-left: 1rem;
          padding-right: 1rem;
          padding-top: 0.2rem;
          padding-bottom: 0.2rem;
        }
        & > tbody > tr:nth-child(even) {
          background-color: rgba(255, 255, 255, 0.1);
        }
      `}
    >
      <tbody>
        {data?.projects[0].invoices.map((invoice) => (
          <tr>
            <td>{invoice.expense_type}</td>
            <td>{invoice.payee_name}</td>
            <td align="right">${(invoice.amount_in_cents / 100).toFixed(2)}</td>
            <td>{invoice.payee_name}</td>
            <td>
              {!!invoice.files?.length && (
                <Popover isLazy trigger="hover">
                  <PopoverTrigger>
                    <Button
                      size="xs"
                      css={tw`flex flex-row items-center gap-5 my-2 text-black`}
                      onClick={() => {
                        if (!accessToken) throw new Error(`No access token`);
                        invoice.files.forEach(async (file) => {
                          const url = await getBlobUrlFromInvoiceFileName(
                            file.fileName,
                            accessToken
                          );
                          window.open(url);
                        });
                      }}
                    >
                      View invoice
                    </Button>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent>
                      <PopoverBody>
                        {invoice.files.map((file) => (
                          <SupplierInvoiceFile
                            fileName={file.fileName}
                            fileType={file.contentType}
                          />
                        ))}
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              )}
            </td>
            <td>
              <Button
                size="xs"
                css={tw`flex flex-row items-center gap-5 my-2 text-black`}
                onClick={async () => {
                  handleClickReassign(invoice);
                }}
              >
                Reassign
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
