import { useState, useEffect, DragEvent } from "react";
import { Role, Team } from "../../codegen/graphql-types";
import { Button } from "@chakra-ui/react";
import { DragHandleIcon, DeleteIcon } from "@chakra-ui/icons";
import { PopoverInput } from "../../helpers/PopoverInput";
import tw from "twin.macro";

type Item = Role | Team;

interface DragSortListProps<T extends Item> {
  items: T[];
  onUpdateOrder: (updatedItems: T[]) => void;
  onUpdateName: (id: string, newName: string) => void;
  onDelete: (id: string) => void;
  type: "Role" | "Team";
}

const DragSortList = <T extends Item>({
  items,
  onUpdateOrder,
  onUpdateName,
  onDelete,
  type,
}: DragSortListProps<T>) => {
  const [list, setList] = useState<T[]>([...items]);

  // Keep the local state synchronized with the parent items prop
  useEffect(() => {
    setList([...items]);
  }, [items]);

  const handleDragStart = (event: DragEvent<HTMLDivElement>, index: number) => {
    event.dataTransfer.setData("text/plain", index.toString());
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault(); // Allows dropping
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>, dropIndex: number) => {
    event.preventDefault();
    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"), 10);

    if (dragIndex === dropIndex) return;

    const updatedList = [...list];
    const [draggedItem] = updatedList.splice(dragIndex, 1);
    updatedList.splice(dropIndex, 0, draggedItem);

    // Update order property
    const reorderedList = updatedList.map((item, index) => ({
      ...item,
      order: index,
    }));

    setList(reorderedList); // Update local state
    onUpdateOrder(reorderedList); // Inform the parent about the updated order
  };

  const handleNameChange = (id: string, newName: string) => {
    const updatedList = list.map((item) =>
      item.id === id
        ? type === "Role"
          ? { ...item, roleTitle: newName } // Update Role's name
          : { ...item, teamName: newName } // Update Team's name
        : item
    );

    setList(updatedList); // Update local state
    onUpdateName(id, newName); // Inform the parent about the name change
  };

  return (
    <section style={{ listStyle: "none", padding: 0, margin: 0, width: "max-content" }}>
      {list
        .sort((a, b) => a.order - b.order) // Sort by order before rendering
        .map((item, index) => {
          const label =
            type === "Role" ? (item as Role).roleTitle : (item as Team).teamName;
          return (
            <div key={item.id} css={tw`bg-gray-100 my-1 p-1 gap-x-2 border border-gray-300 rounded flex items-center justify-between`}>
              <div
                draggable
                onDragStart={(event) => handleDragStart(event, index)}
                onDragOver={handleDragOver}
                onDrop={(event) => handleDrop(event, index)}
                css={tw`cursor-move flex items-center gap-x-2`}
              >
                <DragHandleIcon />
                {label}
              </div>
              <div draggable={false} css={tw`flex items-center gap-x-1`}>
                <PopoverInput
                  initialValue={""}
                  onSubmit={(_, newValue, { onClose }) => {
                    handleNameChange(item.id, newValue); // Update the name
                    onClose();
                  }}
                />
                <Button
                  size="xs"
                  onClick={() => {
                    // Delete the item
                    onDelete(item.id);
                  }}
                >
                  <DeleteIcon />
                </Button>
              </div>
            </div>
          );
        })}
    </section>
  );
};

export default DragSortList;
