
import tw, { css } from "twin.macro";
// import test response json
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { useProjectRows } from "../../../helpers/useProjectRows";
import {
  sumNetSalesSplit,
  sumProfitability,
  filterByCompletedCancelled,
  filterByProfitability,
  filterByInProgressOnHold,
  projectsGroupedByClientName,
  filterProjectsByEndDateYear,
  processClientRows
} from "../../../helpers/brandAnalysis";
import type { ClientNetSalesRow } from "../../../helpers/brandAnalysis";
import { FullSpinner } from "../../../FullSpinner";
import { Stack, Text, Tooltip } from "@chakra-ui/react";
import { 
  GetProjectsForBrandAnalysisDocument, 
  GetProjectsForBrandAnalysisQuery, 
  GetProjectsForBrandAnalysisQueryVariables,
  GetProjectsForBrandAnalysisQueryResult,
  // get multiple forecasts
  GetClientAnnualRevenueForecastsDocument,
  GetClientAnnualRevenueForecastsQuery,
  GetClientAnnualRevenueForecastsQueryVariables,
  GetClientAnnualRevenueForecastsQueryResult,
} from "../../../codegen/graphql-types";
import { 
  AddClientAnnualRevenueForecast, // single add
  AddClientAnnualRevenueForecasts, // multiple add
  ClientAnnualRevenueForecasts // read, update, delete
} from "./ClientAnnualRevenueForecasts";
import { BrandPerformance } from "./BrandPerformance";
import { Banner } from "../../../components/Banner";
import { SlicedPieChart } from "../../../components/SlicedPieChart";

const currencyFormatter = new Intl.NumberFormat("en-US", {});

const SortArrow = ({ onSortToggle }: { onSortToggle: () => void; }) => {
  return (
    <button
      onClick={onSortToggle}
      css={[tw`absolute top-0 right-0 mr-1 text-sm`]}
    >↕️
    </button>
  )
};

const SortableColumnHeader = ({ label, headerCss, handleSort }: { label: string, headerCss: any[], handleSort: () => void }) => {
  return (
    <th css={headerCss}>
      <div css={tw`relative px-4 w-full h-full`}>
        <span>{label}</span><SortArrow onSortToggle={handleSort} />
      </div>
    </th>
  )
}

// Table body component for Brand Analysis data table
// This is where all headings and rows are rendered
const AnalysisBody = ({
  clientRows,
  yearInFocus,
  userId,
  podId,
  clientIds,
  sumNetSalesSplitAsOfCurrentMonthInPreviousYear,
  sumNetSalesSplitInPreviousYear,
  completedProjectsWithTimeTrackingInPreviousYear,
  sumNetSalesSplitInFocusYear,
  sumInProgressOnHoldInFocusYear,
  completedProjectsWithTimeTrackingInFocusYear,
  handleSort,
  forecastResult,
}: {
  clientRows: ClientNetSalesRow[],
  yearInFocus: number,
  userId: string,
  podId: string,
  clientIds: string[],
  sumNetSalesSplitAsOfCurrentMonthInPreviousYear: number,
  sumNetSalesSplitInPreviousYear: number,
  completedProjectsWithTimeTrackingInPreviousYear: any[],
  sumNetSalesSplitInFocusYear: number,
  sumInProgressOnHoldInFocusYear: number,
  completedProjectsWithTimeTrackingInFocusYear: any[],
  handleSort: (column: string) => void,
  forecastResult: GetClientAnnualRevenueForecastsQueryResult
}) => {

  const currentMonth = new Date().toLocaleString('default', { month: 'short' });
  // styles for column headers
  const topHeadingCss = [tw`font-bold sticky top-0 z-10`, css`background: aliceblue;`];
  const headingCss = [tw`sticky top-20`, css`background: aliceblue;`];
  const subheadingCss = [tw`font-bold sticky top-32`, css`background: aliceblue;`];

  const clientIdsThatDoNotHaveForecasts = clientIds.filter((clientId) => {
    return !forecastResult.data?.clientAnnualRevenueForecasts.some((forecast) => forecast.clientId === clientId);
  });

  return (
    <tbody>
      <tr
        css={css`
          background: aliceblue;
          border-right: solid 1px #ddd;
          border-left: solid 1px #ddd;
        `}
      > 
        {/* ---------- ---------- ---------- [PREVIOUS YEAR] ---------- ---------- ---------- */}
        <th css={topHeadingCss}>&nbsp;</th>
        <th colSpan={3} css={topHeadingCss}>
          <span css={css`color:red;`}>{yearInFocus - 1}</span><br/>
          <span css={tw`text-lg`}>
            Net Sales<br/>
            <span css={tw`text-sm`}>(based on split)</span>
          </span>
        </th>
        {/* ---------- ---------- ---------- [CURRENT YEAR] ---------- ---------- ---------- */}
        <td css={tw`bg-black`} style={{ padding: "4px" }}></td>
        <th colSpan={7} css={topHeadingCss}>
          <span css={css`color:red;`}>{yearInFocus}</span><br/>
          <span css={tw`text-lg`}>
            Net Sales<br/>
            <span css={tw`text-sm`}>(based on split)</span>
          </span>
        </th>
      </tr>
      <tr
        css={css`
          background: aliceblue;
          border-right: solid 1px #ddd;
          border-left: solid 1px #ddd;
          th {
            vertical-align: middle;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }
        `}
      >
        {/* ---------- ---------- ---------- [PREVIOUS YEAR] ---------- ---------- ---------- */}
        <th css={[tw`font-bold sticky top-20`, css`background: aliceblue;`]}>&nbsp;</th>
        <th colSpan={2} css={headingCss}>
          Completed + Cancelled
        </th>
        <th css={headingCss}>
          <Tooltip 
            label={<Stack>
              <Text>(Time tracking + Completed + Cancelled)</Text>
            </Stack>}
          >
            Profitability
          </Tooltip>
        </th>
        {/* ---------- ---------- ---------- [CURRENT YEAR] ---------- ---------- ---------- */}
        <td css={tw`bg-black`} style={{ padding: "4px" }}></td>
        <th css={headingCss}>
          Completed + Cancelled
        </th>
        <th css={headingCss}>
          <Tooltip 
            label={<Stack>
              <Text>(Time tracking + Completed + Cancelled)</Text>
            </Stack>}
          >
            Profitability
          </Tooltip>
        </th>
        <td css={tw`bg-black`} style={{ padding: "4px" }}></td>
        <th css={headingCss}>
          In Progress +<br/>
          On Hold
        </th>
        <td css={tw`bg-black`} style={{ padding: "4px" }}></td>
        <th colSpan={2} css={headingCss}>
          Pod Projections Per Brand
          {!forecastResult.data || clientIdsThatDoNotHaveForecasts.length > 0 && (
            <div>
              <AddClientAnnualRevenueForecasts 
                clientIds={clientIdsThatDoNotHaveForecasts} 
                year={yearInFocus}
                userId={userId}
                podId={podId}
                refetchForecasts={forecastResult.refetch} 
              />
            </div>
          )}
        </th>
      </tr>
      <tr
        css={css`
          background: aliceblue;
          border-right: solid 1px #ddd;
          border-left: solid 1px #ddd;
          th {
            vertical-align: middle;
          }
        `}
      >
        {/* ---------- ---------- ---------- [PREVIOUS YEAR] ---------- ---------- ---------- */}
        <th css={subheadingCss}>&nbsp;</th>
        {/* As of current month previous year Completed + Cancelled */}
        <SortableColumnHeader label={`As of ${currentMonth}`} headerCss={subheadingCss} 
          handleSort={() => { handleSort("sumNetSalesSplitAsOfCurrentMonthInPreviousYear") }}
        />
        {/* Previous Year Completed + Cancelled */}
        <SortableColumnHeader label={`Total Year`} headerCss={subheadingCss}
          handleSort={() => { handleSort("sumNetSalesSplitInPreviousYear") }}
        />
        {/* Previous Year Profitability (as above + Time-Tracking) */}
        <SortableColumnHeader label={`Total Year`} headerCss={subheadingCss}
          handleSort={() => { handleSort("sumProfitabilityInPreviousYear") }}
        />
        {/* ---------- ---------- ---------- [CURRENT YEAR] ---------- ---------- ---------- */}
        <td css={tw`bg-black`} style={{ padding: "4px" }}></td>
        {/* Focus Year to date Completed + Cancelled */}
        <SortableColumnHeader label={`Total YTD`} headerCss={subheadingCss}
          handleSort={() => { handleSort("sumNetSalesSplitInFocusYear") }}
        />
        {/* Focus Year to date Profitability (+ Time tracking) */}
        <SortableColumnHeader label={`As of Today`} headerCss={subheadingCss}
          handleSort={() => { handleSort("sumProfitabilityInFocusYear") }}
        />
        <td css={tw`bg-black`} style={{ padding: "4px" }}></td>
        {/* Focus Year to date In Progress + On Hold */}
        <SortableColumnHeader label={`As of Today`} headerCss={subheadingCss}
          handleSort={() => { handleSort("sumInProgressOnHoldInFocusYear") }}
        />
        <td css={tw`bg-black`} style={{ padding: "4px" }}></td>
        {/* Low and High Forecast for Brand */}
        <SortableColumnHeader label={`Low`} headerCss={subheadingCss}
          handleSort={() => { handleSort("lowForecast") }}
        />
        <SortableColumnHeader label={`High`} headerCss={subheadingCss}
          handleSort={() => { handleSort("highForecast") }}
        />
      </tr>
      <tr>
        {/* ---------- ---------- ---------- [PREVIOUS YEAR] ---------- ---------- ---------- */}
        <th css={tw`text-left`}>TOTAL</th>
        {/* Sum of Projects netSalesSplit (Completed+Cancelled) as of current month in prev year */}
        <th css={tw`bg-yellow-200`}>
          ${currencyFormatter.format(sumNetSalesSplitAsOfCurrentMonthInPreviousYear)}
        </th>
        {/* Sum of Projects netSalesSplit (Completed+Cancelled) for prev year */}
        <th css={tw`bg-yellow-200`}>
          ${currencyFormatter.format(sumNetSalesSplitInPreviousYear)}
        </th>
        {/* Total Profitability (Completed+Cancelled+Time tracking) for prev year */}
        <th css={tw`bg-yellow-200`}>
          <span>μ ={sumProfitability(completedProjectsWithTimeTrackingInPreviousYear)}</span>
        </th>
        {/* ---------- ---------- ---------- [CURRENT YEAR] ---------- ---------- ---------- */}
        <td css={tw`bg-black`} style={{ padding: "4px" }}></td>
        {/* Sum of Projects netSalesSplit (Completed+Cancelled) for year in focus */}
        <th css={tw`bg-yellow-200`}>
          ${currencyFormatter.format(sumNetSalesSplitInFocusYear)}
        </th>
        {/* Sum of Profitability (Completed+Cancelled+Time tracking) for year in focus */}
        <th css={tw`bg-yellow-200`}>
          <span>μ ={sumProfitability(completedProjectsWithTimeTrackingInFocusYear)}</span>
        </th>
        <td css={tw`bg-black`} style={{ padding: "4px" }}></td>
        {/* Sum of Projects netSalesSplit (In Progress + On Hold): this project query has no end-date variable */}
        <th css={tw`bg-yellow-200`}>
          ${currencyFormatter.format(sumInProgressOnHoldInFocusYear)}
        </th>
        <td css={tw`bg-black`} style={{ padding: "4px" }}></td>
        {/* ---------- ---------- [BRAND FORECAST TOTALS] ---------- ---------- */}
        {forecastResult.loading && <th css={tw`bg-yellow-200`} colSpan={2}>Fetching sum...</th>}
        {forecastResult.error && <th css={tw`bg-yellow-200`} colSpan={2}>{forecastResult.error.message}</th>}
        {forecastResult.data && forecastResult.data?.clientAnnualRevenueForecasts.length > 0 && (
          <>
            <th css={tw`bg-yellow-200 font-bold`}>
              ${currencyFormatter.format(forecastResult.data?.clientAnnualRevenueForecasts.reduce((acc, forecast) => acc + forecast.lowForecastDollars, 0))}
            </th>
            <th css={tw`bg-yellow-200 font-bold flex flex-col gap-y-3 items-center`}>
              ${currencyFormatter.format(forecastResult.data?.clientAnnualRevenueForecasts.reduce((acc, forecast) => acc + forecast.highForecastDollars, 0))}
            </th>
          </>
        )}
        {forecastResult.data && forecastResult.data?.clientAnnualRevenueForecasts.length === 0 && (
          <>
            <th css={tw`bg-yellow-200 font-bold`}>
              ${currencyFormatter.format(forecastResult.data?.clientAnnualRevenueForecasts.reduce((acc, forecast) => acc + forecast.lowForecastDollars, 0))}
            </th>
            <th css={tw`bg-yellow-200 font-bold flex flex-col gap-y-3 items-center`}>
              ${currencyFormatter.format(forecastResult.data?.clientAnnualRevenueForecasts.reduce((acc, forecast) => acc + forecast.highForecastDollars, 0))}
            </th>
          </>
        )}
      </tr>
      {/* ---------- ---------- ---------- ---------- [COMPANY/BRAND ROWS] ---------- ---------- ---------- ---------- */}
      {clientRows.map(({
          clientName,
          clientId,
          sumNetSalesSplitAsOfCurrentMonthInPreviousYear,
          sumNetSalesSplitInPreviousYear,
          sumProfitabilityInPreviousYear,
          sumNetSalesSplitInFocusYear,
          sumInProgressOnHoldInFocusYear,
          sumProfitabilityInFocusYear
        }) => {
          return (
            <tr key={clientName}>
              {/* ---------- ---------- ---------- [PREVIOUS YEAR] ---------- ---------- ---------- */}
              <th css={css`
                text-align: left;
                max-width: 200px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              `}>{clientName}</th>
              {/* Sum of Projects netSalesSplit (Completed+Cancelled) as of current month in prev year */}
              <th>
                ${currencyFormatter.format(sumNetSalesSplitAsOfCurrentMonthInPreviousYear)}
              </th>
              {/* Sum of Projects netSalesSplit (Completed+Cancelled) for prev year */}
              <th>
                ${currencyFormatter.format(sumNetSalesSplitInPreviousYear)}
              </th>
              {/* Total Profitability (Completed+Cancelled+Time tracking) for prev year */}
              <th>
                <span>μ ={sumProfitabilityInPreviousYear}</span>
              </th>
              {/* ---------- ---------- ---------- [CURRENT YEAR] ---------- ---------- ---------- */}
              <td css={tw`bg-black`} style={{ padding: "4px" }}></td>
              {/* Sum of Projects netSalesSplit (Completed+Cancelled) for year in focus */}
              <th>
                ${currencyFormatter.format(sumNetSalesSplitInFocusYear)}
              </th>
              {/* Sum of Profitability (Completed+Cancelled+Time tracking) for year in focus */}
              <th>
                <span>μ ={sumProfitabilityInFocusYear}</span>
              </th>
              <td css={tw`bg-black`} style={{ padding: "4px" }}></td>
              {/* Sum of Projects netSalesSplit (In Progress + On Hold): this project query has no end-date variable */}
              <th>
                ${currencyFormatter.format(sumInProgressOnHoldInFocusYear)}
              </th>
              <td css={tw`bg-black`} style={{ padding: "4px" }}></td>
              {/* ---------- ---------- [BRAND FORECAST Low/High] ---------- ---------- */}
              {forecastResult.data && !forecastResult.data.clientAnnualRevenueForecasts.find((forecast) => forecast.clientId === clientId) && (
                <AddClientAnnualRevenueForecast
                  clientId={clientId}
                  year={yearInFocus}
                  userId={userId}
                  podId={podId}
                  refetchForecasts={forecastResult.refetch}
                />
              )}
              {forecastResult.data && forecastResult.data.clientAnnualRevenueForecasts.length > 0 && forecastResult.data.clientAnnualRevenueForecasts.map(
                (forecast: any) => {
                  if (forecast.clientId === clientId) {
                    return (
                      <ClientAnnualRevenueForecasts
                        key={forecast.id}
                        forecast={forecast}
                        refetchForecasts={forecastResult.refetch}
                        userId={userId}
                        podId={podId}
                        clientId={clientId}
                        year={yearInFocus}
                      />
                    )
                  }
                }
              )}
            </tr>
          );
        }
      )}
    </tbody>
  );
}

export const BrandAnalysis = ({ 
  yearInFocus, 
  peopleData,
  podId,
  podSeniorBrandMavenLead
}: { 
  yearInFocus: number,
  peopleData: any,
  podId: string,
  podSeniorBrandMavenLead: string
}) => {
  // sort column
  const [sortColumn, setSortColumn] = useState<string>("sumNetSalesSplitInFocusYear");
  // sort direction
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const toggleSortDirection = () => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  }

  // Event callback that takes the string name of the property to sort by
  const handleSort = (column: string) => {
    if (column === sortColumn) {
      toggleSortDirection();
    } else {
      setSortColumn(column);
      setSortDirection("desc");
    }
  }

  // .sort callback function
  const sortComparator = (a: any, b: any) => {
    if (sortDirection === "asc") {
      return a[sortColumn] - b[sortColumn];
    } else {
      return b[sortColumn] - a[sortColumn];
    }
  }

  // Query for projects of the current user's pod since 2020 that have a project_status of "Time tracking", "Completed", "Cancelled", "On Hold", or "In Progress"
  const result: GetProjectsForBrandAnalysisQueryResult = useQuery<GetProjectsForBrandAnalysisQuery, GetProjectsForBrandAnalysisQueryVariables>(GetProjectsForBrandAnalysisDocument, {
    variables: {
      "where": {
        "AND": [
          {
            "OR": [
              { "start_date": { "gt": "2020-01-01T04:00:00.000Z" } },
              { "start_date": { "equals": null } }
            ]
          },
          {
            "OR": [
              { "senior_brand_maven_lead": { "in": [podSeniorBrandMavenLead] } },
              { "sales_split_pod_id": { "in": [podId] } },
              { "senior_brand_maven_lead": { "equals": null } }
            ] 
          },
          { "is_deleted": { "equals": false } },
          { "project_status": { "in": ["Time tracking", "Completed", "Cancelled", "On Hold", "In Progress"] } }
        ]
      }
    }
  });

  const postProcessedProjectRows = useProjectRows(result);

  // console.log("BrandAnalysis -> result", result);
  // console.log("BrandAnalysis -> postProcessedProjectRows", postProcessedProjectRows);

  // const projectsWithNoSBMLead = postProcessedProjectRows.filter((project) => !project.senior_brand_maven_lead);
  
  // if (projectsWithNoSBMLead.length > 0) {
  //   console.log("BrandAnalysis -> Projects with NULL SBM Lead: ", projectsWithNoSBMLead);
  // } else {
  //   console.log("All projects have a senior_brand_maven_lead");
  // }

  // const projectsWithNoStartDate = postProcessedProjectRows.filter((project) => !project.start_date);

  // if (projectsWithNoStartDate.length > 0) {
  //   console.log("BrandAnalysis -> Projects with NO start date: ", projectsWithNoStartDate);
  // } else {
  //   console.log("All projects have a start_date");
  // }

  // Projects with end_date in Previous Year
  const projectsWithEndDateInPreviousYear = filterProjectsByEndDateYear(postProcessedProjectRows, yearInFocus - 1);
  // Projects with end_date in Year In Focus
  const projectsWithEndDateInFocusYear = filterProjectsByEndDateYear(postProcessedProjectRows, yearInFocus);

  // Projects Completed + Cancelled (Previous Year)
  const completedProjectsInPreviousYear = projectsWithEndDateInPreviousYear.filter(filterByCompletedCancelled);
  // Projects Completed + Cancelled (Year in Focus)
  const completedProjectsInFocusYear = projectsWithEndDateInFocusYear.filter(filterByCompletedCancelled);
  const projects = completedProjectsInFocusYear.map((project) => ({
    project_name: project.name,
    id: project.id,
    budget: project.netIncome,
    net_sales_split: project.netSalesSplit,
    senior_brand_maven_lead: project.senior_brand_maven_lead,
    start_date: project.start_date,
    end_date: project.end_date,
    sales_split_pod: project.sales_split_pod,
    sales_split_ratio: project.sales_split_ratio,
  }));
  console.log("BrandAnalysis -> Completed or Cancelled projects in focus year: ", projects);
  console.log("BrandAnalysis -> Number of Completed or Cancelled projects in focus year: ", projects.length);

  // Projects Completed + Cancelled + Time tracking (Previous Year)
  const completedProjectsWithTimeTrackingInPreviousYear = projectsWithEndDateInPreviousYear.filter(filterByProfitability);
  // Projects Completed + Cancelled + Time tracking (Year in Focus)
  const completedProjectsWithTimeTrackingInFocusYear = projectsWithEndDateInFocusYear.filter(filterByProfitability);

  // Projects In Progress + On Hold (Year in Focus)
  const inProgressOnHoldProjects = postProcessedProjectRows.filter(filterByInProgressOnHold);

  // Group projects by client name
  const allProjectsByClientName = projectsGroupedByClientName(postProcessedProjectRows);

  // Sum of netSalesSplit for projects in previous year + focus year
  const sumNetSalesSplitInPreviousYear = sumNetSalesSplit(completedProjectsInPreviousYear);
  const sumNetSalesSplitInFocusYear = sumNetSalesSplit(completedProjectsInFocusYear);

  console.log("BrandAnalysis -> sumNetSalesSplitInFocusYear: ", sumNetSalesSplitInFocusYear);

  // Sum of netSalesSplit for In progress and On hold projects in focus year
  const sumInProgressOnHoldInFocusYear = sumNetSalesSplit(inProgressOnHoldProjects);

  // Sum of Projects netSalesSplit (Completed+Cancelled) as of current month in prev year
  const sumNetSalesSplitAsOfCurrentMonthInPreviousYear = sumNetSalesSplit(
    projectsWithEndDateInPreviousYear
      .filter(filterByCompletedCancelled)
      .filter((project: any) => {
        const endDate = new Date(project.end_date);
        const currentMonth = new Date().getMonth();
        return endDate.getMonth() <= currentMonth;
      })
  );

  // Map and filter Brand/Client data for Brand Analysis table rows
  const clientRows: ClientNetSalesRow[] = processClientRows(allProjectsByClientName, yearInFocus);

  const clientIds: string[] = clientRows.map((client) => client.clientId);  
  const userId: string = peopleData.data?.me?.id ?? "";

  // Query the brands annual low and high revenue forecasts (only for brands that have sales / have a row in the brand analysis table)
  const forecastsResult: GetClientAnnualRevenueForecastsQueryResult = useQuery<
    GetClientAnnualRevenueForecastsQuery,
    GetClientAnnualRevenueForecastsQueryVariables
    >(GetClientAnnualRevenueForecastsDocument, {
      variables: { 
        clientIds,
        podId,
        year: yearInFocus
      },
  });

  if (forecastsResult.loading) {
    return (
      <div css={tw`m-5 w-full justify-center items-center`}>
        <FullSpinner />
      </div>
    )
  }

  if (forecastsResult.error) {
    return (
      <div css={tw`m-5 w-full justify-center items-center`}>
        Error loading brand analysis: {forecastsResult.error.message}
      </div>
    )
  }

  // add the low and high forecast value for each client to the clientRows array
  const clientRowsWithForecasts = clientRows.map((client) => {
    const forecast = forecastsResult.data?.clientAnnualRevenueForecasts.find((forecast) => forecast.clientId === client.clientId);
    return {
      ...client,
      lowForecast: forecast ? forecast.lowForecastDollars : 0,
      highForecast: forecast ? forecast.highForecastDollars : 0,
    }
  });

  return (
    <section css={tw`flex flex-col items-center w-full`}>
      <table
        css={[
          tw`ml-12 mt-4`,
          css`
            width: max-content;
            text-align: center;
            td,
            th {
              border: 1px solid #ccc;
              padding: 0.25rem;
            }
            th {
              font-weight: bold;
            }
          `,
        ]}
      >
        {postProcessedProjectRows.length > 0 && (
          <AnalysisBody 
            clientRows={clientRowsWithForecasts.sort(sortComparator)}
            clientIds={clientIds}
            yearInFocus={yearInFocus}
            userId={userId}
            podId={podId}
            sumNetSalesSplitAsOfCurrentMonthInPreviousYear={sumNetSalesSplitAsOfCurrentMonthInPreviousYear}
            sumNetSalesSplitInPreviousYear={sumNetSalesSplitInPreviousYear}
            completedProjectsWithTimeTrackingInPreviousYear={completedProjectsWithTimeTrackingInPreviousYear}
            sumNetSalesSplitInFocusYear={sumNetSalesSplitInFocusYear}
            sumInProgressOnHoldInFocusYear={sumInProgressOnHoldInFocusYear}
            completedProjectsWithTimeTrackingInFocusYear={completedProjectsWithTimeTrackingInFocusYear}
            handleSort={handleSort}
            forecastResult={forecastsResult}
          />
        )}
      </table>

      <section css={tw`ml-12 w-full`}>
        <Banner>
          <h2 css={tw`font-bold text-xl`}>Brand Contribution to Pod</h2>
        </Banner>

        <div css={tw`w-full my-3 justify-center flex flex-row`}>
          <div css={tw`flex flex-col gap-y-3`}>
            <h3 css={tw`font-bold text-lg text-center`}>{yearInFocus - 1}<br/>(Completed + Cancelled)</h3>
            <SlicedPieChart
              data={clientRows.map(client => ({ name: client.clientName, value: client.sumNetSalesSplitInPreviousYear}))}
              wholePie={sumNetSalesSplitInPreviousYear}
            />
          </div>
          <div css={tw`flex flex-col gap-y-3`}>
            <h3 css={tw`font-bold text-lg text-center`}>{yearInFocus}<br/>(Completed + Cancelled)</h3>
            <SlicedPieChart
              data={clientRows.map(client => ({ name: client.clientName, value: client.sumNetSalesSplitInFocusYear}))}
              wholePie={sumNetSalesSplitInFocusYear}
            />
          </div>
        </div>
        
        <Banner>
          <h2 css={tw`font-bold text-xl`}>Brand Performance</h2>
        </Banner>
        
        {forecastsResult.loading && (
          <section css={tw`mb-20`}>
            <FullSpinner />
          </section>
        )}
        {forecastsResult.data && (
          <BrandPerformance
            yearInFocus={yearInFocus}
            clientRows={clientRows}
            forecastsResultData={forecastsResult.data.clientAnnualRevenueForecasts}
          />
        )}
        {forecastsResult.error && (
          <section css={tw`mb-20`}>
            Error loading brand forecasts: {forecastsResult.error}
          </section>
        )}
      </section>
    </section>
  )
}