/**
 * This is the component that shows the status of the project.
 * (Top right corner of the .numbers deck)
 */
import { useContext, useMemo } from "react";
import tw from "twin.macro";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  Column,
  Table,
  ColumnDef,
  getFilteredRowModel,
  getPaginationRowModel,
  RowData,
} from "@tanstack/react-table";
import { ProjectPricingDetailsDataResponse } from "../helpers/usePricingData";
import { PricingContext } from "../helpers/usePricing";

interface ProjectStatusEntryProps {
  data: {
    name: string;
    value: string;
  }[];
  keyName: string;
}

const Entry = ({ data, keyName }: ProjectStatusEntryProps) => {
  const columns = useMemo(
    () => [
      {
        header: "Name",
        id: "name",
        accessorFn: (row: any) => row.name,
        cell: (props: any) => {
          const uniqueKey = `${keyName}-name-${props.row.index}`;
          return (
            <div
              key={uniqueKey}
              css={tw`font-bold px-2 py-1`}
            >
              {props.row.original.name}
            </div>
          );
        },
      },
      {
        header: "Value",
        id: "value",
        accessorFn: (row: any) => row.value,
        cell: (props: any) => {
          const uniqueKey = `${keyName}-value-${props.row.index}`;
          return (
            <div
              key={uniqueKey}
              css={tw`px-2 py-1`}
            >
              {props.row.original.value}
            </div>
          );
        },
      },
    ],
    [keyName] // Add keyName as a dependency to recompute when it changes
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  return (
    <table css={tw`border-collapse border border-gray-200`}>
      {/* <thead>
        {table.getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={`${keyName}-header-${header.id}`}
                  colSpan={header.colSpan}
                  css={tw`border border-gray-200 px-2 py-1`}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          );
        })}
      </thead> */}
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={`${keyName}-row-${row.index}`}>
            {row.getVisibleCells().map((cell) => {
              const cellKey = `${keyName}-${cell.column.id}-${row.index}`;
              const isNameCell = cell.column.id === "name";
              const defaultTw = tw`border border-gray-200 px-2 py-1`;
              const cellTw = isNameCell ? tw`bg-blue-100 border border-gray-200 px-2 py-1` : defaultTw;
              return (
                <td key={cellKey} css={cellTw}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const ProjectDetails = ({
  projectDetails
}: {
  projectDetails: ProjectPricingDetailsDataResponse;
}) => {
  const { formatNumber } = useContext(PricingContext);
  const projectStatus = projectDetails?.project_status;
  const projectBudget = projectDetails?.budget;
  return (
    <div css={tw`justify-self-end`}>
      <div css={tw`flex gap-2`}>
        <div>
          <Entry
            keyName="project-status"
            data={[
              {
                name: "Project Status",
                value: projectStatus ?? "",
              },
            ]}
          />
        </div>
        <div>
          <Entry
            keyName="project-budget"
            data={[
              {
                name: "Actual Project Budget",
                value: `$${formatNumber(projectBudget ?? 0)}`,
              },
            ]}
          />
        </div>
        {/* <div>
          <Entry
            keyName="profitability"
            data={[
              {
                name: "Relative profitability",
                value: "0",
              },
              {
                name: "Margin as sold",
                value: "0",
              },
              {
                name: "Margin as delivered",
                value: "0",
              },
            ]}
          />
        </div> */}
      </div>
    </div>
  );
};

export default ProjectDetails;
