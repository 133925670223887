import { useMemo, useContext } from "react";
import { PricingContext, TimeEntryWithRate } from "../../helpers/usePricing";
import TasklistDetailsTeamTable from "./TasklistDetailsTeamTable";
import COGSTable from "./COGSTable";
import { ProjectedExpense, ProjectedTime } from "../../codegen/graphql-types";
import type { PricingAppData, ProjectPricingCalculatedData } from "../../helpers/usePricing";
import { usePricingDataContext } from "../../helpers/usePricingData";
import type { MeanProjectRatesByTeamAndRole } from "../../helpers/getMeanProjectRatesByTeamAndRole";

// Interface for Tasklist Details Table
interface ProjectedTimesGroupedByTeam {
  [key: string]: ProjectedTime[];
}

interface TransposedDataForTasklistTableEntry {
  id: number;
  "total-cost": number;
  "total-time": number;
  [key: string]: number;
}

/**
 * This is the component that shows the detail of each tasklist selected from the tasklist table
 *
 * This component will consist of two parts:
 * 1. Comment
 * 2. Table for details
 * 2a. Table Per team
 * 2b. Table for totals
 */

import tw from "twin.macro";
import TasklistComments from "./TasklistComments";

const TasklistDetails = ({
  pricingAppData,
  meanProjectRates,
  projectedTimes,
}: {
  pricingAppData: PricingAppData;
  meanProjectRates: MeanProjectRatesByTeamAndRole[];
  projectedTimes: ProjectedTime[];
}) => {
  const { projectDetails } = usePricingDataContext();
  const {
    currentDetailView,
    selectedTasklist,
    uniqueTeams,
    peopleData,
    convertTasklistDetailsRowDataByTeam,
    transformedTimeEntries,
  } = useContext(PricingContext);

  // Merge all the sorted data into one array with the order of [budget, actual, total]
  const convertRowData = (
    projectedTimesGroupedByTeam: ProjectedTimesGroupedByTeam, 
    selectedTasklistCalculatedData: ProjectPricingCalculatedData
  ) => {
    const newData:
      | [
          TransposedDataForTasklistTableEntry,
          TransposedDataForTasklistTableEntry,
          TransposedDataForTasklistTableEntry
        ]
      | [{}, {}, {}] = [{}, {}, {}];

    Object.entries(projectedTimesGroupedByTeam).forEach(([teamName, teamData]) => {
      if (!teamName || !transformedTimeEntries) return;
      const [budget, actual, total] = convertTasklistDetailsRowDataByTeam(
        teamName,
        teamData,
        meanProjectRates,
        transformedTimeEntries
      );
      newData[0] = {
        ...newData[0],
        ...budget,
        ...selectedTasklistCalculatedData.budget,
      };
      newData[1] = {
        ...newData[1],
        ...actual,
        ...selectedTasklistCalculatedData.actual,
      };
      newData[2] = {
        ...newData[2],
        ...total,
        ...selectedTasklistCalculatedData.total,
      };
    });

    return newData;
  };

  const projectedTimesGroupedByTeam = useMemo(() => {
    const reducedData: ProjectedTimesGroupedByTeam = projectedTimes
      .filter((projectedTime) => Number(projectedTime.tasklist_id) === selectedTasklist?.id)
      .reduce(
      (acc: ProjectedTimesGroupedByTeam, curr: ProjectedTime) => {
        const teamId = curr.team_id;
        const teamName = uniqueTeams.find((team) => team.id === teamId)?.teamName;
        if (!teamName) {
          return acc;
        }
        if (!acc[teamName]) {
          acc[teamName] = [];
        }
        acc[teamName].push(curr);
        return acc;
      },
      {}
    );
    return reducedData;
  }, [selectedTasklist, projectedTimes, uniqueTeams]);

  const transposedTasklistDetailsData = useMemo(() => {
    const selectedTasklistCalculatedData = pricingAppData.db.project_pricings.find(
      (projectPricing) => projectPricing.tasklist_id === selectedTasklist?.id
    )
    if (!selectedTasklistCalculatedData) {
      return [{}, {}, {}];
    }
    return convertRowData(projectedTimesGroupedByTeam, selectedTasklistCalculatedData.CALCULATED);
  }, [projectedTimesGroupedByTeam, selectedTasklist, transformedTimeEntries]);


  const timeEntriesWithNoRoleOrTeam = transformedTimeEntries?.filter(
    (entry: TimeEntryWithRate) => !entry.user_role_id || !entry.user_team_id
  ) ?? [];

  if (timeEntriesWithNoRoleOrTeam.length > 0) {
    console.log("timeEntriesWithNoRoleOrTeam", timeEntriesWithNoRoleOrTeam);
  }

  const projectedExpenses: ProjectedExpense[] = projectDetails?.project_pricing?.projected_expenses || [];

  return (
    <div css={tw`flex gap-4 w-full`}>
      <div css={tw`flex contents`}>
        {currentDetailView === "tasklist" && selectedTasklist && projectDetails && projectDetails.project_pricing && (
          <TasklistComments
            projectId={projectDetails.id}
            projectPricing={projectDetails.project_pricing}
            tasklistId={selectedTasklist.id}
            tasklistName={selectedTasklist.name}
          />
        )}
        {currentDetailView === "cogs" && projectDetails && projectDetails.project_pricing && (
          <TasklistComments
            projectId={projectDetails.id}
            projectPricing={projectDetails.project_pricing}
            tasklistId={null}
            tasklistName={"COGS"}
          />
        )}
      </div>
      <div css={tw`flex flex-col gap-8 pr-8 w-full`}>
        {currentDetailView === "tasklist" && selectedTasklist && (
          <div css={tw`flex gap-8`}>
            <TasklistDetailsTeamTable
              groupedData={projectedTimesGroupedByTeam}
              rowData={transposedTasklistDetailsData}
              transformedTimeEntries={transformedTimeEntries}
              selectedTasklist={selectedTasklist}
            />
          </div>
        )}

        {currentDetailView === "cogs" && pricingAppData.db.cogs && projectDetails && projectDetails.project_pricing && (
          <COGSTable 
            cogs={pricingAppData.db.cogs}
            projectedExpenses={projectedExpenses}
            projectId={projectDetails.id}
            projectPricingId={projectDetails.project_pricing.id}
          />
        )}

        {/* <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Role ID</th>
              <th>Team ID</th>
              <th>Minutes</th>
              <th>Tasklist ID</th>
              <th>Project ID</th>
            </tr>
          </thead>
          <tbody>
            {projectedTimes.map((projectedTime) => (
              <tr key={projectedTime.id}>
                <td>{projectedTime.id}</td>
                <td>{projectedTime.role_id}</td>
                <td>{projectedTime.team_id}</td>
                <td>{projectedTime.projected_minutes}</td>
                <td>{projectedTime.tasklist_id}</td>
              </tr>
            ))}
          </tbody>
        </table> */}

        {/* Time Logs with no Role or Team */}
        {timeEntriesWithNoRoleOrTeam.length > 0 && (
          <div css={tw`flex flex-col gap-4`}>
            <h2 css={tw`text-lg font-bold text-red-500`}>Time Logs with no Role or Team</h2>
            <div css={tw`text-sm`}>
              These people with these time logs have no role or team assigned. A Dashboard admin needs to assign a Team and Role to these users to have these time entries reflected in the Budget Estimator.
            </div>
            <div css={tw`flex flex-col gap-2`}>
              {timeEntriesWithNoRoleOrTeam
                .map((entry: TimeEntryWithRate) => {
                const person = peopleData?.find((person) => person.id === entry.user_id);

                if (!person) {
                  return null;
                }

                const personName = `${person.first_name} ${person.last_name}`;

                return (
                  <div key={entry.id} css={tw`flex gap-2`}>
                    <div css={tw`text-sm`}>
                      Person: {personName}
                    </div>
                    <div css={tw`text-sm`}>Minutes: {entry.minutes}</div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TasklistDetails;
