import { useParams } from "react-router-dom";
import tw, { css } from "twin.macro";
import { useContext } from "react";
import { PricingContext } from "../helpers/usePricing";
import ProjectTasklistPricingTable from "./ProjectTasklistPricingTable";
import ProjectDetails from "./ProjectDetails";
import TasklistDetails from "./tasklist/TasklistDetails";
// import ComparableSelector from "./ComparableSelector";
import { Button, IconButton } from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import {
  CreateProjectPricingDocument,
  CreateProjectPricingProjectedTimesDocument,
  DeleteProjectPricingDocument,
} from "../codegen/graphql-types";
import { usePricingDataContext } from "../helpers/usePricingData";
import { ProjectPricingSelector } from "./ProjectPricingSelector";
import { usePeopleContext } from "../helpers/usePeopleContext";
import { DeleteIcon } from "@chakra-ui/icons";
import { excludeTasklistsByName } from "../helpers/useProjectTasklists";

export const ProjectPricingById = () => {
  const { data: peopleData } = usePeopleContext();
  const me = peopleData?.me;

  const { projectId } = useParams<{ projectId: string }>();

  const {
    refetchProjectPricing,
    projectDetails,
    projectDetailsLoading,
    projectDetailsError,
  } = usePricingDataContext();

  const { tasklists, meanProjectRates, pricingAppData } = useContext(
    PricingContext
  );

  const [createProjectPricing, createProjectPricingMutation] = useMutation(
    CreateProjectPricingDocument
  );
  const [deleteProjectPricing] = useMutation(DeleteProjectPricingDocument);
  const [createProjectedTimes, createProjectedTimesMutation] = useMutation(
    CreateProjectPricingProjectedTimesDocument
  );

  if (projectDetailsLoading) {
    return <p>Fetching project data from database...</p>;
  }

  if (projectDetailsError || !projectDetails) {
    console.log("Error fetching project pricing data:", projectDetailsError);
    return (
      <div css={tw`p-4 flex flex-col gap-y-3`}>
        <strong css={tw`text-red-400`}>
          Error fetching project pricing data: {projectDetailsError?.message}
        </strong>
        <ProjectPricingSelector projectDetails={projectDetails} />
      </div>
    );
  }

  if (tasklists.length === 0) {
    return (
      <div css={tw`p-4 flex flex-col gap-y-3`}>
        <strong css={tw`text-red-400`}>
          No tasklists found for project. This project could be archived, has no
          tasklists, or there is an issue with the network connection to
          Teamwork. Check the job in Teamwork by clicking the link below.
        </strong>
        <ProjectPricingSelector projectDetails={projectDetails} />
      </div>
    );
  }

  const budgetEstimatorTasklistId =
    tasklists.find((tasklist) => tasklist.name === "Budget Estimator")?.id ?? 0;

  // if there's no project pricing data for the project, return a button to create it
  if (!projectDetails || !projectDetails.project_pricing) {
    return (
      <div css={tw`p-4 w-full flex justify-center`}>
        <Button
          css={tw`mt-4 mx-auto`}
          onClick={async () => {
            if (!me) {
              alert("Could not start project pricing because the logged in User data was not found.");
              return;
            }
            if (!meanProjectRates) {
              alert(
                "Could not start project pricing because no Project Rates were found."
              );
              return;
            }
            const uniqueTeamIds = Array.from(
              new Set(meanProjectRates.map((rate) => rate.team.id))
            );
            const uniqueRoleIds = Array.from(
              new Set(meanProjectRates.map((rate) => rate.role.id))
            );
            // Create project pricing data
            try {
              await createProjectPricing({
                variables: {
                  projectId: projectId,
                  tasklistId: budgetEstimatorTasklistId,
                  creatorId: me.id,
                  roleIds: uniqueRoleIds,
                  teamIds: uniqueTeamIds,
                  isArchived: projectDetails.is_archived,
                },
              });
              // Refetch project pricing data
              refetchProjectPricing();
            } catch (error) {
              console.error("Error creating project pricing data:", error);
            }
          }}
        >
          {createProjectPricingMutation.loading
            ? "Initializing Project Pricing..."
            : "Start Project Pricing"}
        </Button>
      </div>
    );
  }

  const projectPricing = projectDetails.project_pricing;
  const projectedTimes = projectPricing.projected_times;
  const projectPricingId = projectPricing.id?.toString() ?? "";
  const tasklistIds = tasklists
    .filter(excludeTasklistsByName)
    .map((tasklist) => tasklist.id?.toString());

  // Filter tasklist ids that exist in tasklists that are not in projected times
  const missingTasklistIdsInProjectedTimes = tasklistIds.filter(
    (tasklistId) => !projectedTimes.some((pt) => pt.tasklist_id === tasklistId)
  );

  if (missingTasklistIdsInProjectedTimes.length > 0) {
    console.log(
      "Missing Projected times in these tasklist names:",
      tasklists.find((tasklist) => tasklist.id === parseInt(missingTasklistIdsInProjectedTimes[0]))?.name,
    );
  }

  return (
    <div
      css={[
        tw`px-8 py-4 h-full`,
        css`
          max-width: 100vw;
        `,
      ]}
    >
      
      <section css={tw`grid grid-cols-2`}>
        <ProjectPricingSelector projectDetails={projectDetails} />

        {projectDetails && <ProjectDetails projectDetails={projectDetails} />}
      </section>

      <hr css={tw`my-6 border border-gray-800`} />

      {/* Project Pricing Table */}
      <div>
        {pricingAppData &&
          tasklists &&
          tasklists.length > 0 &&
          meanProjectRates &&
          meanProjectRates.length > 0 && (
            <div key={projectId}>
              <ProjectTasklistPricingTable
                // The SSOT pricingAppData state is passed as a PROP because we need to ensure...
                // it is always defined with calculated values before this child component renders
                pricingAppData={pricingAppData}
              />
            </div>
          )}
      </div>

      {/* Tasklist Details Table */}
      <hr css={tw`my-6 border border-gray-800`} />

      {/* Create projected times for missing tasklist ids */}
      {projectedTimes.length > 0 &&
        missingTasklistIdsInProjectedTimes.length > 0 &&
        meanProjectRates && (
          <div css={tw`px-0 mb-4`}>
            <Button
              onClick={async () => {
                // Create projected times
                const uniqueTeamIds = Array.from(
                  new Set(meanProjectRates.map((rate) => rate.team.id))
                );
                const uniqueRoleIds = Array.from(
                  new Set(meanProjectRates.map((rate) => rate.role.id))
                );
                // Create projected times for tasklist_id = 0
                try {
                  await createProjectedTimes({
                    variables: {
                      projectId: projectId,
                      projectPricingId: projectPricingId,
                      tasklistIds: missingTasklistIdsInProjectedTimes,
                      teamIds: uniqueTeamIds,
                      roleIds: uniqueRoleIds,
                      minutes: 0,
                    },
                  });
                  // Refetch project pricing data
                  refetchProjectPricing();
                } catch (error) {
                  console.error(
                    "Error creating projected times for tasklist_id = 0:",
                    error
                  );
                }
              }}
            >
              Create Projected Times for New Tasklists: {tasklists.filter((tasklist) => missingTasklistIdsInProjectedTimes.includes(tasklist.id.toString())).map((tasklist) => tasklist.name).join(", ")}
            </Button>
          </div>
        )}

      <div>
        {pricingAppData &&
          projectedTimes &&
          projectedTimes.length > 0 &&
          meanProjectRates &&
          meanProjectRates.length > 0 && (
            <TasklistDetails
              // The SSOT pricingAppData state is passed as a PROP because we need to ensure...
              // it is always defined with calculated values before this child component renders
              pricingAppData={pricingAppData}
              meanProjectRates={meanProjectRates}
              projectedTimes={projectedTimes}
            />
          )}
      </div>

      {/* If no projected times exist, render button to create one for each tasklist/role/team */}
      {projectedTimes.length === 0 && meanProjectRates && (
        <div css={tw`px-8`}>
          <Button
            onClick={async () => {
              // Create projected times
              const uniqueTeamIds = Array.from(
                new Set(meanProjectRates.map((rate) => rate.team.id))
              );
              const uniqueRoleIds = Array.from(
                new Set(meanProjectRates.map((rate) => rate.role.id))
              );

              try {
                await createProjectedTimes({
                  variables: {
                    projectId: projectId,
                    projectPricingId: projectPricingId,
                    tasklistIds: tasklistIds,
                    teamIds: uniqueTeamIds,
                    roleIds: uniqueRoleIds,
                    minutes: 0,
                  },
                });
                // Refetch project pricing data
                refetchProjectPricing();
              } catch (error) {
                console.error("Error creating projected times:", error);
              }
            }}
          >
            Create Projected Times
          </Button>
        </div>
      )}

      {/* Projected Times Creation Loading */}
      {createProjectedTimesMutation.loading && (
        <div css={tw`px-8`}>
          <p>
            Creating projected times for each tasklist, role, and team from
            project rates...
          </p>
        </div>
      )}

      {/* Development mode only: Delete project pricing */}
      {process.env.NODE_ENV === "development" && projectDetails.project_pricing.id && (
          <div css={tw`pt-20`}>
            <IconButton
              aria-label="Delete Project Pricing"
              icon={<DeleteIcon />}
              variant="outline"
              colorScheme={"red"}
              isDisabled={createProjectPricingMutation.loading || !projectDetails.project_pricing.id}
              onClick={async () => {

                if (!projectDetails.project_pricing) {
                  return;
                }  

                if (
                  window.confirm(
                    "Are you sure you want to delete this project pricing?"
                  )
                ) {
                  // Delete project pricing
                  try {
                    await deleteProjectPricing({
                      variables: {
                        id: projectDetails.project_pricing.id,
                      },
                    });
                    // Refetch project pricing data
                    refetchProjectPricing();
                  } catch (error) {
                    console.error(
                      "Error deleting project pricing data:",
                      error
                    );
                  }
                }
              }}
            />
          </div>
        )}
    </div>
  );
};
